import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { isCancel } from 'axios';
import {
    TextField,
    InputAdornment,
    Paper,
    List,
    ListItem,
    Typography,
    Box,
    CircularProgress,
    Popper,
    ClickAwayListener
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const MIN_SEARCH_LENGTH = 1;
const DEBOUNCE_DELAY = 500;

const SearchBar = () => {
    const theme = useTheme();
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    const searchRef = useRef(null);
    const abortControllerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (query.length < MIN_SEARCH_LENGTH) {
            setResults([]);
            setDebouncedQuery('');
            return;
        }

        const timeoutId = setTimeout(() => {
            setDebouncedQuery(query);
        }, DEBOUNCE_DELAY);

        return () => clearTimeout(timeoutId);
    }, [query]);

    const fetchResults = useCallback(async (searchQuery) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();

        setIsLoading(true);
        try {
            const response = await axios.get(`/api/search`, {
                params: { query: searchQuery },
                signal: abortControllerRef.current.signal
            });
            setResults(response.data);
        } catch (error) {
            if (!isCancel(error)) {
                console.error('Error fetching search results:', error);
                setResults([]);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (debouncedQuery.length >= MIN_SEARCH_LENGTH) {
            fetchResults(debouncedQuery);
        }

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [debouncedQuery, fetchResults]);

    const handleQueryChange = (event) => {
        const newQuery = event.target.value;
        setQuery(newQuery);

        if (newQuery.length >= MIN_SEARCH_LENGTH) {
            setAnchorEl(event.currentTarget); // Attach dropdown to input field
        } else {
            setAnchorEl(null); // Close dropdown if input is too short
        }
    };

    const handleResultClick = (symbol) => {
        navigate(`/asset/${symbol}`);
        setQuery('');
        setResults([]);
        setAnchorEl(null);
    };

    const handleClickAway = () => {
        setResults([]);
        setQuery('');
        setAnchorEl(null);
    };

    const getTypeColor = (type) => {
        switch (type) {
            case 'stock': return theme.palette.primary.main;
            case 'crypto': return theme.palette.success.main;
            case 'index': return theme.palette.warning.main;
            default: return theme.palette.text.primary;
        }
    };

    return (
        <div ref={searchRef} style={{ position: 'relative', width: '300px' }}>
            <TextField
                value={query}
                onChange={handleQueryChange}
                variant="outlined"
                size="small"
                placeholder="Search stocks, crypto, indices..."
                fullWidth
                autoComplete="off"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />

            <Popper
                open={Boolean(anchorEl) && (isLoading || results.length > 0 || (debouncedQuery && results.length === 0))}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal={false}
                modifiers={[
                    {
                        name: "preventOverflow",
                        options: {
                            boundary: "window",
                        },
                    },
                ]}
                style={{
                    width: anchorEl?.offsetWidth || '100%',
                    zIndex: 1500,
                }}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Paper
                        elevation={3}
                        sx={{
                            maxHeight: '400px',
                            overflowY: 'auto',
                            mt: 1
                        }}
                    >
                        {isLoading ? (
                            <Box sx={{ p: 2, textAlign: 'center' }}>
                                <CircularProgress size={20} />
                            </Box>
                        ) : results.length > 0 ? (
                            <List>
                                {results.map((result) => (
                                    <ListItem
                                        button
                                        key={`${result.symbol}-${result.type}`}
                                        onClick={() => handleResultClick(result.symbol)}
                                        sx={{
                                            '&:hover': { backgroundColor: theme.palette.action.hover }
                                        }}
                                    >
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {result.symbol}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        color: getTypeColor(result.type),
                                                        fontWeight: 'bold',
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    {result.type}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                {result.name}
                                            </Typography>
                                            {result.exchange && (
                                                <Typography variant="caption" color="text.secondary">
                                                    {result.exchange}
                                                </Typography>
                                            )}
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            !isLoading && debouncedQuery && results.length === 0 ? (
                                <Box sx={{ p: 2, textAlign: 'center' }}>
                                    <Typography color="text.secondary">
                                        No results found for "{debouncedQuery}"
                                    </Typography>
                                </Box>
                            ) : null
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
};

export default SearchBar;
