import React from 'react';
import {
    Box,
    Typography,
    Alert,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
    useMediaQuery,
    Skeleton
} from '@mui/material';
import {
    Refresh as RefreshIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
    Info as InfoIcon
} from '@mui/icons-material';

// Helper function to format large numbers with K, M, B, T suffixes
const formatNumber = (num, decimals = 2) => {
    if (num === null || num === undefined) return '-';
    if (num === 0) return '0';

    const absNum = Math.abs(num);
    const sign = num < 0 ? '-' : '';

    if (absNum >= 1000000000000) {
        return `${sign}${(num / 1000000000000).toFixed(decimals)}T`;
    } else if (absNum >= 1000000000) {
        return `${sign}${(num / 1000000000).toFixed(decimals)}B`;
    } else if (absNum >= 1000000) {
        return `${sign}${(num / 1000000).toFixed(decimals)}M`;
    } else if (absNum >= 1000) {
        return `${sign}${(num / 1000).toFixed(decimals)}K`;
    } else {
        return `${sign}${num.toFixed(decimals)}`;
    }
};

// Format date helper
const formatDate = (dateString) => {
    if (!dateString) return '-';
    try {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    } catch (error) {
        return '-';
    }
};

// Price data field definitions
const priceMetrics = [
    { id: 'price', label: 'Current Price', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: 'Current market price of the asset' },
    { id: 'change', label: 'Change', format: (value) => value?.toFixed(2), tooltip: 'Absolute price change from previous close' },
    { id: 'changesPercentage', label: 'Change %', format: (value) => `${value?.toFixed(2)}%`, tooltip: 'Percentage price change from previous close' },
    { id: 'previousClose', label: 'Previous Close', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: 'Closing price from the previous trading day' },
    { id: 'open', label: 'Open', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: 'Opening price for the current trading day' },
    { id: 'dayHigh', label: 'Day High', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: 'Highest price reached during the current trading day' },
    { id: 'dayLow', label: 'Day Low', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: 'Lowest price reached during the current trading day' },
    { id: 'yearHigh', label: '52-Week High', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: 'Highest price reached in the last 52 weeks' },
    { id: 'yearLow', label: '52-Week Low', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: 'Lowest price reached in the last 52 weeks' },
    { id: 'priceAvg50', label: '50-Day Avg', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: '50-day moving average price' },
    { id: 'priceAvg200', label: '200-Day Avg', format: (value, currency) => `${value?.toFixed(2)} ${currency || 'USD'}`, tooltip: '200-day moving average price' },
    { id: 'volume', label: 'Volume', format: (value) => formatNumber(value, 0), tooltip: 'Trading volume for the current day' },
    { id: 'avgVolume', label: 'Avg Volume', format: (value) => formatNumber(value, 0), tooltip: 'Average daily trading volume' },
    { id: 'marketCap', label: 'Market Cap', format: (value) => formatNumber(value, 1), tooltip: 'Total market value of the company\'s outstanding shares', stockOnly: true },
    { id: 'pe', label: 'P/E Ratio', format: (value) => value?.toFixed(2) || '-', tooltip: 'Price to earnings ratio', stockOnly: true },
    { id: 'eps', label: 'EPS', format: (value, currency) => value ? `${value?.toFixed(2)} ${currency || 'USD'}` : '-', tooltip: 'Earnings per share', stockOnly: true },
    { id: 'sharesOutstanding', label: 'Shares Outstanding', format: (value) => formatNumber(value, 0), tooltip: 'Total number of shares issued by the company', stockOnly: true },
    { id: 'earningsAnnouncement', label: 'Next Earnings', format: (value) => formatDate(value), tooltip: 'Date of next earnings announcement', stockOnly: true },
];

const PriceComparison = ({
    symbols,
    managedMode = false,
    priceData = [],
    loading = false,
    error = null,
    lastUpdated = null,
    onRefresh = () => { },
    onError = () => { }
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    // Filter metrics based on asset types present
    const getVisibleMetrics = (data) => {
        if (!data || !data.length) return priceMetrics;

        // Check if we only have stocks
        const hasNonStocks = data.some(asset => asset.type !== 'stock');

        if (hasNonStocks) {
            // Filter out stock-only metrics if we have non-stocks
            return priceMetrics.filter(metric => !metric.stockOnly);
        }

        return priceMetrics;
    };

    const handleRefresh = () => {
        onRefresh();
    };

    const visibleMetrics = getVisibleMetrics(priceData);

    return (
        <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>
                <Typography variant="h6">Price Information</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {lastUpdated && (
                        <Typography variant="caption" color="text.secondary">
                            Last updated: {lastUpdated.toLocaleTimeString()}
                        </Typography>
                    )}

                    <IconButton
                        onClick={handleRefresh}
                        size="small"
                        disabled={loading}
                        title="Refresh price data"
                    >
                        <RefreshIcon />
                    </IconButton>
                </Box>
            </Box>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            )}

            {symbols.length === 0 ? (
                <Typography variant="body2" color="text.secondary" sx={{ py: 4, textAlign: 'center' }}>
                    Add symbols to view price information
                </Typography>
            ) : loading ? (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table stickyHeader size={isMobile ? "small" : "medium"}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        fontWeight: 'bold',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 3,
                                        minWidth: 100
                                    }}
                                >
                                    Metric
                                </TableCell>
                                {symbols.map((symbol) => (
                                    <TableCell key={symbol} align="right" sx={{ minWidth: 120 }}>
                                        {symbol}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleMetrics.map((metric) => (
                                <TableRow key={metric.id} hover>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 2,
                                            fontWeight: 'medium',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5
                                        }}
                                    >
                                        {metric.label}
                                        {metric.tooltip && (
                                            <Tooltip title={metric.tooltip} arrow enterTouchDelay={0} leaveTouchDelay={1500}>
                                                <InfoIcon fontSize="small" color="disabled" sx={{ ml: 0.5, fontSize: 16 }} />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    {symbols.map((symbol) => (
                                        <TableCell key={`${symbol}-${metric.id}`} align="right">
                                            <Skeleton width={80} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table stickyHeader size={isMobile ? "small" : "medium"}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        fontWeight: 'bold',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 3,
                                        minWidth: 100
                                    }}
                                >
                                    Metric
                                </TableCell>
                                {symbols.map((symbol) => {
                                    const asset = priceData.find(item => item.symbol === symbol);
                                    return (
                                        <TableCell
                                            key={symbol}
                                            align="right"
                                            sx={{
                                                minWidth: 120,
                                                color: theme.palette.text.primary,
                                            }}
                                        >
                                            {symbol}
                                            {asset?.type && (
                                                <Typography variant="caption" display="block" color="text.secondary">
                                                    {asset.type.charAt(0).toUpperCase() + asset.type.slice(1)}
                                                </Typography>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleMetrics.map((metric) => (
                                <TableRow key={metric.id} hover>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 2,
                                            fontWeight: 'medium',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5
                                        }}
                                    >
                                        {metric.label}
                                        {metric.tooltip && (
                                            <Tooltip title={metric.tooltip} arrow enterTouchDelay={0} leaveTouchDelay={1500}>
                                                <InfoIcon fontSize="small" color="disabled" sx={{ ml: 0.5, fontSize: 16 }} />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    {symbols.map((symbol) => {
                                        const asset = priceData.find(item => item.symbol === symbol);
                                        const value = asset ? asset[metric.id] : null;

                                        // Special styling for change values
                                        let valueColor = theme.palette.text.primary;
                                        let valueIcon = null;

                                        if (metric.id === 'change' || metric.id === 'changesPercentage') {
                                            if (value > 0) {
                                                valueColor = theme.palette.success.main;
                                                valueIcon = <ArrowUpwardIcon fontSize="small" sx={{ fontSize: 14 }} />;
                                            } else if (value < 0) {
                                                valueColor = theme.palette.error.main;
                                                valueIcon = <ArrowDownwardIcon fontSize="small" sx={{ fontSize: 14 }} />;
                                            }
                                        }

                                        return (
                                            <TableCell
                                                key={`${symbol}-${metric.id}`}
                                                align="right"
                                                sx={{ color: valueColor }}
                                            >
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 0.5 }}>
                                                    {valueIcon}
                                                    {asset ? metric.format(value, asset.currency) : '-'}
                                                </Box>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
        </Box>
    );
};

export default PriceComparison;