import React from 'react';
import ExtendedHoursTable from './ExtendedHoursTable';

const ExtendedHoursLosers = ({ marketSession, data }) => {
    const sessionName = marketSession === 'pre' ? 'Pre-Market' : 'After-Market';
    
    return (
        <ExtendedHoursTable
            type="losers"
            marketSession={marketSession}
            titleTop={`${sessionName} Losers:`}
            // title={`Declining Stocks in ${sessionName}`}
            info={`The ${sessionName} Losers list shows stocks with the largest percentage decreases during ${marketSession === 'pre' ? 'pre-market' : 'after-market'} trading hours compared to their previous regular market close.`}
            data={data}
        />
    );
};

export default ExtendedHoursLosers;