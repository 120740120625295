import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Box,
    Paper,
    Typography,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Alert,
    Drawer,
    Fab,
    Button,
    IconButton
} from '@mui/material';
import {
    Compare as CompareIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';
import axios from '../config/axiosConfig';
import ControlPanel from './ControlPanel';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const ChartWrapper = memo(({ data, options, onChartReady, chartKey }) => {
    const chartRef = useRef(null);
    
    useEffect(() => {
        const raf = requestAnimationFrame(() => {
            if (onChartReady) {
                onChartReady();
            }
        });
        return () => cancelAnimationFrame(raf);
    }, [data, onChartReady]);
    
    return <Line ref={chartRef} data={data} options={options} key={chartKey} />;
});

const timeframes = ['1M', '6M', 'YTD', '1Y', '5Y'];

const colors = [
    '#1976d2', '#0eb35b', '#e62333', '#757575', 
    '#ffd700', '#9c27b0', '#ff9800', '#00bcd4'
];

const StockComparison = ({ symbols: externalSymbols, selectedTimeframe: externalTimeframe, managedMode = false }) => {
    const [searchParams] = useSearchParams();
    const symbolsParam = searchParams.get('symbols');
    const timeframeParam = searchParams.get('timeframe');

    const { user } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    
    const chartContainerRef = useRef(null);
    const refreshTimeoutRef = useRef(null);
    const [renderKey, setRenderKey] = useState(0);

    const maxSymbols = user?.planName === 'Premium' ? 8 : 
                  user?.planName === 'Basic' ? 4 : 2;

    const [symbols, setSymbols] = useState(() => {
        if (managedMode && externalSymbols) {
            return externalSymbols;
        } else if (symbolsParam) {
            const urlSymbols = symbolsParam.split(',');
            return urlSymbols.slice(0, maxSymbols);
        }
        return [];
    });

    const [selectedTimeframe, setSelectedTimeframe] = useState(() => {
        if (managedMode && externalTimeframe) {
            return externalTimeframe;
        } else {
            return timeframes.includes(timeframeParam) ? timeframeParam : 'YTD';
        }
    });

    useEffect(() => {
        if (managedMode) {
            if (externalSymbols) setSymbols(externalSymbols);
            if (externalTimeframe) setSelectedTimeframe(externalTimeframe);
        }
    }, [managedMode, externalSymbols, externalTimeframe]);

    const [fullDataCache, setFullDataCache] = useState({});
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [chartReady, setChartReady] = useState(false);

    useEffect(() => {
        return () => {
            if (refreshTimeoutRef.current) {
                clearTimeout(refreshTimeoutRef.current);
            }
        };
    }, []);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const getCacheKey = (symbols) => {
        return Array.isArray(symbols) ? 
            [...symbols].sort().join(',') : 
            '';
    };

    const forceChartRefresh = useCallback((newData) => {
        setChartReady(false);
        setChartData(null);
        
        if (refreshTimeoutRef.current) {
            clearTimeout(refreshTimeoutRef.current);
        }
        
        refreshTimeoutRef.current = setTimeout(() => {
            setRenderKey(prev => prev + 1);
            setChartData(newData);
        }, 150);
    }, []);

    useEffect(() => {
        if (!managedMode) {
            const params = new URLSearchParams();
            if (symbols.length > 0) {
                params.set('symbols', symbols.join(','));
            }
            params.set('timeframe', selectedTimeframe);
            navigate(`?${params.toString()}`, { replace: true });
        }

        if (symbols.length > 0) {
            const cacheKey = getCacheKey(symbols);
            
            if (fullDataCache[cacheKey]) {
                const processedData = processChartData(fullDataCache[cacheKey], selectedTimeframe);
                forceChartRefresh(processedData);
            } else {
                const missingSymbols = symbols.filter(symbol => {
                    return !Object.values(fullDataCache).some(cachedData => 
                        cachedData && Array.isArray(cachedData) && 
                        cachedData.some(item => item && item.symbol === symbol)
                    );
                });

                if (missingSymbols.length > 0) {
                    if (missingSymbols.length === symbols.length) {
                        fetchComparisonData();
                    } else {
                        fetchMissingSymbols(missingSymbols);
                    }
                } else {
                    const combinedData = symbols.map(symbol => {
                        for (const cachedData of Object.values(fullDataCache)) {
                            if (!cachedData || !Array.isArray(cachedData)) continue;
                            
                            const symbolData = cachedData.find(item => item && item.symbol === symbol);
                            if (symbolData) return symbolData;
                        }
                        return null;
                    }).filter(Boolean);
                    
                    setFullDataCache(prevCache => ({
                        ...prevCache,
                        [cacheKey]: combinedData
                    }));
                    
                    const processedData = processChartData(combinedData, selectedTimeframe);
                    forceChartRefresh(processedData);
                }
            }
        } else {
            setChartData(null);
            setChartReady(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols, selectedTimeframe, navigate, managedMode, forceChartRefresh]);

    const fetchComparisonData = async () => {
        try {
            setLoading(true);
            setError(null);
            setChartReady(false);

            const response = await axios.get('/api/stocks-comparison', {
                params: {
                    symbols: symbols.join(',')
                }
            });

            if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
                console.error('Invalid response data structure:', response.data);
                setError('Received invalid data format from server');
                return;
            }

            const receivedSymbols = response.data.map(item => item && item.symbol).filter(Boolean);
            const missingSymbols = symbols.filter(symbol => !receivedSymbols.includes(symbol));
            
            if (missingSymbols.length > 0) {
                console.warn(`Did not receive data for symbols: ${missingSymbols.join(', ')}`);
            }

            const validData = response.data.filter(
                item => item && item.symbol && Array.isArray(item.fullData) && item.fullData.length > 0
            );

            if (validData.length === 0) {
                setError('No valid data received for any of the requested symbols');
                return;
            }

            const cacheKey = getCacheKey(symbols);
            setFullDataCache(prevCache => ({
                ...prevCache,
                [cacheKey]: validData
            }));

            const processedData = processChartData(validData, selectedTimeframe);
            if (processedData) {
                forceChartRefresh(processedData);
            } else {
                setError('Unable to process chart data from server response');
            }
        } catch (error) {
            console.error('Error in fetchComparisonData:', error);
            setError(error.response?.data?.error || 'Failed to fetch comparison data');
        } finally {
            setLoading(false);
        }
    };

    const fetchMissingSymbols = async (missingSymbols) => {
        if (!missingSymbols || !missingSymbols.length) return;
        
        try {
            setLoading(true);
            setError(null);
            setChartReady(false);

            const response = await axios.get('/api/stocks-comparison', {
                params: {
                    symbols: missingSymbols.join(',')
                }
            });

            if (!response.data || !Array.isArray(response.data)) {
                console.error('Invalid response data structure:', response.data);
                setError('Received invalid data format from server');
                return;
            }

            const receivedSymbols = response.data.map(item => item && item.symbol).filter(Boolean);
            const stillMissingSymbols = missingSymbols.filter(symbol => !receivedSymbols.includes(symbol));
            
            if (stillMissingSymbols.length > 0) {
                console.warn(`Did not receive data for symbols: ${stillMissingSymbols.join(', ')}`);
            }

            const validNewData = response.data.filter(
                item => item && item.symbol && Array.isArray(item.fullData) && item.fullData.length > 0
            );

            if (validNewData.length === 0) {
                setError('No valid data received for the requested symbols');
                return;
            }

            const existingSymbolData = symbols
                .filter(symbol => !missingSymbols.includes(symbol))
                .map(symbol => {
                    for (const cachedData of Object.values(fullDataCache)) {
                        if (!cachedData || !Array.isArray(cachedData)) continue;
                        
                        const symbolData = cachedData.find(item => item && item.symbol === symbol);
                        if (symbolData && Array.isArray(symbolData.fullData) && symbolData.fullData.length > 0) {
                            return symbolData;
                        }
                    }
                    return null;
                })
                .filter(Boolean);

            const combinedData = [...existingSymbolData, ...validNewData];
            
            if (combinedData.length === 0) {
                setError('No valid data available after combining existing and new data');
                return;
            }
            
            const cacheKey = getCacheKey(symbols);
            setFullDataCache(prevCache => ({
                ...prevCache,
                [cacheKey]: combinedData
            }));
            
            const processedData = processChartData(combinedData, selectedTimeframe);
            if (processedData) {
                forceChartRefresh(processedData);
            } else {
                setError('Unable to process chart data from combined sources');
            }
        } catch (error) {
            console.error('Error in fetchMissingSymbols:', error);
            setError(error.response?.data?.error || 'Failed to fetch missing symbols');
        } finally {
            setLoading(false);
        }
    };

    const getStartDate = (timeframe) => {
        const now = new Date();
        switch (timeframe) {
            case '1M':
                return new Date(new Date().setMonth(now.getMonth() - 1));
            case '6M':
                return new Date(new Date().setMonth(now.getMonth() - 6));
            case 'YTD':
                return new Date(now.getFullYear(), 0, 1);
            case '1Y':
                return new Date(new Date().setFullYear(now.getFullYear() - 1));
            case '5Y':
                return new Date(new Date().setFullYear(now.getFullYear() - 5));
            default:
                return new Date(now.getFullYear(), 0, 1);
        }
    };

    const processChartData = (data, timeframe) => {
        if (!data || !Array.isArray(data) || data.length === 0) {
            console.log("Invalid data received for chart processing:", data);
            return null;
        }

        const validData = data.filter(symbolData => 
            symbolData && 
            symbolData.symbol && 
            Array.isArray(symbolData.fullData) && 
            symbolData.fullData.length > 0
        );

        if (validData.length === 0) {
            console.log("No valid symbol data found after filtering:", data);
            return null;
        }

        const startDate = getStartDate(timeframe);

        const processedSymbols = validData.map(symbolData => {
            const filteredData = symbolData.fullData
                .filter(d => d && d.date && d.close && new Date(d.date) >= startDate)
                .sort((a, b) => new Date(a.date) - new Date(b.date));

            if (filteredData.length === 0) {
                console.log(`No data points found after timeframe filtering for ${symbolData.symbol}`);
                return null;
            }

            const basePrice = filteredData[0].close;
            
            if (basePrice <= 0) {
                console.log(`Invalid base price (${basePrice}) for ${symbolData.symbol}`);
                return null;
            }

            return {
                symbol: symbolData.symbol,
                data: filteredData.map(point => ({
                    date: point.date,
                    performance: ((point.close - basePrice) / basePrice) * 100
                }))
            };
        }).filter(Boolean);

        if (processedSymbols.length === 0) {
            console.log("No symbols with valid data after processing");
            return null;
        }

        const allDates = new Set();
        processedSymbols.forEach(symbol => {
            symbol.data.forEach(point => {
                if (point && point.date) {
                    allDates.add(point.date);
                }
            });
        });

        const sortedDates = Array.from(allDates).sort();
        
        if (sortedDates.length === 0) {
            console.log("No valid dates found across all symbols");
            return null;
        }

        let minValue = 0, maxValue = 0;
        let hasValues = false;
        
        processedSymbols.forEach(symbol => {
            symbol.data.forEach(point => {
                if (point && typeof point.performance === 'number') {
                    minValue = Math.min(minValue, point.performance);
                    maxValue = Math.max(maxValue, point.performance);
                    hasValues = true;
                }
            });
        });

        if (!hasValues) {
            console.log("No valid performance values found");
            return null;
        }

        minValue = Math.floor(minValue / 20) * 20;
        maxValue = Math.ceil(maxValue / 20) * 20;

        return {
            labels: sortedDates,
            datasets: processedSymbols.map((symbol, index) => ({
                label: symbol.symbol,
                data: sortedDates.map(date => {
                    const point = symbol.data.find(p => p.date === date);
                    return point ? point.performance : null;
                }),
                borderColor: colors[index % colors.length],
                backgroundColor: colors[index % colors.length],
                borderWidth: 1.5,
                pointRadius: 0,
                tension: 0.1,
                fill: false,
                spanGaps: true,
                order: index
            }))
        };
    };

    const handleAddSymbol = async (symbol) => {
        if (!symbol || typeof symbol !== 'string' || symbols.length >= maxSymbols) return;

        const formattedSymbol = symbol.toUpperCase().trim();
        if (!symbols.includes(formattedSymbol)) {
            if (!managedMode) {
                try {
                    setLoading(true);
                    setError(null);
                    setChartReady(false);
                    
                    const response = await axios.get('/api/stocks-comparison', {
                        params: {
                            symbols: formattedSymbol
                        }
                    });
                    
                    if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
                        console.error(`Invalid response when adding symbol ${formattedSymbol}:`, response.data);
                        setError(`No valid data available for symbol ${formattedSymbol}`);
                        return;
                    }
                    
                    const newSymbolData = response.data[0];
                    if (!newSymbolData || !newSymbolData.symbol || !Array.isArray(newSymbolData.fullData) || newSymbolData.fullData.length === 0) {
                        console.error(`Invalid data structure for symbol ${formattedSymbol}:`, newSymbolData);
                        setError(`Invalid data received for symbol ${formattedSymbol}`);
                        return;
                    }
                    
                    if (newSymbolData.symbol !== formattedSymbol) {
                        console.warn(`Requested ${formattedSymbol} but received data for ${newSymbolData.symbol}`);
                    }
                    
                    const updatedSymbols = [...symbols, formattedSymbol];
                    setSymbols(updatedSymbols);
                    
                    const cacheKey = getCacheKey(updatedSymbols);
                    
                    setFullDataCache(prevCache => {
                        const existingSymbolsKey = getCacheKey(symbols);
                        const existingData = prevCache[existingSymbolsKey] || [];
                        
                        const validExistingData = existingData.filter(
                            item => item && item.symbol && Array.isArray(item.fullData) && item.fullData.length > 0
                        );
                        
                        const combinedData = [...validExistingData, newSymbolData];
                        
                        return {
                            ...prevCache,
                            [cacheKey]: combinedData
                        };
                    });
                    
                    setTimeout(() => {
                        const latestCacheKey = getCacheKey(updatedSymbols);
                        const latestData = fullDataCache[latestCacheKey] || 
                                         [...(fullDataCache[getCacheKey(symbols)] || []), newSymbolData];
                        
                        setRenderKey(prev => prev + 5);
                        setChartData(null);
                        
                        setTimeout(() => {
                            const processedData = processChartData(latestData, selectedTimeframe);
                            if (processedData) {
                                setChartData(processedData);
                            } else {
                                setError(`Unable to process data for ${formattedSymbol}`);
                            }
                        }, 100);
                    }, 100);
                } catch (error) {
                    console.error(`Error adding symbol ${formattedSymbol}:`, error);
                    setError(error.response?.data?.error || `Failed to fetch data for ${formattedSymbol}`);
                } finally {
                    setLoading(false);
                    if (isMobile) {
                        setDrawerOpen(false);
                    }
                }
            }
        }
    };

    const handleRemoveSymbol = (symbolToRemove) => {
        if (!managedMode) {
            const updatedSymbols = symbols.filter(symbol => symbol !== symbolToRemove);
            setSymbols(updatedSymbols);
            setChartReady(false);
            
            if (updatedSymbols.length > 0) {
                const previousCacheKey = getCacheKey(symbols);
                if (fullDataCache[previousCacheKey]) {
                    const updatedCacheKey = getCacheKey(updatedSymbols);
                    
                    const updatedData = fullDataCache[previousCacheKey].filter(
                        data => data && data.symbol !== symbolToRemove
                    );
                    
                    setFullDataCache(prevCache => ({
                        ...prevCache,
                        [updatedCacheKey]: updatedData
                    }));
                    
                    setTimeout(() => {
                        setRenderKey(prev => prev + 5);
                        setChartData(null);
                        
                        setTimeout(() => {
                            const processedData = processChartData(updatedData, selectedTimeframe);
                            if (processedData) {
                                setChartData(processedData);
                            } else {
                                setError('Unable to update chart after removing symbol');
                            }
                        }, 100);
                    }, 100);
                }
            } else {
                setChartData(null);
            }
        }
    };

    const handleClearAll = () => {
        if (!managedMode) {
            setSymbols([]);
            setChartData(null);
            setChartReady(false);
        }
    };

    const handleRefreshChart = () => {
        if (symbols.length > 0) {
            const cacheKey = getCacheKey(symbols);
            if (fullDataCache[cacheKey]) {
                setRenderKey(prev => prev + 10);
                setChartData(null);
                
                setTimeout(() => {
                    const processedData = processChartData(fullDataCache[cacheKey], selectedTimeframe);
                    if (processedData) {
                        setChartData(processedData);
                    } else {
                        setError('Unable to refresh chart data');
                    }
                }, 100);
            } else {
                fetchComparisonData();
            }
        }
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                position: 'top',
                align: 'center',
                labels: {
                    boxWidth: 12,
                    padding: 15,
                    color: theme.palette.text.primary,
                    font: {
                        size: 11
                    }
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                includeInvisible: true,
                callbacks: {
                    title: function (context) {
                        const date = new Date(context[0].label);
                        return date.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        });
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${context.parsed.y ? context.parsed.y.toFixed(2) : 'N/A'}%`;
                    }
                },
                itemSort: function(a, b) {
                    return a.datasetIndex - b.datasetIndex;
                },
                padding: 10,
                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                titleColor: theme.palette.text.primary,
                bodyColor: theme.palette.text.primary,
                borderColor: theme.palette.divider,
                borderWidth: 1,
                displayColors: true
            },
            datalabels: {
                display: false
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
                pan: {
                    enabled: true,
                    mode: 'x'
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    drawBorder: false
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 8,
                    color: theme.palette.text.secondary,
                    font: {
                        size: 11
                    },
                    callback: function (value, index, ticks) {
                        const date = new Date(this.getLabelForValue(value));
                        return date.toLocaleDateString('en-US', {
                            month: 'short',
                            year: '2-digit'
                        });
                    }
                }
            },
            y: {
                position: 'right',
                grid: {
                    display: true,
                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    drawBorder: false
                },
                ticks: {
                    color: theme.palette.text.secondary,
                    callback: (value) => `${value}%`,
                    stepSize: 20,
                    font: {
                        size: 11
                    }
                },
                suggestedMin: -100,
                suggestedMax: 60
            }
        },
        layout: {
            padding: {
                top: 30
            }
        },
        interaction: {
            mode: 'index',
            intersect: false
        },
        elements: {
            line: {
                tension: 0.1
            },
            point: {
                radius: 0,
                hoverRadius: 3
            }
        },
        transitions: {
            active: {
                animation: {
                    duration: 0
                }
            }
        },
        responsiveAnimationDuration: 0
    };

    return (
        <Box sx={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
            {isMobile && !managedMode && (
                <Fab
                    color="primary"
                    aria-label="compare"
                    onClick={toggleDrawer}
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        zIndex: 1000
                    }}
                >
                    <CompareIcon />
                </Fab>
            )}

            <Box sx={{ display: 'flex', gap: 2, height: '100%' }}>
                {!managedMode && (
                    isMobile ? (
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={toggleDrawer}
                            PaperProps={{
                                sx: {
                                    width: '85%',
                                    maxWidth: '360px',
                                    bgcolor: theme.palette.background.paper
                                }
                            }}
                        >
                            <ControlPanel
                                onAddSymbol={handleAddSymbol}
                                onRemoveSymbol={handleRemoveSymbol}
                                symbols={symbols}
                                maxSymbols={maxSymbols}
                                selectedTimeframe={selectedTimeframe}
                                setSelectedTimeframe={setSelectedTimeframe}
                                toggleDrawer={toggleDrawer}
                                isMobile={isMobile}
                                theme={theme}
                                onClearAll={handleClearAll}
                            />
                        </Drawer>
                    ) : (
                        <Paper
                            elevation={0}
                            sx={{
                                width: isTablet ? '240px' : '300px',
                                height: '100%',
                                bgcolor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                overflow: 'auto'
                            }}
                        >
                            <ControlPanel
                                onAddSymbol={handleAddSymbol}
                                onRemoveSymbol={handleRemoveSymbol}
                                symbols={symbols}
                                maxSymbols={maxSymbols}
                                selectedTimeframe={selectedTimeframe}
                                setSelectedTimeframe={setSelectedTimeframe}
                                toggleDrawer={toggleDrawer}
                                isMobile={isMobile}
                                theme={theme}
                                onClearAll={handleClearAll}
                            />
                        </Paper>
                    )
                )}

                <Paper
                    elevation={0}
                    sx={{
                        flexGrow: 1,
                        height: '100%',
                        p: managedMode ? 0 : 2,
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: theme.palette.background.paper,
                        border: managedMode ? 'none' : `1px solid ${theme.palette.divider}`,
                        overflow: 'hidden',
                        position: 'relative'
                    }}
                >
                    {symbols.length > 0 && !loading && (
                        <IconButton 
                            onClick={handleRefreshChart}
                            sx={{ 
                                position: 'absolute', 
                                top: 10, 
                                right: 10, 
                                zIndex: 10,
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: 1,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                }
                            }}
                            size="small"
                            title="Refresh Chart"
                        >
                            <RefreshIcon />
                        </IconButton>
                    )}

                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
                    )}

                    {!loading && !error && chartData && (
                        <Box 
                            ref={chartContainerRef}
                            sx={{ 
                                flexGrow: 1, 
                                width: '100%', 
                                height: '100%', 
                                minHeight: 0,
                                opacity: chartReady ? 1 : 0.6,
                                transition: 'opacity 0.2s ease-in-out'
                            }}
                        >
                            <ChartWrapper 
                                data={chartData} 
                                options={chartOptions}
                                onChartReady={() => setChartReady(true)}
                                chartKey={`chart-${renderKey}-${symbols.join('-')}-${selectedTimeframe}`}
                            />
                        </Box>
                    )}

                    {!loading && !error && !chartData && symbols.length === 0 && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            gap: 2
                        }}>
                            <CompareIcon sx={{ fontSize: 48, color: 'text.secondary', opacity: 0.5 }} />
                            <Typography variant="h6" color="text.secondary">
                                Start Comparing Assets
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                                Add symbols using the {isMobile ? 'button below' : 'panel on the left'} to begin comparison
                            </Typography>
                        </Box>
                    )}

                    {!loading && !error && !chartData && symbols.length > 0 && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            gap: 2
                        }}>
                            <CircularProgress size={24} sx={{ mb: 2 }} />
                            <Typography variant="body1" color="text.secondary">
                                Preparing chart data...
                            </Typography>
                            <Button 
                                variant="outlined" 
                                startIcon={<RefreshIcon />}
                                onClick={handleRefreshChart}
                                sx={{ mt: 2 }}
                            >
                                Refresh Chart
                            </Button>
                        </Box>
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default StockComparison