import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
    Box,
    Container,
    Typography,
    CircularProgress,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from '../config/axiosConfig';
import TitleAndInfo from './TitleAndInfo';
import { findBioguideId } from '../utils/congressMembersMapping';
import ProfileImage from './ProfileImage';

const PAGE_SIZE = 10;

const CongressTracker = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseUrl = window.location.origin;

    // Pagination state
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // Filter state
    const [selectedParty, setSelectedParty] = useState('');

    // Cache state - structure: { [party]: { [page]: data[] } }
    const [cachedData, setCachedData] = useState({});

    const titleTop = "Congress Trading Activity";
    const title = "";
    const info = "Track trading activities of members of Congress, including both Senate and House representatives. View detailed information about stock purchases, sales, and transaction amounts, sorted by date to monitor the most recent trading patterns.";

    const fetchTradesData = async (page, party) => {
        // Check if data is already cached
        const partyCache = cachedData[party || 'all'] || {};
        if (partyCache[page] !== undefined) {
            return;
        }

        try {
            setLoading(true);
            const response = await axios.get('/api/congress/trading', {
                params: {
                    page: page + 1, // DataGrid uses 0-based indexing, API uses 1-based
                    limit: PAGE_SIZE,
                    party: party
                }
            });

            const { data: responseData, total } = response.data;

            // Transform the data for the grid
            const transformedData = responseData.map(trade => ({
                id: trade._id,
                personName: trade.name,
                personFirstLetter: trade.name.charAt(0),
                party: trade.party,
                companySymbol: trade.symbol,
                companyName: trade.assetDescription,
                date: new Date(trade.transactionDate),
                transactionDate: trade.transactionDate,
                amount: trade.amount,
                type: trade.transactionType
            }));

            // Update cache
            setCachedData(prev => ({
                ...prev,
                [party || 'all']: {
                    ...(prev[party || 'all'] || {}),
                    [page]: transformedData
                }
            }));

            setTotalRows(total);
            setError(null);
        } catch (error) {
            console.error('Error fetching trades data:', error);
            setError(error.message || 'Failed to fetch trades data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTradesData(page, selectedParty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, selectedParty]);

    const handlePartyChange = (event) => {
        const newParty = event.target.value;
        setSelectedParty(newParty);
        setPage(0); // Reset to first page when changing party
        // Clear cache for new party if it doesn't exist
        if (!cachedData[newParty || 'all']) {
            setCachedData(prev => ({
                ...prev,
                [newParty || 'all']: {}
            }));
        }
    };

    // Get current data from cache
    const currentData = (cachedData[selectedParty || 'all'] || {})[page] || [];

    const columns = [
        {
            field: 'person',
            headerName: 'Person',
            minWidth: 250,
            flex: 1,
            renderCell: (params) => {
                const bioguideId = findBioguideId(params.row.personName);

                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ProfileImage
                            name={params.row.personName}
                            imageUrls={[
                                bioguideId
                                    ? `/images/congress/${bioguideId}.jpg`
                                    : null
                            ]}
                            size={32}
                            sx={{
                                flexShrink: 0,
                                width: 32,
                                height: 32
                            }}
                        />
                        <Box>
                            <RouterLink
                                to={`/congress-member/${encodeURIComponent(params.row.personName)}`}
                                style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: 'none',
                                    fontSize: '1rem',
                                }}
                            >
                                {params.row.personName}
                            </RouterLink>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'text.secondary',
                                    fontWeight: 500,
                                }}
                            >
                                {params.row.party}
                            </Typography>
                        </Box>
                    </Box>
                );
            }
        },
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 250,
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.companySymbol}`}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {params.row.companySymbol}
                    </RouterLink>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            fontWeight: 500,
                        }}
                    >
                        {params.row.companyName}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            minWidth: 100,
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            }
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <Typography
                    sx={{
                        color: params.value.toLowerCase() === 'purchase' ||
                            params.value.toLowerCase() === 'buy' ?
                            theme.palette.positive.main :
                            theme.palette.negative.main
                    }}
                >
                    {params.value.toLowerCase() === 'purchase' ? 'Bought' :
                        params.value.toLowerCase() === 'sale' ? 'Sold' :
                            params.value}
                </Typography>
            )
        }
    ];

    // Track if this is the initial load
    const isInitialLoad = loading && !Object.keys(cachedData).length;

    if (isInitialLoad) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error">
                {error}
            </Alert>
        );
    }

    return (
        <Container maxWidth="xl">
            <TitleAndInfo titleTop={titleTop} title={title} info={info} />

            {/* Party Filter */}
            <Box sx={{ mb: 3 }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Filter by Party</InputLabel>
                    <Select
                        value={selectedParty}
                        onChange={handlePartyChange}
                        label="Filter by Party"
                    >
                        <MenuItem value="">All Parties</MenuItem>
                        <MenuItem value="Democratic">Democratic</MenuItem>
                        <MenuItem value="Republican">Republican</MenuItem>
                        <MenuItem value="Independent">Independent</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ height: 650, width: '100%' }}>
                <DataGrid
                    rows={currentData}
                    columns={columns}
                    paginationMode="server"
                    rowCount={totalRows}
                    pageSize={PAGE_SIZE}
                    paginationModel={{
                        page,
                        pageSize: PAGE_SIZE,
                    }}
                    onPaginationModelChange={(model) => {
                        setPage(model.page);
                    }}
                    loading={loading}
                    disableColumnMenu
                    hideFooterRowCount
                    hideFooterSelectedRowCount
                />
            </Box>
        </Container>
    );
};

export default CongressTracker;