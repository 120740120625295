import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const ChangeCurrencyModal = ({ open, onClose, onConfirm, currentCurrency, title }) => {
  const [newCurrency, setNewCurrency] = useState(currentCurrency);

  useEffect(() => {
    if (open) {
      setNewCurrency(currentCurrency);
    }
  }, [open, currentCurrency]);

  const handleConfirm = () => {
    onConfirm(newCurrency);
    onClose();
  };

  const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'CAD', 'AUD', 'CHF']; // Add more currencies as needed

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="h6" component="h2" mb={2} textAlign="center">
          {title}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="currency-select-label">Currency</InputLabel>
          <Select
            labelId="currency-select-label"
            id="currency-select"
            value={newCurrency}
            label="Currency"
            onChange={(e) => setNewCurrency(e.target.value)}
          >
            {currencies.map((currency) => (
              <MenuItem key={currency} value={currency}>{currency}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm} 
            variant="contained" 
            disabled={newCurrency === currentCurrency}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangeCurrencyModal;