// Stock.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../config/axiosConfig';
import {
  Container,
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
  Paper,
  Grid,
  CircularProgress,
} from '@mui/material';
import StockChart from './StockChart';
import CompanyProfile from './CompanyProfile';
import EnhancedCompanyRating from './EnhancedCompanyRating';
import CombinedFinancials from './CombinedFinancials';
// import CompanyHistoricalDividends from './CompanyHistoricalDividends';
import CompanyHistoricalDividendsV2 from './CompanyHistoricalDividendsV2';
import EarningsTranscript from './EarningsTranscript';
import PriceTargetBySymbol from './PriceTargetBySymbol';
import AddToWatchlistModal from './AddToWatchlistModal';
import { useTheme } from '@mui/material/styles';
import { StarOutline as StarIcon } from '@mui/icons-material';
import { useAuth } from './AuthContext';
import { formatNumber, formatPrice } from '../utils/numberUtils';
import AnalystRatingCard from './AnalystRating';
import ReturnComparison from './ReturnComparison';
import RevenueSegmentation from './RevenueSegmentation';
import MarketStatusIndicator from './MarketStatusIndicator';
import StockPriceContainer from './StockPriceContainer';
import CombineInsiderTrading from './CombineInsiderTrading';

// Define sections with their metadata
const sectionConfig = [
  {
    id: 'overview',
    label: 'Overview',
    component: CompanyProfile,
    endpoint: symbol => `/api/companyprofile/${symbol}`
  },
  {
    id: 'financials',
    label: 'Financials',
    component: CombinedFinancials,
    endpoint: symbol => ({
      incomeStatement: `/api/companyincomestatement/${symbol}`,
      balanceSheet: `/api/company-balance-sheet-statement/${symbol}`,
      cashStatement: `/api/company-cash-statement/${symbol}`
    })
  },
  {
    id: 'segmentation',
    label: 'Revenue Breakdown',
    component: RevenueSegmentation,
    endpoint: symbol => `/api/revenueanalysis/${symbol}`
  },
  {
    id: 'ratingsAndScores',
    label: 'Ratings & Scores',
    component: EnhancedCompanyRating,
    endpoint: symbol => `/api/company-rating-and-scores/${symbol}`
  },
  {
    id: 'priceTargets',
    label: 'Price Targets',
    component: PriceTargetBySymbol,
    endpoint: symbol => `/api/priceTargets/symbol/${symbol}`
  },
  // {
  //   id: 'dividends',
  //   label: 'Dividends',
  //   component: CompanyHistoricalDividends,
  //   endpoint: symbol => `/api/historicaldividendsdata/${symbol}`,
  // },
  {
    id: 'dividends',
    label: 'Dividends',
    component: CompanyHistoricalDividendsV2,
    endpoint: symbol => `/api/historicaldividendsdatav2/${symbol}`,
  },
  {
    id: 'CombineInsiderTrading',
    label: 'Insider Trading',
    component: CombineInsiderTrading,
    endpoint: symbol => `/api/insider-trading-statistics/${symbol}`
  },
  {
    id: 'earningsTranscript',
    label: 'Earnings Transcript',
    component: EarningsTranscript,
    endpoint: symbol => `/api/earnings-transcript/${symbol}?year=${new Date().getFullYear()}`
  }
];

const Stock = () => {
  const symbol = useParams().symbol.toUpperCase();
  const { user } = useAuth();
  const theme = useTheme();

  // Base state
  const [selectedTab, setSelectedTab] = useState(0);
  const [stockData, setStockData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);

  // New state for section management
  const [sectionData, setSectionData] = useState({});
  const [sectionLoading, setSectionLoading] = useState({});
  const [availableSections, setAvailableSections] = useState([]);

  const [, setPriceFlash] = useState(false);
  const [, setPriceIncreased] = useState(null);
  const previousPrice = React.useRef(null);
  const [holidayInfo, setHolidayInfo] = useState(null);

  const activeEndpointRef = useRef(null);

  useEffect(() => {
    // Reset states at start
    setStockData(null);
    setLoading(true);
    setSectionData({});
    setSectionLoading({});
    setAvailableSections([]);
    setSelectedTab(0);
    previousPrice.current = null;
    activeEndpointRef.current = null;

    // Define fetchSectionData inside useEffect
    const fetchSectionData = async (sectionId) => {
      const section = sectionConfig.find(s => s.id === sectionId);

      setSectionLoading(prev => ({ ...prev, [sectionId]: true }));

      try {
        // Handle combined financials differently
        if (sectionId === 'financials') {
          const endpoints = section.endpoint(symbol);
          const [incomeResponse, balanceResponse, cashResponse] = await Promise.all([
            axios.get(endpoints.incomeStatement),
            axios.get(endpoints.balanceSheet),
            axios.get(endpoints.cashStatement)
          ]);

          // Check if either response has data
          const hasIncomeData = incomeResponse.data.data &&
            (Array.isArray(incomeResponse.data.data) ?
              incomeResponse.data.data.length > 0 : true);

          const hasBalanceData = balanceResponse.data.data &&
            (Array.isArray(balanceResponse.data.data) ?
              balanceResponse.data.data.length > 0 : true);

          const hasCashData = cashResponse.data.data &&
            (Array.isArray(cashResponse.data.data) ?
              cashResponse.data.data.length > 0 : true);

          if (hasIncomeData || hasBalanceData || hasCashData) {
            setSectionData(prev => ({
              ...prev,
              [sectionId]: {
                incomeStatement: incomeResponse.data.data,
                balanceSheet: balanceResponse.data.data,
                cashStatement: cashResponse.data.data
              }
            }));

            setAvailableSections(prev => {
              if (!prev.includes(sectionId)) {
                return [...prev, sectionId].sort((a, b) =>
                  sectionConfig.findIndex(s => s.id === a) -
                  sectionConfig.findIndex(s => s.id === b)
                );
              }
              return prev;
            });
          }
        } else {
          // Original code for other sections
          const response = await axios.get(section.endpoint(symbol));

          if (!response.data.hasOwnProperty('hasData')) {
            const hasData = response.data.data &&
              (Array.isArray(response.data.data) ? response.data.data.length > 0 : true);

            if (hasData) {
              setSectionData(prev => ({
                ...prev,
                [sectionId]: response.data.data
              }));

              setAvailableSections(prev => {
                if (!prev.includes(sectionId)) {
                  return [...prev, sectionId].sort((a, b) =>
                    sectionConfig.findIndex(s => s.id === a) -
                    sectionConfig.findIndex(s => s.id === b)
                  );
                }
                return prev;
              });
            }
          } else if (response.data.hasData) {
            setSectionData(prev => ({
              ...prev,
              [sectionId]: response.data.data
            }));

            setAvailableSections(prev => {
              if (!prev.includes(sectionId)) {
                return [...prev, sectionId].sort((a, b) =>
                  sectionConfig.findIndex(s => s.id === a) -
                  sectionConfig.findIndex(s => s.id === b)
                );
              }
              return prev;
            });
          }
        }
      } catch (error) {
        console.error(`Error fetching ${sectionId} data:`, error);
      } finally {
        setSectionLoading(prev => ({ ...prev, [sectionId]: false }));
      }
    };

    // Initial fetch of all data
    const fetchAllData = async () => {
      try {
        let response = await axios.get(`/api/fullquote/${symbol}`);

        if (!response.data.data[0]) {
          response = await axios.get(`/api/indexfullquote/${symbol}`);
          if (!response.data.data[0]) {
            response = await axios.get(`/api/cryptofullquote/${symbol}`);
            activeEndpointRef.current = `/api/cryptofullquote/${symbol}`;
          } else {
            activeEndpointRef.current = `/api/indexfullquote/${symbol}`;
          }
        } else {
          activeEndpointRef.current = `/api/fullquote/${symbol}`;
        }

        setStockData(response.data.data[0]);
        setLoading(false);

        // Fetch all section data at once
        await Promise.all(
          sectionConfig.map(section => fetchSectionData(section.id))
        );
      } catch (error) {
        console.error('Error fetching stock data:', error);
        setLoading(false);
      }
    };

    // Real-time stock price updates
    const fetchStockPrice = async () => {
      if (!activeEndpointRef.current) return;
      try {
        const response = await axios.get(activeEndpointRef.current);
        const newData = response.data.data[0];

        if (previousPrice.current !== null && newData.price !== previousPrice.current) {
          setPriceFlash(true);
          setPriceIncreased(newData.price > previousPrice.current);
          setTimeout(() => setPriceFlash(false), 1000);
        }
        previousPrice.current = newData.price;
        setStockData(newData);
      } catch (error) {
        console.error('Error fetching stock price:', error);
      }
    };

    // Start initial fetch and set up interval
    fetchAllData();
    const intervalId = setInterval(fetchStockPrice, 60000);

    // Cleanup function
    return () => {
      clearInterval(intervalId);
      setStockData(null);
      setSectionData({});
      setAvailableSections([]);
      setSelectedTab(0);
      previousPrice.current = null;
      activeEndpointRef.current = null;
    };
  }, [symbol]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleStarClick = () => {
    setShowAddToWatchlistModal(true);
  };

  const handleWatchlistSuccess = (message) => {
    // console.log(message);
  };

  const handleCloseModal = () => {
    setShowAddToWatchlistModal(false);
  };

  const hasValidValue = (value) => {
    if (!value) return false;
    if (value === 'N/A') return false;
    if (typeof value === 'string' && value.trim().length === 0) return false;
    return true;
  };

  const formatStatValue = (value, currency) => {
    if (typeof value === 'number') {
      if (value > 1000000) {
        return formatNumber(value);
      }
      return value.toLocaleString();
    }
    return value;
  };

  // Compute key stats
  const keyStats = stockData ? {
    'Market Cap': formatNumber(stockData.marketCap),
    'PE': stockData.pe?.toLocaleString() ?? 'N/A',
    'EPS': stockData.eps?.toLocaleString() ?? 'N/A',
    'Avg Volume': formatNumber(stockData.avgVolume),
    'Volume': formatNumber(stockData.volume),
    'Exchange': stockData.exchange,
    'Day High': formatPrice(stockData.dayHigh),
    'Day Low': formatPrice(stockData.dayLow),
    'Year High': formatPrice(stockData.yearHigh),
    'Year Low': formatPrice(stockData.yearLow),
    'Price Avg 200': formatPrice(stockData.priceAvg200),
    'Price Avg 50': formatPrice(stockData.priceAvg50),
    'Previous Close': formatPrice(stockData.previousClose),
    'Open': formatPrice(stockData.open),
    'Next Earnings': stockData.earningsAnnouncement ?
      new Date(stockData.earningsAnnouncement).toLocaleDateString() : 'N/A',
  } : {};

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 100px)"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!stockData) {
    return <div>No asset data available</div>;
  }

  // const currencySymbol = currencySymbols[stockData.currency] || '';

  // Filter sections based on available data
  // const visibleSections = sectionConfig.filter(section =>
  //   availableSections.includes(section.id)
  // );

  return (
    <Container maxWidth="xl">
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          {/* Stock Header Section */}
          <Grid item xs={12}>
            <Paper variant='stats' elevation={3} sx={{ padding: 2 }}>
              <Container sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="h4">{stockData.symbol}</Typography>
                  {!loading && stockData?.exchange && (
                    <MarketStatusIndicator
                      exchange={stockData.exchange}
                      onHolidayInfoUpdate={(info) => setHolidayInfo(info)}
                    />
                  )}
                </Box>
                <Typography variant="h4">{stockData.name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <StockPriceContainer
                    stockData={stockData}
                    todayHoliday={holidayInfo}
                  />
                </Box>
                {user && (
                  <StarIcon
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      fontSize: 40,
                      cursor: 'pointer',
                      '&:hover': {
                        color: theme.palette.primary.main
                      }
                    }}
                    onClick={handleStarClick}
                  />
                )}
              </Container>
            </Paper>
          </Grid>

          {/* Chart and Stats Section */}
          <Grid container item xs={12} spacing={2}>
            {/* Chart Section */}
            <Grid
              item
              xs={12}
              sm={12}
              lg={8}
              sx={{
                display: { xs: 'none', sm: 'block' },
                mb: { sm: 2, lg: 0 }
              }}
            >
              <Paper
                elevation={3}
                variant='stats'
                sx={{
                  height: '100%',  // Will match the height of stats section
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <StockChart symbol={symbol} />
              </Paper>
            </Grid>

            {/* Stats Section */}
            <Grid
              item
              xs={12}
              sm={12}
              lg={4}
            >
              <Paper
                elevation={3}
                variant='stats'
                sx={{
                  padding: 4,
                  height: '100%'
                }}
              >
                <Grid
                  container
                  direction="column"
                  spacing={2}
                >
                  {/* Price Data Section */}
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          mb: 1,
                          color: theme.palette.primary.main
                        }}
                      >
                        Price Data
                      </Typography>
                      <Grid container spacing={1}>
                        {[
                          { key: 'Open', value: keyStats.Open },
                          { key: 'Day High', value: keyStats['Day High'] },
                          { key: 'Day Low', value: keyStats['Day Low'] },
                          { key: 'Previous Close', value: keyStats['Previous Close'] },
                          { key: 'Year High', value: keyStats['Year High'] },
                          { key: 'Year Low', value: keyStats['Year Low'] },
                          { key: 'Price Avg 200', value: keyStats['Price Avg 200'] },
                          { key: 'Price Avg 50', value: keyStats['Price Avg 50'] },
                        ].map(({ key, value }) => (
                          hasValidValue(value) && (
                            <Grid item xs={6} key={key}>
                              <Typography
                                variant="body2"
                                sx={{
                                  mb: 1.5,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: 1,
                                  '& strong': {
                                    fontWeight: 600,
                                    color: theme.palette.text.primary,
                                  }
                                }}
                              >
                                <strong>{key}:</strong>
                                <span style={{ color: theme.palette.text.secondary }}>
                                  {formatStatValue(value, stockData.currency)}
                                </span>
                              </Typography>
                            </Grid>
                          )
                        ))}
                      </Grid>
                    </Box>
                  </Grid>

                  {/* Trading Stats Section */}
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          mb: 1,
                          color: theme.palette.primary.main
                        }}
                      >
                        Trading Stats
                      </Typography>
                      <Grid container spacing={1}>
                        {[
                          { key: 'Market Cap', value: keyStats['Market Cap'] },
                          { key: 'Volume', value: keyStats.Volume },
                          { key: 'EPS', value: keyStats.EPS },
                          { key: 'PE', value: keyStats.PE },
                          { key: 'Avg Volume', value: keyStats['Avg Volume'] },
                          { key: 'Exchange', value: keyStats.Exchange },
                          { key: 'Next Earnings', value: keyStats['Next Earnings'] },
                        ].map(({ key, value }) => (
                          hasValidValue(value) && (
                            <Grid item xs={6} key={key}>
                              <Typography
                                variant="body2"
                                sx={{
                                  mb: 1.5,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: 1,
                                  '& strong': {
                                    fontWeight: 600,
                                    color: theme.palette.text.primary,
                                  }
                                }}
                              >
                                <strong>{key}:</strong>
                                <span style={{ color: theme.palette.text.secondary }}>
                                  {formatStatValue(value, stockData.currency)}
                                </span>
                              </Typography>
                            </Grid>
                          )
                        ))}
                      </Grid>
                    </Box>
                  </Grid>

                  {/* Analyst Rating Card - conditionally rendered */}
                  <Grid item xs={12}>
                    <AnalystRatingCard />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {/* Return Comparison Section */}
          <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3, width: '100%' }}>
            <ReturnComparison symbol={symbol} />
          </Box>

          {availableSections.length > 0 && (
            <Grid item xs={12}>
              <AppBar position="static">
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root": {
                      color: theme.palette.mode === 'dark'
                        ? theme.palette.text.secondary  // For dark mode
                        : theme.palette.secondary.dark,  // For light mode using our new color
                    },
                    "& .Mui-selected": {
                      color: theme.palette.mode === 'dark'
                        ? theme.palette.primary.main
                        : theme.palette.secondary.main,
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: theme.palette.mode === 'dark'
                        ? theme.palette.primary.main
                        : theme.palette.secondary.main,
                    },
                  }}
                >
                  {availableSections.map((sectionId, index) => {
                    const section = sectionConfig.find((s) => s.id === sectionId);
                    return (
                      <Tab
                        key={section.id}
                        label={section.label}
                        sx={{
                          "&.Mui-selected": {
                            color: theme.palette.mode === 'dark'
                              ? theme.palette.primary.main
                              : theme.palette.secondary.main,
                          },
                        }}
                      />
                    );
                  })}
                </Tabs>
              </AppBar>
              <Paper elevation={3} sx={{ padding: 2 }}>
                {sectionLoading[availableSections[selectedTab]] ? (
                  <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress />
                  </Box>
                ) : (
                  React.createElement(sectionConfig.find(s => s.id === availableSections[selectedTab])?.component, {
                    data: sectionData[availableSections[selectedTab]]
                  })
                )}
              </Paper>
            </Grid>
          )}

        </Grid>
      </Box>

      {/* Watchlist Modal */}
      {stockData && (
        <AddToWatchlistModal
          open={showAddToWatchlistModal}
          onClose={handleCloseModal}
          asset={stockData}
          userId={user?._id}
          onSuccess={handleWatchlistSuccess}
          assetType="stock"
        />
      )}
    </Container>
  );
};

export default Stock;