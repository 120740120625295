import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  Brightness4 as Brightness4Icon,
  Menu as MenuIcon,
  Close as CloseIcon
} from '@mui/icons-material';

const GuestLayout = ({ children, darkMode, setDarkMode }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePricingClick = () => {
    localStorage.setItem('scrollToSection', 'pricing');
    navigate('/', { state: { scrollTo: 'pricing' } });
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuClose();
  };

  // Desktop Navigation Items
  const DesktopNav = () => (
    <>
      <Button color="inherit" onClick={handlePricingClick}>Pricing</Button>
      <Button color="inherit" component={Link} to="/login">Login</Button>
      <Button color="inherit" component={Link} to="/signup">Sign Up</Button>
      <IconButton
        onClick={() => setDarkMode(!darkMode)}
        edge="end"
        color="inherit"
      >
        <Brightness4Icon />
      </IconButton>
    </>
  );

  // Mobile Navigation Items with container to control position
  const MobileNav = () => (
    <Box sx={{ marginLeft: 'auto' }}>
      <IconButton
        color="inherit"
        onClick={handleMenuOpen}
      >
        {Boolean(anchorEl) ? (
          <CloseIcon />
        ) : (
          <MenuIcon />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'rgb(13, 34, 55)',
            width: 150,
            position: 'absolute',
            right: 16,
            left: 'auto !important',
            marginTop: '40px',  // Added more margin to clear the icon
            zIndex: -1  // This ensures the menu appears below the icon
          },
          '& .MuiMenuItem-root': {
            ...theme.typography.button,
            color: 'inherit',
            padding: '8px 16px'
          }
        }}
      >
        <MenuItem onClick={handlePricingClick}>Pricing</MenuItem>
        <MenuItem onClick={() => handleNavigation('/login')}>Login</MenuItem>
        <MenuItem onClick={() => handleNavigation('/signup')}>Sign Up</MenuItem>
        <MenuItem onClick={() => { setDarkMode(!darkMode); handleMenuClose(); }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Brightness4Icon sx={{ mr: 1 }} />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar sx={{ px: { xs: 2, sm: 4 } }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            MarketRodeo
          </Typography>
          {isMobile ? <MobileNav /> : <DesktopNav />}
        </Toolbar>
      </AppBar>
      <main>{children}</main>
    </div>
  );
};

export default GuestLayout;