import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    // Tooltip,
    CircularProgress,
    useTheme,
    useMediaQuery,
    // IconButton
} from '@mui/material';
// import {
//     InfoOutlined as InfoIcon,
//     Refresh as RefreshIcon
// } from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip as ChartTooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ChartTooltip,
    Legend
);

const ReturnComparisonChart = ({
    symbols,
    fullDataCache,
    onRefresh = () => { },
    onError = () => { }
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const chartRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [chartData, setChartData] = useState(null);
    // const [lastUpdated, setLastUpdated] = useState(null);

    // Colors for the chart bars
    const colors = {
        0: 'rgba(25, 118, 210, 0.8)',  // Blue
        1: 'rgba(14, 179, 91, 0.8)',   // Green
        2: 'rgba(230, 35, 51, 0.8)',   // Red
        3: 'rgba(117, 117, 117, 0.8)', // Grey
        4: 'rgba(255, 215, 0, 0.8)',   // Gold
        5: 'rgba(156, 39, 176, 0.8)',  // Purple
        6: 'rgba(255, 152, 0, 0.8)',   // Orange
        7: 'rgba(0, 188, 212, 0.8)'    // Cyan
    };

    // Timeframes to show in the chart
    const timeframes = [
        { id: 'return1M', label: '1 Month' },
        { id: 'return6M', label: '6 Months' },
        { id: 'returnYTD', label: 'YTD' },
        { id: 'return1Y', label: '1 Year' },
        { id: 'return3Y', label: '3 Years' },
        { id: 'return5Y', label: '5 Years' }
    ];

    useEffect(() => {
        if (symbols.length === 0 || !fullDataCache) {
            setChartData(null);
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const cacheKey = symbols.sort().join(',');
            const cachedData = fullDataCache[cacheKey];

            if (!cachedData || !Array.isArray(cachedData) || cachedData.length === 0) {
                // Set a more friendly error message
                setError('Waiting for data to be available...');
                setLoading(false);
                return;
            }

            const performanceData = calculateReturns(cachedData);
            const formattedChartData = formatChartData(performanceData);

            setChartData(formattedChartData);
            // setLastUpdated(new Date());
            setLoading(false);
        } catch (err) {
            console.error('Error processing chart data:', err);
            setError('Unable to process data. Please try again later.');
            onError('Failed to process chart data');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols, fullDataCache, onError]);

    // Calculate returns for each timeframe
    // In both ReturnComparisonChart and AssetPerformanceComparison
    const calculateReturns = (cachedData) => {
        const now = new Date();

        // Define timeframe start dates exactly like StockComparison does
        const getStartDate = (timeframe) => {
            switch (timeframe) {
                case '1M':
                    return new Date(new Date().setMonth(now.getMonth() - 1));
                case '6M':
                    return new Date(new Date().setMonth(now.getMonth() - 6));
                case 'YTD':
                    return new Date(now.getFullYear(), 0, 1); // January 1st
                case '1Y':
                    return new Date(new Date().setFullYear(now.getFullYear() - 1));
                case '3Y':
                    return new Date(new Date().setFullYear(now.getFullYear() - 3));
                case '5Y':
                    return new Date(new Date().setFullYear(now.getFullYear() - 5));
                default:
                    return new Date(now.getFullYear(), 0, 1);
            }
        };

        return cachedData.map(symbolData => {
            if (!symbolData || !symbolData.symbol || !Array.isArray(symbolData.fullData) || symbolData.fullData.length === 0) {
                return {
                    symbol: symbolData?.symbol || 'Unknown',
                    return1M: null,
                    return6M: null,
                    returnYTD: null,
                    return1Y: null,
                    return3Y: null,
                    return5Y: null
                };
            }

            const sortedData = [...symbolData.fullData].sort((a, b) => new Date(a.date) - new Date(b.date));
            const latestPrice = sortedData[sortedData.length - 1]?.close;

            // For each timeframe, filter data and use first point as reference
            const calculate = (timeframe) => {
                const startDate = getStartDate(timeframe);
                const filteredData = sortedData
                    .filter(d => d && d.date && d.close && new Date(d.date) >= startDate)
                    .sort((a, b) => new Date(a.date) - new Date(b.date));

                if (filteredData.length === 0) return null;

                const basePrice = filteredData[0].close;
                if (basePrice <= 0) return null;

                return ((latestPrice - basePrice) / basePrice) * 100;
            };

            // Calculate returns for each timeframe using the same method
            return {
                symbol: symbolData.symbol,
                return1M: calculate('1M'),
                return6M: calculate('6M'),
                returnYTD: calculate('YTD'),
                return1Y: calculate('1Y'),
                return3Y: calculate('3Y'),
                return5Y: calculate('5Y')
            };
        });
    };

    // Format data for Chart.js
    const formatChartData = (performanceData) => {
        // Format data for Chart.js - always show all timeframes
        return {
            labels: timeframes.map(tf => tf.label),
            datasets: performanceData.map((item, index) => ({
                label: item.symbol,
                data: timeframes.map(tf => item[tf.id]),
                backgroundColor: colors[index % Object.keys(colors).length],
                borderColor: colors[index % Object.keys(colors).length].replace('0.8', '1'),
                borderWidth: 1,
                barPercentage: 0.8,
                categoryPercentage: 0.7
            }))
        };
    };

    // const handleRefresh = () => {
    //     onRefresh();
    // };

    // Chart.js options
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                align: 'center',
                labels: {
                    boxWidth: 12,
                    padding: 15,
                    color: theme.palette.text.primary,
                    font: {
                        size: 11
                    }
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        if (value === null || value === undefined) return `${context.dataset.label}: N/A`;

                        const formattedValue = value.toFixed(2);
                        return `${context.dataset.label}: ${formattedValue}%`;
                    }
                },
                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                titleColor: theme.palette.text.primary,
                bodyColor: theme.palette.text.primary,
                borderColor: theme.palette.divider,
                borderWidth: 1,
                padding: 10,
                displayColors: true
            },
            datalabels: false
        },
        scales: {
            y: {
                grid: {
                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    drawBorder: false
                },
                ticks: {
                    callback: (value) => `${value}%`,
                    color: theme.palette.text.secondary,
                    font: {
                        size: 11
                    }
                },
                suggestedMin: -60,
                suggestedMax: 2500
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: 12,
                        weight: 'bold'
                    }
                }
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 0
            }
        },
        layout: {
            padding: {
                top: 20,
                right: 20,
                bottom: 10,
                left: 10
            }
        },
        animation: {
            duration: 500
        }
    };

    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4
            }}>
                <Typography variant="h6">Returns Comparison</Typography>

                {/* <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    {lastUpdated && (
                        <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                            {lastUpdated.toLocaleTimeString()}
                        </Typography>
                    )}
                    <IconButton
                        size="small"
                        onClick={handleRefresh}
                        disabled={loading}
                        title="Refresh data"
                    >
                        <RefreshIcon fontSize="small" />
                    </IconButton>
                </Box>
                */}
            </Box>

            {symbols.length === 0 ? (
                <Typography variant="body2" color="text.secondary" sx={{ py: 4, textAlign: 'center' }}>
                    Add symbols to view returns comparison
                </Typography>
            ) : loading || !chartData ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: isMobile ? 250 : 300 }}>
                    <CircularProgress size={40} />
                </Box>
            ) : error ? (
                <Box sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
                    <Typography variant="body2">
                        {error}
                    </Typography>
                </Box>
            ) : (
                <Box sx={{
                    height: isMobile ? 250 : 450,
                    width: '100%',
                    position: 'relative',
                    mt: 2
                }}>
                    <Bar
                        ref={chartRef}
                        data={chartData}
                        options={chartOptions}
                    />
                </Box>
            )}

    
        </Box>
    );
};

export default ReturnComparisonChart;