import React /*,{ useState }*/ from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  useTheme,
  Button,
  Box,
  Paper,
} from '@mui/material';
import TextCarousel from './TextCarousel';
import StaticHeroImage from './StaticHeroImage';

// const WaveBackground = () => (
//   <Box
//     sx={{
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       right: 0,
//       height: '100%',
//       overflow: 'hidden',
//       zIndex: 0,
//       opacity: 0.1,
//     }}
//   >
//     <svg
//       viewBox="0 0 1440 320"
//       preserveAspectRatio="none"
//       style={{
//         position: 'absolute',
//         width: '100%',
//         height: '100%',
//         transform: 'scale(1.5)',
//       }}
//     >
//       <path
//         fill="currentColor"
//         d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
//       />
//     </svg>
//   </Box>
// );

const Metric = ({ label, value }) => (
  <Box
    sx={{
      textAlign: 'center',
      p: {
        xs: 1,      // Mobile
        sm: 1.5,    // Tablet
        md: 1.75,   // Small desktop/iPad Pro
        lg: 2       // Large desktop
      },
      background: 'linear-gradient(145deg, rgba(25, 118, 210, 0.1), rgba(25, 118, 210, 0.05))',
      borderRadius: 2,
      backdropFilter: 'blur(8px)',
      transition: 'transform 0.3s ease-in-out',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '&:hover': {
        transform: 'translateY(-4px)',
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        fontWeight: 700,
        mb: { xs: 0.25, sm: 0.5, md: 0.75 },
        fontSize: {
          xs: '0.875rem',   // Mobile: 14px
          sm: '1rem',       // Tablet: 16px
          md: '1.25rem',    // iPad Pro/Small desktop: 20px
          lg: '2rem'        // Desktop: 32px
        },
        lineHeight: 1.2     // Tighter line height to prevent overlap
      }}
    >
      {value}
    </Typography>
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{
        fontSize: {
          xs: '0.625rem',   // Mobile: 10px
          sm: '0.75rem',    // Tablet: 12px
          md: '0.875rem',   // iPad Pro/Small desktop: 14px
          lg: '1rem'        // Desktop: 16px
        },
        lineHeight: 1.2     // Tighter line height to prevent overlap
      }}
    >
      {label}
    </Typography>
  </Box>
);

const HeroSection = () => {
  const theme = useTheme();
  // const [showPreview, setShowPreview] = useState(false);

  const phrases = [
    'retail traders',
    'new investors',
    'active traders',
    'long-term investors',
    'student investors',
    'everyone'
  ];

  const metrics = [
    { label: 'Financial Instruments', value: '80,000+' },
    { label: 'Analysis Tools', value: 'Advanced' },
    { label: 'Market Coverage', value: 'Global' },
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        pt: { xs: 4, md: 8 },
        pb: { xs: 6, md: 12 },
      }}
    >
      {/* <WaveBackground /> */}

      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 2, md: 4 }} alignItems="center">
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: { xs: '300px', md: 'auto' }, // Ensure minimum height on mobile
              }}
            >
              {/* Main heading container */}
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 800,
                  fontSize: {
                    xs: '2.25rem',    // 36px on mobile
                    sm: '2.75rem',    // 44px on small screens
                    md: '3.25rem',    // 52px on medium screens
                    lg: '3.75rem'     // 60px on large screens
                  },
                  lineHeight: 1.2,
                  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
                  mb: { xs: 2, sm: 2.5, md: 3 },
                  width: '100%',
                  textAlign: { xs: 'center' }
                }}
              >
                Powerful tools for
              </Typography>

              <Box
                sx={{
                  height: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' }, // Adjusted mobile height
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: { xs: 4, md: 4.5 },
                  overflow: 'visible',
                }}
              >
                <TextCarousel phrases={phrases} intervalDuration={3000} />
              </Box>

              <Typography
                variant="h5"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: '0.875rem', sm: '1.1rem', md: '1.5rem' }, // Smaller on mobile
                  lineHeight: { xs: 1.4, md: 1.5 },
                  color: 'text.secondary',
                  textAlign: 'center',
                  maxWidth: '100%',
                  mx: 'auto',
                  px: { xs: 1, md: 0 }, // Less padding on mobile
                  mb: { xs: 5, md: 6 },
                }}
              >
                Comprehensive Market Analysis That Fits Every Need
              </Typography>

              <Grid container spacing={{ xs: 1, sm: 1.5, md: 2 }}
                sx={{
                  mb: { xs: 6, sm: 7, md: 8 }, // Reduced margin bottom
                  mt: { xs: 1, sm: 1, md: 2 }, // Added margin top
                  '& .MuiGrid-item': {
                    paddingTop: '0 !important' // Remove default Grid item spacing
                  }
                }}
              >
                {metrics.map((metric, index) => (
                  <Grid item xs={4} key={index}>
                    <Metric {...metric} />
                  </Grid>
                ))}
              </Grid>

              {/* CTA buttons with proper mobile spacing */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Link
                  to="/Signup"
                  style={{
                    textDecoration: 'none',
                    width: { xs: '100%', sm: 'auto' }
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth={true}
                    sx={{
                      // Adjusted padding for better scaling
                      py: {
                        xs: 1,      // Smaller padding on mobile
                        sm: 1.25,   // Medium padding on tablet
                        md: 2       // Original padding on desktop
                      },
                      px: {
                        xs: 2,      // Smaller padding on mobile
                        sm: 3,      // Medium padding on tablet
                        md: 4       // Original padding on desktop
                      },
                      // Adjusted font size
                      fontSize: {
                        xs: '0.875rem',  // 14px on mobile
                        sm: '0.9375rem', // 15px on tablet
                        md: '1.1rem'     // Original size on desktop
                      },
                      fontWeight: 600,
                      borderRadius: 2,
                      background: 'linear-gradient(45deg, #1976d2 30%, #1565c0 90%)',
                      boxShadow: '0 3px 15px rgba(25,118,210,0.3)',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 5px 20px rgba(25,118,210,0.4)',
                      }
                    }}
                  >
                    Sign Up For Free
                  </Button>
                </Link>

                {/* Preview button commented out for future use */}
                {/* <Button
                  variant="outlined"
                  size="large"
                  fullWidth={true}
                  startIcon={<PlayCircleOutline />}
                  onClick={() => setShowPreview(!showPreview)}
                  sx={{
                    borderWidth: 2,
                    '&:hover': {
                      borderWidth: 2,
                    }
                  }}
                >
                  Preview Features
                </Button> */}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box
              sx={{
                position: 'relative',
                zIndex: 1,
                transform: 'none',
                overflow: 'visible' // Added this
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  overflow: 'visible',
                  borderRadius: 4,
                  height: {
                    xs: '300px',  // Reduced from 450px
                    sm: '600px',
                    md: '750px',
                    lg: '850px'
                  },
                  width: '100%',
                  backgroundColor: 'transparent',
                }}
              >
                <StaticHeroImage />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Preview modal commented out for future use */}
      {/* <Fade in={showPreview}>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0,0,0,0.8)',
            zIndex: 1300,
            display: showPreview ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
          }}
          onClick={() => setShowPreview(false)}
        >
          <Box
            sx={{
              maxWidth: 'lg',
              width: '100%',
              maxHeight: '90vh',
              overflow: 'auto',
              bgcolor: 'background.paper',
              borderRadius: 2,
              p: 4,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography variant="h4" gutterBottom>
              Platform Features
            </Typography>
          </Box>
        </Box>
      </Fade> */}
    </Box>
  );
};

export default HeroSection;