import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import InsiderTradingStatistics from './InsiderTradingStatistics';
import InsiderTrading from './InsiderTrading';

const CombineInsiderTrading = ({ data }) => {
  // If there's no data at all, show loading state
  if (!data) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  // Extract the separate data arrays from the combined data
  const { insiderTrading, insiderTradingStatistics } = data;

  // Check if either data array is missing
  const hasInsiderTrading = insiderTrading && insiderTrading.length > 0;
  const hasStatistics = insiderTradingStatistics && insiderTradingStatistics.length > 0;

  // If both are missing, show a message
  if (!hasInsiderTrading && !hasStatistics) {
    return (
      <Box p={3}>
        <Typography variant="body1">No insider trading data available for this symbol</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Always render statistics first if available */}
      {hasStatistics && (
        <InsiderTradingStatistics data={insiderTradingStatistics} />
      )}
      
      {/* Then render transactions if available */}
      {hasInsiderTrading && (
        <Box mt={hasStatistics ? 4 : 0}>
          <InsiderTrading data={insiderTrading} />
        </Box>
      )}
    </Box>
  );
};

export default CombineInsiderTrading;