import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
    Box,
    Container,
    Typography,
    CircularProgress,
    Alert,
    FormControl,
    TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from '../config/axiosConfig';
import TitleAndInfo from './TitleAndInfo';

const PAGE_SIZE = 10;

const InsiderTracker = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Pagination state
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // Filter state
    const [selectedSymbol, setSelectedSymbol] = useState('');

    // Cache state - structure: { [symbol]: { [page]: data[] } }
    const [cachedData, setCachedData] = useState({});

    const titleTop = "Insider Trading Activity";
    const title = "";
    const info = "Track insider trading activities of corporate executives, directors, and major shareholders. View detailed information about stock purchases, sales, and transaction amounts.";

    const fetchTradesData = async (page, symbol) => {
        // Check if data is already cached
        const symbolCache = cachedData[symbol || 'all'] || {};
        if (symbolCache[page] !== undefined) {
            return;
        }

        try {
            setLoading(true);
            const endpoint = symbol
                ? `/api/insiderTrading/${symbol}`
                : '/api/insiderTrading';

            const response = await axios.get(endpoint, {
                params: {
                    page: page,
                    limit: PAGE_SIZE
                }
            });

            const { data: responseData, pagination } = response.data;
            const total = pagination?.total || 0;

            // Transform the data for the grid
            const transformedData = responseData.map(trade => ({
                id: trade._id || `${trade.reportingCik}-${trade.transactionDate}-${Math.random().toString(36).substr(2, 9)}`,
                reportingName: trade.reportingName,
                typeOfOwner: trade.typeOfOwner,
                companySymbol: trade.symbol,
                securityName: trade.securityName,
                filingDate: new Date(trade.filingDate),
                transactionDate: new Date(trade.transactionDate),
                securitiesTransacted: trade.securitiesTransacted,
                price: trade.price,
                transactionType: trade.transactionType,
                acquisitionOrDisposition: trade.acquisitionOrDisposition
            }));

            // Update cache
            setCachedData(prev => ({
                ...prev,
                [symbol || 'all']: {
                    ...(prev[symbol || 'all'] || {}),
                    [page]: transformedData
                }
            }));

            setTotalRows(total);
            setError(null);
        } catch (error) {
            console.error('Error fetching insider trades data:', error);
            setError(error.message || 'Failed to fetch insider trades data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTradesData(page, selectedSymbol);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, selectedSymbol]);

    const handleSymbolChange = (event) => {
        const newSymbol = event.target.value.toUpperCase();
        setSelectedSymbol(newSymbol);
        setPage(0); // Reset to first page when changing symbol
        // Clear cache for new symbol if it doesn't exist
        if (!cachedData[newSymbol || 'all']) {
            setCachedData(prev => ({
                ...prev,
                [newSymbol || 'all']: {}
            }));
        }
    };

    // Get current data from cache
    const currentData = (cachedData[selectedSymbol || 'all'] || {})[page] || [];

    // Format transaction type
    const formatTransactionType = (type) => {
        if (!type) return '';

        // Parse the code and description format (e.g., "P-Purchase")
        const parts = type.split('-');
        const code = parts[0]?.trim();
        const description = parts[1]?.trim();

        // If it's already parsed, return a user-friendly name
        switch (code) {
            case 'P': return 'Buy';
            case 'S': return 'Sale';
            case 'A': return 'Award';
            case 'X': return 'Option Exercise';
            case 'O': return 'Option Exercise';
            case 'G': return 'Gift';
            case 'C': return 'Conversion';
            case 'M': return 'Exempt';
            case 'L': return 'Small Acquisition';
            case 'D': return 'Return';
            case 'J': return 'Other';
            case 'F': return 'In-Kind';
            case 'I': return 'Discretionary';
            case 'W': return 'Will/Inheritance';
            case 'Z': return 'Trust';
            case 'U': return 'Tender';
            case 'E': return 'Expire Short';
            case 'H': return 'Expire Long';
            default: break;
        }

        // If description is provided, use it
        if (description) return description;

        // Handle other common cases
        const lowerType = type.toLowerCase();
        if (lowerType.includes('purchase') || lowerType.includes('buy')) {
            return 'Buy';
        } else if (lowerType.includes('sale') || lowerType.includes('sell')) {
            return 'Sale';
        } else if (lowerType.includes('option') || lowerType.includes('exercise')) {
            return 'Option Exercise';
        } else if (lowerType.includes('proposed') || lowerType.includes('intent')) {
            return 'Proposed Sale';
        }

        return type;
    };

    // Format currency
    const formatCurrency = (value) => {
        if (!value && value !== 0) return 'N/A';
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    // Format name (convert from all caps to proper case)
    const formatName = (name) => {
        if (!name) return '';
        return name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    // Format owner type (remove prefixes like "officer:" and handle "See Remarks")
    const formatOwnerType = (ownerType) => {
        if (!ownerType) return '';

        // Handle "See Remarks" case
        if (ownerType.includes('See Remarks')) {
            return '';
        }

        // Original logic for other cases
        if (ownerType.includes(':')) {
            return ownerType.split(':')[1].trim();
        }

        return ownerType;
    };

    const columns = [
        {
            field: 'insider',
            headerName: 'Insider',
            minWidth: 250,
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Typography
                        sx={{
                            color: theme.palette.primary.main,
                            fontSize: '1rem',
                        }}
                    >
                        {formatName(params.row.reportingName)}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            fontWeight: 500,
                        }}
                    >
                        {formatOwnerType(params.row.typeOfOwner)}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 240,
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <RouterLink
                        to={`/asset/${params.row.companySymbol}`}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {params.row.companySymbol}
                    </RouterLink>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            fontWeight: 500,
                        }}
                    >
                        {params.row.securityName}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'transactionDate',
            headerName: 'Transaction Date',
            flex: 1,
            minWidth: 130,
            valueFormatter: (params) => {
                return params.value.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            }
        },
        {
            field: 'filingDate',
            headerName: 'Filing Date',
            flex: 1,
            minWidth: 130,
            valueFormatter: (params) => {
                return params.value.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            }
        },
        {
            field: 'price',
            headerName: 'Price',
            minWidth: 100,
            flex: 0.7,
            valueFormatter: (params) => formatCurrency(params.value)
        },
        {
            field: 'securitiesTransacted',
            headerName: 'Shares',
            minWidth: 100,
            flex: 0.7,
            valueFormatter: (params) => params.value.toLocaleString()
        },
        {
            field: 'value',
            headerName: 'Value',
            minWidth: 120,
            flex: 0.8,
            valueGetter: (params) => {
                return params.row.price * params.row.securitiesTransacted;
            },
            valueFormatter: (params) => formatCurrency(params.value)
        },
        {
            field: 'transactionType',
            headerName: 'Transaction',
            minWidth: 140,
            flex: 0.8,
            renderCell: (params) => {
                const transactionType = formatTransactionType(params.row.transactionType);

                // Determine color based on transaction type
                let color;
                let bgColor;

                if (['Buy', 'Purchase', 'Award', 'Small Acquisition'].includes(transactionType)) {
                    color = '#fff';
                    bgColor = '#1e7e34'; // Dark green
                } else if (['Sale', 'Tender'].includes(transactionType)) {
                    color = '#fff';
                    bgColor = '#bd2130'; // Dark red
                } else if (['Option Exercise'].includes(transactionType)) {
                    color = '#fff';
                    bgColor = '#0056b3'; // Dark blue
                } else if (['Proposed Sale'].includes(transactionType)) {
                    color = '#000';
                    bgColor = '#ffc107'; // Dark yellow/amber
                } else if (['Gift', 'Will/Inheritance', 'Trust'].includes(transactionType)) {
                    color = '#fff';
                    bgColor = '#6c757d'; // Dark gray
                } else if (['Conversion', 'Exempt', 'In-Kind', 'Discretionary', 'Other'].includes(transactionType)) {
                    color = '#000';
                    bgColor = '#e9ecef'; // Light gray
                } else {
                    color = '#fff';
                    bgColor = '#6610f2'; // Purple for anything else
                }

                return (
                    <Typography
                        sx={{
                            color: color,
                            backgroundColor: bgColor,
                            fontWeight: 600,
                            borderRadius: '4px',
                            padding: '4px 8px',
                            fontSize: '0.8125rem',
                            display: 'inline-block',
                            textAlign: 'center',
                            width: 'fit-content',
                            minWidth: '90px'
                        }}
                    >
                        {transactionType}
                    </Typography>
                );
            }
        }
    ];

    // Track if this is the initial load
    const isInitialLoad = loading && !Object.keys(cachedData).length;

    if (isInitialLoad) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error">
                {error}
            </Alert>
        );
    }

    return (
        <Container maxWidth="xl">
            <TitleAndInfo titleTop={titleTop} title={title} info={info} />

            {/* Symbol Filter */}
            <Box sx={{ mb: 3 }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <TextField
                        label="Filter by Symbol"
                        value={selectedSymbol}
                        onChange={handleSymbolChange}
                        placeholder="Enter stock symbol"
                        variant="outlined"
                        size="small"
                    />
                </FormControl>
            </Box>

            <Box sx={{ height: 650, width: '100%' }}>
                <DataGrid
                    rows={currentData}
                    columns={columns}
                    paginationMode="server"
                    rowCount={totalRows}
                    pageSize={PAGE_SIZE}
                    paginationModel={{
                        page,
                        pageSize: PAGE_SIZE,
                    }}
                    onPaginationModelChange={(model) => {
                        setPage(model.page);
                    }}
                    loading={loading}
                    disableColumnMenu
                    hideFooterRowCount
                    hideFooterSelectedRowCount
                />
            </Box>
        </Container>
    );
};

export default InsiderTracker;