import React, { useState, useEffect } from 'react';
import { Slide, Typography, Fade, Box, useTheme } from '@mui/material';

const TextCarousel = ({ phrases, intervalDuration = 3000 }) => {
  const theme = useTheme();
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [intervalDuration]);

  useEffect(() => {
    let timeout;
    if (!fadeIn) {
      timeout = setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setFadeIn(true);
      }, 400); // Slightly faster transition for more snappiness
    }

    return () => clearTimeout(timeout);
  }, [fadeIn, phrases.length]);

  const currentPhrase = phrases[currentPhraseIndex];

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Slide 
        direction="up" 
        in={true} 
        mountOnEnter 
        unmountOnExit
        timeout={{ enter: 400, exit: 300 }} // Adjusted for smoother motion
      >
        <Fade in={fadeIn} timeout={400}>
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: '1.5rem',
                sm: '2rem',
                md: '2.5rem',
                lg: '3.3rem'
              },
              lineHeight: 1.2,
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              textAlign: 'center',
              display: 'block',
              background: theme.palette.mode === 'dark'
                ? 'linear-gradient(135deg, #007FFF 0%, #0059B2 100%)'
                : 'linear-gradient(135deg, #007FFF 0%, #0059B2 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.02)', // Subtle scale on hover
                background: theme.palette.mode === 'dark'
                  ? 'linear-gradient(135deg, #66B2FF 0%, #0059B2 100%)'
                  : 'linear-gradient(135deg, #66B2FF 0%, #0059B2 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }
            }}
          >
            {currentPhrase}
          </Typography>
        </Fade>
      </Slide>
    </Box>
  );
};

export default TextCarousel;