import React, { useState, useEffect } from 'react';
import { Avatar, Box } from '@mui/material';

const ProfileImage = ({ name, imageUrls = [], size = 80, sx = {} }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0); // Track the current URL being used
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        // Reset state whenever `imageUrls` changes
        setCurrentImageIndex(0);
        setImageError(false);
    }, [imageUrls]);

    // Helper function to generate initials or fallback text
    const getFallbackText = (name) => {
        if (!name) return '?';
        const nameParts = name.split(' ');
        if (nameParts.length > 1) {
            return nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
        }
        return name.charAt(0).toUpperCase();
    };

    const handleImageError = () => {
        if (currentImageIndex < imageUrls.length - 1) {
            // Try the next image in the array
            setCurrentImageIndex(currentImageIndex + 1);
        } else {
            // No more images to try, show fallback
            setImageError(true);
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: size,
                height: size,
                ...sx,
            }}
        >
            {/* Profile Image */}
            {!imageError && imageUrls[currentImageIndex] ? (
                <Box
                    component="img"
                    src={imageUrls[currentImageIndex]}
                    alt={name}
                    onError={handleImageError} // Handle image load failure
                    sx={{
                        width: size,
                        height: size,
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: `2px solid white`,
                    }}
                />
            ) : (
                // Fallback Avatar
                <Avatar
                    sx={{
                        width: size,
                        height: size,
                        fontSize: size / 2.5,
                        fontWeight: 500,
                        color: 'text.primary',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {getFallbackText(name)}
                </Avatar>
            )}
        </Box>
    );
};

export default ProfileImage;
