/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box,
    Container,
    CircularProgress,
    Alert,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from '../config/axiosConfig';
import TitleAndInfo from './TitleAndInfo';
import { findBioguideId } from '../utils/congressMembersMapping';
import debounce from 'lodash/debounce';
import MembersGrid from './MembersGrid';

const CongressMembers = () => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observerTarget = useRef(null);
    const currentSearchRef = useRef('');

    const titleTop = "Congress Members";
    const title = "";
    const info = "Track and analyze the trading activities of all members of Congress. Click on any member to view their detailed trading history and patterns.";

    const fetchMembers = useCallback(async (isNewSearch = false, searchPattern = '') => {
        if (!hasMore && !isNewSearch) return;
        
        try {
            setLoading(true);
            const currentPage = isNewSearch ? 1 : page;
            const searchQuery = isNewSearch ? searchPattern : currentSearchRef.current;
            
            const response = await axios.get('/api/congress/members', {
                params: {
                    page: currentPage,
                    limit: 20,
                    search: searchQuery
                }
            });

            const { data: responseData } = response.data;

            const transformedMembers = responseData.map(member => {
                const fullName = member.name?.trim();
                const bioguideId = fullName ? findBioguideId(fullName) : null;

                if (!fullName) {
                    console.error('Invalid member data:', member);
                    return null;
                }

                return {
                    ...member,
                    fullName,
                    bioguideId,
                    firstLetter: fullName.charAt(0).toUpperCase(),
                    displayDistrict: member.type === 'senate' ? 'Senate' : member.district
                };
            }).filter(Boolean);

            if (isNewSearch) {
                setMembers(transformedMembers);
                setPage(1);
                currentSearchRef.current = searchPattern;
            } else {
                setMembers(prev => [...prev, ...transformedMembers]);
            }
            
            setHasMore(responseData.length === 20);
            setError(null);
        } catch (error) {
            console.error('Error fetching members:', error);
            setError(error.message || 'Failed to fetch members data');
        } finally {
            setLoading(false);
        }
    }, [page, hasMore]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            if (searchValue.length >= 2 || searchValue === '') {
                fetchMembers(true, searchValue);
            }
        }, 800),
        [fetchMembers]
    );

    const handleSearchChange = (e) => {
        const newTerm = e.target.value;
        setSearchTerm(newTerm);
        
        debouncedSearch.cancel();
        debouncedSearch(newTerm.trim());
    };

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !loading) {
            setPage(prev => prev + 1);
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 0.1
        });

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [handleObserver]);

    // Initial load
    useEffect(() => {
        fetchMembers(true, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Only run on mount

    // Handle pagination
    useEffect(() => {
        if (page > 1) {
            fetchMembers(false);
        }
    }, [page, fetchMembers]);

    if (error) {
        return (
            <Alert severity="error">
                {error}
            </Alert>
        );
    }

    return (
        <Container maxWidth="xl">
            <TitleAndInfo titleTop={titleTop} title={title} info={info} />

            {/* Search Bar */}
            <Box sx={{ mb: 4 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Find by name"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        maxWidth: '300px',
                    }}
                />
            </Box>

            {/* Show loading state for initial load */}
            {loading && members.length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                    <CircularProgress />
                </Box>
            )}

            {/* Members Grid - Memoized */}
            {members.length > 0 && <MembersGrid members={members} />}

            {/* Loading indicator and observer target */}
            <Box ref={observerTarget} sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                p: 2,
                minHeight: '100px'
            }}>
                {loading && members.length > 0 && (
                    <CircularProgress />
                )}
            </Box>
        </Container>
    );
};

export default CongressMembers;