import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from '../config/axiosConfig';

const ExtendedHoursDisplay = ({ symbol, exchange, regularPrice, currency, todayHoliday }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [extendedData, setExtendedData] = useState(null);
    const [, setError] = useState(null);
    const [currentSession, setCurrentSession] = useState(null);

    // Array of US exchanges we support
    const supportedUSExchanges = ['NYSE', 'NASDAQ', 'CBOE', 'AMEX'];

    // Function to determine if we're in pre or after market hours using NYC time
    const determineMarketSession = () => {
        if (todayHoliday) {
            return null; // Don't show extended hours on holidays
        }
        // Get current date and time in Eastern Time (ET) for US markets
        const now = new Date();

        // Format the date to get ET time
        const options = { timeZone: 'America/New_York' };
        const nyTime = new Date(now.toLocaleString('en-US', options));

        const hours = nyTime.getHours();
        const minutes = nyTime.getMinutes();
        const dayOfWeek = nyTime.getDay();

        // Check if it's a weekday (1-5 represents Monday-Friday)
        const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;

        if (!isWeekday) {
            return null;
        }

        // Pre-market hours: 4:00 AM to 9:30 AM ET
        if ((hours === 4 && minutes >= 0) ||
            (hours > 4 && hours < 9) ||
            (hours === 9 && minutes < 30)) {
            return 'pre';
        }
        // After-market hours: 4:00 PM to 8:00 PM ET
        else if ((hours === 16 && minutes >= 0) ||
            (hours > 16 && hours < 20)) {
            return 'after';
        }
        // Regular market hours or closed
        return null;
    };

    useEffect(() => {
        // Check if the component should be active based on exchange
        if (!supportedUSExchanges.includes(exchange?.toUpperCase())) {
            return;
        }

        // Determine current market session using NYC time
        const session = determineMarketSession();
        setCurrentSession(session);

        // Only fetch data if we're in extended hours and have the necessary information
        if (session && symbol) {
            fetchExtendedHoursData(symbol, session);
        }

        // Set up interval to refresh data every 5 minutes
        const intervalId = setInterval(() => {
            const currentSession = determineMarketSession();
            setCurrentSession(currentSession);

            if (currentSession && symbol) {
                fetchExtendedHoursData(symbol, currentSession);
            }
        }, 5 * 60 * 1000); // 5 minutes

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbol, exchange]);

    const fetchExtendedHoursData = async (symbol, marketSession) => {
        if (!symbol || !marketSession) return;

        setLoading(true);
        setError(null);

        try {
            const response = await axios.get('/api/extended-hours', {
                params: {
                    symbols: symbol,
                    marketSession
                }
            });

            if (response.data && response.data.data && response.data.data.length > 0) {
                setExtendedData(response.data.data[0]);
            } else {
                setExtendedData(null);
            }
        } catch (err) {
            console.error('Error fetching extended hours data:', err);
            setError('Failed to fetch extended hours data');
            setExtendedData(null);
        } finally {
            setLoading(false);
        }
    };

    // Calculate percentage change if both prices are available
    const calculatePercentageChange = () => {
        if (!extendedData || !regularPrice || !extendedData.price) {
            return null;
        }

        const change = ((extendedData.price - regularPrice) / regularPrice) * 100;
        return change.toFixed(2);
    };

    // Format price for display
    const formatPrice = (price) => {
        if (price == null) {
            return 'N/A';
        }
        // Use Math.round to ensure proper rounding to 2 decimal places
        return (Math.round(price * 100) / 100).toFixed(2);
    };

    // Only render the component if we're on a supported exchange and in extended hours
    if (!supportedUSExchanges.includes(exchange?.toUpperCase()) || !currentSession) {
        return null;
    }

    return (
        <Box>
            {/* Only render when we have extended hours data and we're in pre/after market hours */}
            {extendedData && currentSession && (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    mt: 0.5
                }}>
                    <Chip
                        label={currentSession === 'pre' ? 'Pre-market' : 'After-market'}
                        color={currentSession === 'pre' ? 'primary' : 'secondary'}
                        size="small"
                        sx={{
                            borderRadius: 1,
                            fontSize: '0.7rem',
                            height: 24
                        }}
                    />

                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'medium',
                            fontSize: '1.2rem'
                        }}
                    >
                        ${formatPrice(extendedData.price)}
                    </Typography>

                    {calculatePercentageChange() !== null && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: parseFloat(calculatePercentageChange()) >= 0
                                    ? theme.palette.positive.main
                                    : theme.palette.negative.main,
                                fontSize: '0.9rem'
                            }}
                        >
                            {`${calculatePercentageChange() >= 0 ? '+' : ''}${calculatePercentageChange()}%`}
                        </Typography>
                    )}
                </Box>
            )}

            {loading && (
                <CircularProgress size={16} />
            )}
        </Box>
    );
};

export default ExtendedHoursDisplay;