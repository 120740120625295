import React, { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';
import {
  Box,
  Typography,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  CircularProgress
} from '@mui/material';
import { Close as CloseIcon, WatchLater as ClockIcon } from '@mui/icons-material';

const MarketStatusIndicator = ({ exchange, onHolidayInfoUpdate }) => {
  const [open, setOpen] = useState(false);
  const [exchangeData, setExchangeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchExchangeData = async () => {
    try {
      setLoading(true);
      // Special case for crypto - don't need to call backend
      if (exchange?.toUpperCase() === 'CRYPTO') {
        setExchangeData({
          exchangeInfo: {
            name: 'Cryptocurrency Market',
            exchangeShortName: 'CRYPTO',
            openingHour: '24/7',
            closingHour: '24/7',
            timezone: 'UTC',
            isMarketOpen: true
          },
          marketStatuses: {
            isTheCryptoMarketOpen: true
          },
          holidays: [],
          todayHoliday: null
        });
        return;
      }

      const response = await axios.get(`/api/exchange-status/${exchange}`);
      setExchangeData(response.data.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching exchange data:', err);
      setError('Failed to load market status');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (exchange?.trim()) {
      fetchExchangeData();
    } else {
      setLoading(false);
      setExchangeData(null);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchange]);

  useEffect(() => {
    if (exchangeData?.todayHoliday && onHolidayInfoUpdate) {
      onHolidayInfoUpdate(exchangeData.todayHoliday);
    }
  }, [exchangeData, onHolidayInfoUpdate]);

  const getMarketDescription = (data) => {
    if (!data) return '';

    if (data.todayHoliday) {
      return `${data.exchangeInfo.name} is closed today in observance of ${data.todayHoliday.name}.`;
    }

    if (exchange?.toUpperCase() === 'CRYPTO') {
      return `${data.exchangeInfo.name} operates 24/7 with no trading holidays.`;
    }

    return `${data.exchangeInfo.name} operates on a regular trading schedule. Its trading hours are from ${data.exchangeInfo.openingHour} to ${data.exchangeInfo.closingHour} ${data.exchangeInfo.timezone}${data.exchangeInfo.openingAdditional ? ` (${data.exchangeInfo.openingAdditional})` : ''} Monday through Friday.`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '.');
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!exchange?.trim() || !exchangeData) return null;
  if (loading) return <CircularProgress size={20} />;
  if (error) return null;

  const { exchangeInfo /*, marketStatuses */, holidays, todayHoliday } = exchangeData;
  const isCrypto = exchange?.toUpperCase() === 'CRYPTO';

  return (
    <>
      <Chip
        label={
          todayHoliday
            ? `Closed (${todayHoliday.name})`
            : exchangeInfo.isMarketOpen
              ? "Market Open"
              : "Market Closed"
        }
        color={exchangeInfo.isMarketOpen ? "success" : "error"}
        size="small"
        onClick={handleOpen}
        icon={<ClockIcon />}
        sx={{ cursor: 'pointer' }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="market-hours-modal"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 500 },
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 3,
            maxHeight: '90vh',
            overflow: 'auto'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ClockIcon /> Trading Hours
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="body1" sx={{ mb: 3 }}>
            {getMarketDescription(exchangeData)}
          </Typography>

          {!isCrypto && holidays.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>
                The market is closed on the following holidays:
              </Typography>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Exchange holidays</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {holidays.map((holiday, index) => (
                      <TableRow
                        key={index}
                        sx={todayHoliday?.name === holiday.name ? {
                          backgroundColor: 'action.hover'
                        } : {}}
                      >
                        <TableCell>{holiday.name}</TableCell>
                        <TableCell align="right">{formatDate(holiday.date)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

         
        </Paper>
      </Modal>
    </>
  );
};

export default MarketStatusIndicator;