import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    CircularProgress,
    Alert,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import axios from '../config/axiosConfig';
import { findBioguideId } from '../utils/congressMembersMapping';
import ProfileImage from './ProfileImage';

const Politician = () => {
    const theme = useTheme();
    const { politician } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [politicianData, setPoliticianData] = useState(null);
    const [trades, setTrades] = useState([]);
    const [stats, setStats] = useState({
        yearVolume: 0,
        totalTransactions: 0,
        lastTransaction: null,
        buySellRatio: 0,
        mainSectors: [],
        topIndustries: [],
        currentYear: new Date().getFullYear()
    });

    const calculateTotalAmount = (trades) => {
        return trades.reduce((total, trade) => {
            if (!trade.amount) return total;

            let amount = 0;
            const amountStr = trade.amount.toString().toUpperCase();

            // Handle ranges like "$250K-$500K"
            if (amountStr.includes('-')) {
                // Take the higher range
                const ranges = amountStr.split('-');
                const higherRange = ranges[1].trim();
                amount = parseAmountString(higherRange);
            } else {
                amount = parseAmountString(amountStr);
            }

            return total + amount;
        }, 0);
    };

    const parseAmountString = (str) => {
        // Remove any non-numeric characters except K, M, B
        const cleanStr = str.replace(/[^0-9KMB.]/g, '');
        let multiplier = 1;

        if (cleanStr.includes('K')) {
            multiplier = 1000;
        } else if (cleanStr.includes('M')) {
            multiplier = 1000000;
        } else if (cleanStr.includes('B')) {
            multiplier = 1000000000;
        }

        // Remove K,M,B and convert to number
        const number = parseFloat(cleanStr.replace(/[KMB]/g, ''));
        return number * multiplier;
    };

    const fetchPoliticianData = async () => {
        try {
            setLoading(true);
            console.log('Fetching data for:', politician);

            // Try Senate first
            try {
                console.log('Trying Senate API...');
                const senateResponse = await axios.get(`/api/senate-trading/by-name/${encodeURIComponent(politician)}`);
                console.log('Senate response:', senateResponse.data);

                if (senateResponse.data.data && senateResponse.data.data.length > 0) {
                    console.log('Found in Senate, processing data...');
                    processPoliticianData(senateResponse.data.data, 'Senate');
                    return;
                }
            } catch (error) {
                console.log('Senate API error:', error.response || error);
                if (error.response?.status !== 404) throw error;
            }

            // If not found in Senate, try House
            console.log('Trying House API...');
            const houseResponse = await axios.get(`/api/house-disclosure/by-name/${encodeURIComponent(politician)}`);
            console.log('House response:', houseResponse.data);

            if (houseResponse.data.data && houseResponse.data.data.length > 0) {
                console.log('Found in House, processing data...');
                processPoliticianData(houseResponse.data.data, 'House');
            } else {
                throw new Error('No data found for this politician');
            }
        } catch (error) {
            console.error('Error fetching politician data:', error);
            setError(error.message || 'Failed to fetch politician data');
        } finally {
            setLoading(false);
        }
    };

    const processPoliticianData = (data, chamber) => {
        console.log('Processing data:', { data, chamber });

        // Basic politician info
        setPoliticianData({
            name: chamber === 'Senate' ? `${data[0].firstName} ${data[0].lastName}` : data[0].representative,
            party: data[0].party,
            chamber: chamber,
            state: data[0].district || '',
        });

        // Process trades
        const processedTrades = data.map(trade => ({
            id: trade._id,
            symbol: chamber === 'Senate' ? trade.symbol : trade.ticker,
            name: trade.assetDescription,
            type: trade.type,
            amount: trade.amount,
            transactionDate: trade.transactionDate,
            filedDate: chamber === 'Senate' ? trade.dateRecieved : trade.disclosureDate
        }));

        setTrades(processedTrades);

        // Get current year
        const currentYear = new Date().getFullYear();

        // Filter trades for current year
        const currentYearTrades = processedTrades.filter(trade => {
            const tradeDate = new Date(trade.transactionDate);
            return tradeDate.getFullYear() === currentYear;
        });

        // Calculate statistics
        const buyTrades = processedTrades.filter(t =>
            t.type.toLowerCase() === 'purchase' || t.type.toLowerCase() === 'buy'
        ).length;
        const sellTrades = processedTrades.filter(t =>
            t.type.toLowerCase() === 'sale' || t.type.toLowerCase() === 'sell'
        ).length;

        const ratio = sellTrades === 0 ? buyTrades : (buyTrades / sellTrades).toFixed(2);

        const dates = processedTrades.map(t => new Date(t.transactionDate));
        const lastTransaction = dates.length > 0 ? new Date(Math.max(...dates)) : null;

        setStats({
            yearVolume: calculateTotalAmount(currentYearTrades),
            totalTransactions: processedTrades.length,
            lastTransaction: lastTransaction,
            buySellRatio: ratio,
            mainSectors: extractMainSectors(processedTrades),
            topIndustries: extractTopIndustries(processedTrades),
            currentYear
        });
    };

    const extractMainSectors = (trades) => {
        return ['Technology', 'Financial Services', 'Communication Services'];
    };

    const extractTopIndustries = (trades) => {
        return ['Financial - Credit Services', 'Semiconductors', 'Entertainment'];
    };

    useEffect(() => {
        if (politician) {
            fetchPoliticianData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [politician]);

    const columns = [
        {
            field: 'symbol',
            headerName: 'Symbol',
            flex: 0.5,
            minWidth: 250,
            renderCell: (params) => (
                <Box>
                    <RouterLink
                        to={`/asset/${params.value}`}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold'
                        }}
                    >
                        {params.value}
                    </RouterLink>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            fontWeight: 500
                        }}
                    >
                        {params.row.name}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'type',
            headerName: 'Transaction Type',
            flex: 0.7,
            minWidth: 100,
            renderCell: (params) => (
                <Typography
                    sx={{
                        color: params.value.toLowerCase() === 'purchase' ||
                            params.value.toLowerCase() === 'buy' ?
                            theme.palette.positive.main :
                            theme.palette.negative.main
                    }}
                >
                    {params.value.toLowerCase() === 'purchase' ? 'Bought' :
                        params.value.toLowerCase() === 'sale' ? 'Sold' :
                            params.value}
                </Typography>
            )
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 200,
            flex: 0.7
        },
        {
            field: 'transactionDate',
            headerName: 'Transaction Date',
            flex: 0.8,
            minWidth: 100,
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            }
        },
        {
            field: 'filedDate',
            headerName: 'Filed',
            flex: 0.8,
            minWidth: 100,
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            }
        }
    ];

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error">
                {error}
            </Alert>
        );
    }

    // Get bioguideId only when politicianData is available
    const bioguideId = politicianData ? findBioguideId(politicianData.name) : null;

    return (
        <Container maxWidth="xl">
            {politicianData && (
                <Box sx={{ width: '100%' }}>
                    {/* Header Section */}
                    <Box sx={{
                        mb: 4,
                        mt: 4,
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        justifyContent: { xs: 'center', md: 'space-between' },
                        width: '100%',
                        pb: 3,
                        borderBottom: 1,
                        borderColor: 'divider',
                        gap: { xs: 3, md: 0 }
                    }}>
                        {/* Left side - Avatar and Info */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            textAlign: { xs: 'center', md: 'left' },
                            gap: 2
                        }}>
                            <ProfileImage
                                name={politicianData.name}
                                imageUrls={[
                                    bioguideId
                                        ? `/images/congress/${bioguideId}.jpg`
                                        : null
                                ]}
                                size={80}
                                sx={{
                                    mb: 1,
                                }}
                            />
                            <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mb: 1,
                                        fontSize: { xs: '1.75rem', md: '2rem' }
                                    }}
                                >
                                    {politicianData.name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: '0.875rem', md: '1rem' }
                                    }}
                                >
                                    {politicianData.party} / {politicianData.chamber} / {politicianData.state}
                                </Typography>
                            </Box>
                        </Box>

                        {/* Right side - Stats */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            gap: { xs: 2, md: 4 },
                            width: { xs: '100%', md: 'auto' },
                            textAlign: 'center'
                        }}>
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: { xs: '1.5rem', md: '1.5rem' }
                                    }}
                                >
                                    ${stats.yearVolume.toLocaleString()}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: '0.875rem', md: '0.875rem' }
                                    }}
                                >
                                    {stats.currentYear} Volume
                                </Typography>
                            </Box>
                            {/* <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: { xs: '1.5rem', md: '1.5rem' }
                                    }}
                                >
                                    {stats.totalTransactions}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: '0.875rem', md: '0.875rem' }
                                    }}
                                >
                                    Transaction
                                </Typography>
                            </Box>
                            */}
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: { xs: '1.5rem', md: '1.5rem' }
                                    }}
                                >
                                    {stats.lastTransaction?.toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric'
                                    })}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: '0.875rem', md: '0.875rem' }
                                    }}
                                >
                                    Last Transaction
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: { xs: '1.5rem', md: '1.5rem' }
                                    }}
                                >
                                    {stats.buySellRatio}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: '0.875rem', md: '0.875rem' }
                                    }}
                                >
                                    Buy/Sell
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Main content */}
                    {/*
                    <Box sx={{ width: '100%' }}>
                        {/* Sectors and Industries */}
                    <Box sx={{ mb: 4 }}>
                        {/*
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                                        Main Sectors:
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                        {stats.mainSectors.map((sector) => (
                                            <Chip
                                                key={sector}
                                                label={sector}
                                                sx={{
                                                    bgcolor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100'
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                                        Top Industries:
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                        {stats.topIndustries.map((industry) => (
                                            <Chip
                                                key={industry}
                                                label={industry}
                                                sx={{
                                                    bgcolor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100'
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        ^/}
                        {/* Trades Table */}
                        <Box >
                            <DataGrid
                                rows={trades}
                                columns={columns}
                                disableColumnMenu
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 20 } },
                                    sorting: { sortModel: [{ field: 'transactionDate', sort: 'desc' }] },
                                }}
                                pageSizeOptions={[5, 10, 25]}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default Politician;