import React, { useState, useMemo } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Select,
    MenuItem,
    FormControl,
    useTheme,
    CircularProgress,
    useMediaQuery,
    Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled circular progress with direct color values
const StyledCircularProgress = styled(CircularProgress)(({ value, colortype }) => ({
    color: colortype === 'buy' ? '#0eb35b' :
        colortype === 'sell' ? '#e62333' :
            '#1976d2',
    '& .MuiCircularProgress-circle': {
        strokeLinecap: 'round',
    }
}));

// Card wrapper component
const StatsCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ?
        'rgba(19, 47, 76, 0.4)' :
        'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    border: theme.palette.mode === 'dark' ?
        '1px solid rgba(255, 255, 255, 0.1)' :
        '1px solid rgba(0, 0, 0, 0.1)',
    transition: 'all 0.2s ease-in-out',
    padding: theme.spacing(1), // Reduced padding
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: theme.shadows[4],
    },
}));

const InsiderTradingStatistics = ({ data }) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedQuarter, setSelectedQuarter] = useState(null);

    // Initialize selectors with most recent data
    useMemo(() => {
        if (data && data.length > 0) {
            const sortedData = [...data].sort((a, b) => {
                if (a.year !== b.year) return b.year - a.year;
                return b.quarter - a.quarter;
            });

            const latestEntry = sortedData[0];
            setSelectedYear(latestEntry.year);
            setSelectedQuarter(latestEntry.quarter);
        }
    }, [data]);

    useMemo(() => {
        if (data && selectedYear && selectedQuarter) {
            // Check if the currently selected quarter exists for the selected year
            const availableQuarters = [...new Set(data
                .filter(item => item.year === selectedYear)
                .map(item => item.quarter))];
            
            // If the selected quarter is not available for this year
            if (!availableQuarters.includes(selectedQuarter)) {
                // Select the first available quarter instead
                if (availableQuarters.length > 0) {
                    setSelectedQuarter(availableQuarters[0]);
                } else {
                    setSelectedQuarter(null);
                }
            }
        }
    }, [data, selectedYear, selectedQuarter]);

    // Extract unique years and quarters for select options
    const years = useMemo(() => {
        if (!data) return [];
        return [...new Set(data.map(item => item.year))].sort((a, b) => b - a);
    }, [data]);

    const quarters = useMemo(() => {
        if (!data) return [];
        return [...new Set(data.filter(item => item.year === selectedYear)
            .map(item => item.quarter))]
            .sort((a, b) => b - a);
    }, [data, selectedYear]);

    // Get the current selected period's data
    const currentPeriodData = useMemo(() => {
        if (!data || !selectedYear || !selectedQuarter) return null;
        return data.find(item => item.year === selectedYear && item.quarter === selectedQuarter);
    }, [data, selectedYear, selectedQuarter]);

    if (!data || data.length === 0) {
        return (
            <Box p={2}>
                <Typography variant="body1">No insider trading data available</Typography>
            </Box>
        );
    }

    // Quarter display formatting
    const formatQuarter = (q) => `Q${q}`;

    return (
        <Box mt={4}>
            <Box mb={1.5} display="flex" flexDirection={isXs ? "column" : "row"} alignItems={isXs ? "flex-start" : "center"} justifyContent="space-between" gap={1.5}>
                <Typography variant={isXs ? "h5" : "h4"} component="h1">
                    Insider Trading
                </Typography>

                <Box display="flex" gap={1.5} flexWrap="wrap">
                    <FormControl size="small" variant="outlined" sx={{ minWidth: isXs ? "100%" : 100 }}>
                        <Select
                            value={selectedYear || ''}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            displayEmpty
                        >
                            {years.map(year => (
                                <MenuItem key={year} value={year}>
                                    Year: {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl size="small" variant="outlined" sx={{ minWidth: isXs ? "100%" : 100 }}>
                        <Select
                            value={selectedQuarter || ''}
                            onChange={(e) => setSelectedQuarter(e.target.value)}
                            displayEmpty
                        >
                            {quarters.map(quarter => (
                                <MenuItem key={quarter} value={quarter}>
                                    Quarter: {formatQuarter(quarter)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Typography variant="h6" mb={1.5}>
                {formatQuarter(selectedQuarter)} {selectedYear} Insider Statistics
            </Typography>

            {currentPeriodData ? (
                <Box display="flex" justifyContent="center" width="100%">
                    <Grid container spacing={0.5} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={4} md={3} lg={2.5}>
                            <StatsCard sx={{ width: '100%', height: "auto", minHeight: "90px" }}>
                                <CardContent sx={{ p: '0.5rem !important', '&:last-child': { pb: '0.5rem !important' } }}>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                                        <Box>
                                            <Typography variant="caption" component="div" fontWeight="500">
                                                Buy/Sell Ratio
                                            </Typography>
                                            <Typography variant="h5" component="div" fontWeight="bold" sx={{ lineHeight: 1.2 }}>
                                                {currentPeriodData.buyToSellRatio.toFixed(2)}
                                            </Typography>
                                        </Box>
                                        <Box position="relative" display="inline-flex">
                                            <StyledCircularProgress
                                                variant="determinate"
                                                // Convert the ratio to a percentage with a maximum of 100%
                                                value={Math.min(currentPeriodData.buyToSellRatio / (currentPeriodData.buyToSellRatio + 1) * 100, 100)}
                                                size={35}
                                                thickness={3}
                                                // Color based on whether ratio is > 1 (more buying) or < 1 (more selling)
                                                colortype={currentPeriodData.buyToSellRatio >= 1 ? "buy" : "sell"}
                                            />
                                            <Box
                                                top={0}
                                                left={0}
                                                bottom={0}
                                                right={0}
                                                position="absolute"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    fontWeight="bold"
                                                    color={currentPeriodData.buyToSellRatio >= 1 ? "#0eb35b" : "#e62333"}
                                                >
                                                    {currentPeriodData.buyToSellRatio >= 1 ? "B>S" : "S>B"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </StatsCard>
                        </Grid>

                        {/* Repeat similar structure for other two cards */}
                        <Grid item xs={12} sm={4} md={3} lg={2.5}>
                            <StatsCard sx={{ width: '100%', height: "auto", minHeight: "90px" }}>
                                <CardContent sx={{ p: '0.5rem !important', '&:last-child': { pb: '0.5rem !important' } }}>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                                        <Box>
                                            <Typography variant="caption" component="div" fontWeight="500">
                                                Bought Shares
                                            </Typography>
                                            <Typography variant="h5" component="div" fontWeight="bold" sx={{ lineHeight: 1.2 }}>
                                                {currentPeriodData.boughtShares || 0}
                                            </Typography>
                                        </Box>
                                        <Box position="relative" display="inline-flex">
                                            <StyledCircularProgress
                                                variant="determinate"
                                                value={currentPeriodData.boughtSharesPercentage || 0}
                                                size={35}
                                                thickness={3}
                                                colortype="buy"
                                            />
                                            <Box
                                                top={0}
                                                left={0}
                                                bottom={0}
                                                right={0}
                                                position="absolute"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    fontWeight="bold"
                                                    color="#0eb35b"
                                                >
                                                    {`${currentPeriodData.boughtSharesPercentage || 0}%`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </StatsCard>
                        </Grid>

                        <Grid item xs={12} sm={4} md={3} lg={2.5}>
                            <StatsCard sx={{ width: '100%', height: "auto", minHeight: "90px" }}>
                                <CardContent sx={{ p: '0.5rem !important', '&:last-child': { pb: '0.5rem !important' } }}>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                                        <Box>
                                            <Typography variant="caption" component="div" fontWeight="500">
                                                Sold Shares
                                            </Typography>
                                            <Typography variant="h5" component="div" fontWeight="bold" sx={{ lineHeight: 1.2 }}>
                                                {currentPeriodData.soldShares || 0}
                                            </Typography>
                                        </Box>
                                        <Box position="relative" display="inline-flex">
                                            <StyledCircularProgress
                                                variant="determinate"
                                                value={currentPeriodData.soldSharesPercentage || 0}
                                                size={35}
                                                thickness={3}
                                                colortype="sell"
                                            />
                                            <Box
                                                top={0}
                                                left={0}
                                                bottom={0}
                                                right={0}
                                                position="absolute"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    fontWeight="bold"
                                                    color="#e62333"
                                                >
                                                    {`${currentPeriodData.soldSharesPercentage || 0}%`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </StatsCard>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box p={2} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default InsiderTradingStatistics;