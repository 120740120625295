import React from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
} from '@mui/material';
import {
    Info as InfoIcon,
} from '@mui/icons-material';

const DividendComparison = ({
    symbols,
    dividendData = [],
    loading = false,
    error = null,
}) => {
    const theme = useTheme();

    const metricDefinitions = {
        amount: "Annual dividend amount in dollars.",
        yield: "Annual dividend yield as a percentage of share price.",
        exDivDate: "Most recent ex-dividend date. Shareholders must own the stock before this date to receive the dividend.",
        frequency: "How often dividends are paid (e.g., Quarterly, Semi-Annual, Annual).",
        growth: "Year-over-year percentage growth of the dividend amount.",
    };

    // Define display rows for the table
    const displayRows = [
        { id: 'exDivDate', label: 'Ex-Div Date', hasTooltip: true },
        { id: 'amount', label: 'Annual Dividend', hasTooltip: true, format: value => value ? `$${value.toFixed(2)}` : 'N/A' },
        {
            id: 'yield', label: 'Dividend Yield', hasTooltip: true, format: value => {
                if (value === undefined || value === null) return 'N/A';
                return `${value.toFixed(2)}%`;
            }
        },
        { id: 'frequency', label: 'Payout Frequency', hasTooltip: true },
        {
            id: 'growth', label: '1Y Dividend Growth', hasTooltip: true, format: value => {
                if (value === undefined || value === null) return 'N/A';
                const isPositive = value > 0;
                return (
                    <span style={{ color: isPositive ? theme.palette.success.main : theme.palette.error.main }}>
                        {isPositive ? '+' : ''}{value.toFixed(2)}%
                    </span>
                );
            }
        },
    ];

    if (!symbols || symbols.length === 0) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 3
            }}>
                <Typography variant="body1" color="text.secondary">
                    Select at least one symbol to view dividend data.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            {loading && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <CircularProgress />
                </Box>
            )}

            {error && !loading && (
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            )}

            {!loading && !error && dividendData.length > 0 && (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: theme.palette.background.paper, fontWeight: 'bold' }}>
                                    Metric
                                </TableCell>
                                {symbols.map(symbol => {
                                    return (
                                        <TableCell
                                            key={symbol}
                                            align="center"
                                            sx={{
                                                backgroundColor: theme.palette.background.paper,
                                                fontWeight: 'bold',
                                                minWidth: '150px'
                                            }}
                                        >
                                            {symbol}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayRows.map(row => (
                                <TableRow key={row.id} hover>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            position: 'sticky',
                                            left: 0,
                                            fontWeight: 'medium',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5
                                        }}
                                    >
                                        {row.label}
                                        {row.hasTooltip && (
                                            <Tooltip title={metricDefinitions[row.id] || ''} enterTouchDelay={0} leaveTouchDelay={1500}>
                                                <InfoIcon fontSize="small" color="action" sx={{ opacity: 0.6 }} />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    {symbols.map(symbol => {
                                        const dividend = dividendData.find(d => d.symbol === symbol);
                                        const value = dividend && dividend.dividend ? dividend.dividend[row.id] : undefined;
                                        return (
                                            <TableCell key={`${symbol}-${row.id}`} align="center">
                                                {row.format ? row.format(value) : (value || 'N/A')}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}

            {!loading && !error && dividendData.length === 0 && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <Typography variant="body1" color="text.secondary">
                        No dividend data available for the selected symbols.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default DividendComparison;