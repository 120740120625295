// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, AppBar, Tabs, Tab, Box } from '@mui/material';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';
import axios from '../config/axiosConfig';
import SectorGrid from './SectorGrid';
import StockNews from './StockNews';
import StockDashboard from './StockDashboard';
import IndexDashboard from './IndexDashboard';
import CryptoDashboard from './CryptoDashboard';
import PromotionBanner from './PromotionBanner';
import MarketIndicesOverview from './MarketIndicesOverview';

const Dashboard = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);

  // Centralized states for all data
  const [stocksData, setStocksData] = useState([]);
  const [indexesData, setIndexesData] = useState([]);
  const [cryptosData, setCryptosData] = useState([]);

  // Loading states
  const [loadingStocks, setLoadingStocks] = useState(true);
  const [loadingIndexes, setLoadingIndexes] = useState(true);
  const [loadingCryptos, setLoadingCryptos] = useState(true);

  // Error states
  const [errors, setErrors] = useState({
    stocks: null,
    indexes: null,
    cryptos: null
  });

  // Fetch stocks data
  const fetchStocksData = async () => {
    try {
      setLoadingStocks(true);
      const response = await axios.get('/api/usquotes');
      if (response.data && response.data.data) {
        setStocksData(response.data.data);
      } else {
        setErrors(prev => ({ ...prev, stocks: 'Invalid response data' }));
      }
    } catch (error) {
      console.error('Error fetching stocks data:', error);
      setErrors(prev => ({ ...prev, stocks: error.message }));
    } finally {
      setLoadingStocks(false);
    }
  };

  // Fetch indexes data
  const fetchIndexesData = async () => {
    try {
      setLoadingIndexes(true);
      const response = await axios.get('/api/indexes');
      if (response.data && response.data.data) {
        setIndexesData(response.data.data);
      } else {
        setErrors(prev => ({ ...prev, indexes: 'Invalid response data' }));
      }
    } catch (error) {
      console.error('Error fetching indexes data:', error);
      setErrors(prev => ({ ...prev, indexes: error.message }));
    } finally {
      setLoadingIndexes(false);
    }
  };

  // Fetch cryptos data
  const fetchCryptosData = async () => {
    try {
      setLoadingCryptos(true);
      const response = await axios.get('/api/cryptos');
      if (response.data && response.data.data) {
        setCryptosData(response.data.data);
      } else {
        setErrors(prev => ({ ...prev, cryptos: 'Invalid response data' }));
      }
    } catch (error) {
      console.error('Error fetching cryptos data:', error);
      setErrors(prev => ({ ...prev, cryptos: error.message }));
    } finally {
      setLoadingCryptos(false);
    }
  };

  // Set up data fetching
  useEffect(() => {
    // Initial fetch
    fetchStocksData();
    fetchIndexesData();
    fetchCryptosData();

    // // Set up intervals for periodic updates
    // const stocksInterval = setInterval(fetchStocksData, 60000);
    // const indexesInterval = setInterval(fetchIndexesData, 60000);
    // const cryptosInterval = setInterval(fetchCryptosData, 60000);

    // // Cleanup intervals
    // return () => {
    //   clearInterval(stocksInterval);
    //   clearInterval(indexesInterval);
    //   clearInterval(cryptosInterval);
    // };
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="table-container">
      <PromotionBanner />
      <Container maxWidth="xl">
        {/* SectorGrid component */}
        <SectorGrid />

        {/* Daily Overview and StockNews */}
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid
            item
            xs={12}
            md={5}
            container
            direction="column"
            justifyContent="center"
            sx={{ height: '150px' }}
          >
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 800,
                fontSize: { xs: '2rem', md: '2.5rem' },
                background: theme.palette.mode === 'dark'
                  ? 'linear-gradient(to right, #fff 0%, #c7c7c7 100%)'
                  : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Daily Overview
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
              <StockNews />
            </div>
          </Grid>
        </Grid>

        <Container maxWidth="lg" sx={{ mt: 2 }}>
          <MarketIndicesOverview />
        </Container>

        {/* App Bar with Tabs */}
        <AppBar position="static" sx={{
          mt: 4,
          "& .MuiTab-root": {
            color: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.7)'  // For dark mode
              : theme.palette.secondary.dark,  // For light mode using our new color
          },
          "& .Mui-selected": {
            color: theme.palette.mode === 'dark'
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.mode === 'dark'
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          },
        }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab
              label="US Stocks"
              sx={{
                "&.Mui-selected": {
                  color: theme.palette.mode === 'dark'
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                },
              }}
            />
            <Tab
              label="Index"
              sx={{
                "&.Mui-selected": {
                  color: theme.palette.mode === 'dark'
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                },
              }}
            />
            <Tab
              label="Crypto"
              sx={{
                "&.Mui-selected": {
                  color: theme.palette.mode === 'dark'
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                },
              }}
            />
          </Tabs>
        </AppBar>

        {/* Tab Content */}
        <Box sx={{ mt: 4 }}>
          {activeTab === 0 && (
            <div>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <StockDashboard
                    user={user}
                    data={stocksData}
                    loading={loadingStocks}
                    error={errors.stocks}
                    onRefresh={fetchStocksData}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          {activeTab === 1 && (
            <div>
              <IndexDashboard
                user={user}
                data={indexesData}
                loading={loadingIndexes}
                error={errors.indexes}
                onRefresh={fetchIndexesData}
              />
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <CryptoDashboard
                user={user}
                data={cryptosData}
                loading={loadingCryptos}
                error={errors.cryptos}
                onRefresh={fetchCryptosData}
              />
            </div>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default Dashboard;