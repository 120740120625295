import React, { useState, useEffect } from 'react';
import TitleAndInfo from './TitleAndInfo';
import {
    Typography,
    Box,
    Container,
    IconButton,
    Paper,
    useTheme,
    CircularProgress,
    Chip,
    useMediaQuery
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    TrendingUp as TrendingUpIcon,
    TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';
import axios from '../config/axiosConfig';

const EconomicCalendar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [weekDates, setWeekDates] = useState([]);
    const [economicData, setEconomicData] = useState({});
    const [loading, setLoading] = useState(true);
    const [latestDate, setLatestDate] = useState(null);
    const [earliestDate, setEarliestDate] = useState(null);

    const titleTop = "Economic Calendar: "
    const title = "";
    const info = "";

    const formatDate = (date) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`;
    };

    const formatMobileDate = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`;
    };

    const getNextValidDate = (date, direction) => {
        const newDate = new Date(date);
        do {
            newDate.setDate(newDate.getDate() + direction);
        } while (newDate.getDay() === 0 || newDate.getDay() === 6);
        return newDate;
    };

    const getWeekDates = (date) => {
        const dates = [];
        const currentDate = new Date(date);

        while (currentDate.getDay() !== 1) {
            currentDate.setDate(currentDate.getDate() - 1);
        }

        for (let i = 0; i < 5; i++) {
            const newDate = new Date(currentDate);
            dates.push({
                date: newDate,
                formatted: formatDate(newDate),
                fullDate: newDate.toISOString().split('T')[0]
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const getStartOfWeek = (date) => {
        const d = new Date(date);
        while (d.getDay() !== 1) {
            d.setDate(d.getDate() - 1);
        }
        d.setDate(d.getDate() - 7);
        return d.toISOString().split('T')[0];
    };

    const getEndOfMonth = (date) => {
        const d = new Date(date);
        d.setMonth(d.getMonth() + 1);
        d.setDate(0);
        return d.toISOString().split('T')[0];
    };

    useEffect(() => {
        setWeekDates(getWeekDates(selectedDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const startDate = getStartOfWeek(new Date());
                const endDate = getEndOfMonth(new Date());

                const response = await axios.get(`/api/economic-calendar?from=${startDate}&to=${endDate}`);

                const organizedData = {};
                let latest = null;
                let earliest = null;

                response.data.data.forEach(item => {
                    const date = new Date(item.date).toISOString().split('T')[0];
                    if (!latest || date > latest) latest = date;
                    if (!earliest || date < earliest) earliest = date;

                    if (!organizedData[date]) {
                        organizedData[date] = [];
                    }
                    organizedData[date].push({
                        ...item,
                        id: item._id
                    });
                });

                setLatestDate(latest);
                setEarliestDate(earliest);
                setEconomicData(organizedData);
            } catch (error) {
                console.error('Error fetching economic data:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const handleDateNavigation = (direction) => {
        const newDate = isMobile 
            ? getNextValidDate(selectedDate, direction)
            : new Date(selectedDate.setDate(selectedDate.getDate() + (direction * 7)));

        if (latestDate && newDate > new Date(latestDate)) return;
        if (earliestDate && newDate < new Date(earliestDate)) return;

        setSelectedDate(newDate);
    };

    const formatTime = (dateString) => {
        return new Date(dateString).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const getImpactColor = (impact) => {
        switch (impact) {
            case 'High':
                return theme.palette.error.main;
            case 'Medium':
                return theme.palette.warning.main;
            case 'Low':
                return theme.palette.success.main;
            default:
                return theme.palette.text.secondary;
        }
    };

    const formatValue = (value, unit) => {
        if (value === null) return '-';
        return `${value}${unit ? ' ' + unit : ''}`;
    };

    const isNextDisabled = latestDate && new Date(selectedDate) >= new Date(latestDate);
    const isPrevDisabled = earliestDate && new Date(selectedDate) <= new Date(earliestDate);

    const columns = [
        {
            field: 'time',
            headerName: 'Time',
            minWidth: 120,
            flex: 1,
            valueGetter: (params) => formatTime(params.row.date)
        },
        {
            field: 'impact',
            headerName: 'Impact',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    size="small"
                    sx={{
                        backgroundColor: getImpactColor(params.value),
                        color: 'white'
                    }}
                />
            )
        },
        {
            field: 'event',
            headerName: 'Event',
            flex: 1,
            minWidth: 120
        },
        {
            field: 'country',
            headerName: 'Country',
            minWidth: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography>{params.value}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {params.row.currency}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'actual',
            headerName: 'Actual',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Typography>
                        {formatValue(params.value, params.row.unit)}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'previous',
            headerName: 'Previous',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Typography>
                    {formatValue(params.value, params.row.unit)}
                </Typography>
            )
        },
        {
            field: 'estimate',
            headerName: 'Estimate',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Typography>
                    {formatValue(params.value, params.row.unit)}
                </Typography>
            )
        },
        {
            field: 'change',
            headerName: 'Change',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => {
                if (!params.value && params.value !== 0) return '-';
                const isPositive = params.value > 0;
                return (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: isPositive ? theme.palette.success.main : theme.palette.error.main
                    }}>
                        {isPositive ? <TrendingUpIcon /> : <TrendingDownIcon />}
                        <Typography sx={{ ml: 1 }}>
                            {isPositive ? '+' : ''}{params.value}
                            {params.row.changePercentage ? ` (${params.row.changePercentage}%)` : ''}
                        </Typography>
                    </Box>
                );
            }
        }
    ];

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    const renderDateNavigation = () => {
        if (isMobile) {
            const currentDayData = economicData[selectedDate.toISOString().split('T')[0]] || [];
            return (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <IconButton 
                        onClick={() => handleDateNavigation(-1)}
                        disabled={isPrevDisabled}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                            {formatMobileDate(selectedDate)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {currentDayData.length} Events
                        </Typography>
                    </Box>
                    <IconButton 
                        onClick={() => handleDateNavigation(1)}
                        disabled={isNextDisabled}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
            );
        }

        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'stretch',
                borderBottom: 1,
                borderColor: 'divider'
            }}>
                <IconButton
                    onClick={() => handleDateNavigation(-1)}
                    disabled={isPrevDisabled}
                    sx={{
                        borderRadius: 0,
                        px: 3,
                        '&:hover': { backgroundColor: 'action.hover' },
                        opacity: isPrevDisabled ? 0.5 : 1
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>

                {weekDates.map((day) => {
                    const dayData = economicData[day.fullDate] || [];
                    const isSelected = selectedDate.toISOString().split('T')[0] === day.fullDate;

                    return (
                        <Box
                            key={day.fullDate}
                            onClick={() => setSelectedDate(day.date)}
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 2,
                                cursor: 'pointer',
                                backgroundColor: isSelected ? 'primary.main' : 'transparent',
                                '&:hover': {
                                    backgroundColor: isSelected ? 'primary.dark' : 'action.hover'
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    color: isSelected ? 'primary.contrastText' : 'text.primary',
                                    fontWeight: 'medium'
                                }}
                            >
                                {day.formatted}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: isSelected ? 'primary.contrastText' : 'text.secondary',
                                    mt: 0.5
                                }}
                            >
                                {dayData.length} Events
                            </Typography>
                        </Box>
                    );
                })}

                <IconButton
                    onClick={() => handleDateNavigation(1)}
                    disabled={isNextDisabled}
                    sx={{
                        borderRadius: 0,
                        px: 3,
                        '&:hover': { backgroundColor: 'action.hover' },
                        opacity: isNextDisabled ? 0.5 : 1
                    }}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <Container maxWidth="xl">
            <TitleAndInfo titleTop={titleTop} title={title} info={info} />
            <Paper elevation={3} variant='stats' sx={{ overflow: 'hidden' }}>
                {renderDateNavigation()}
            </Paper>

            <Box sx={{ height: 650, width: '100%' }}>
                <DataGrid
                    rows={economicData[selectedDate.toISOString().split('T')[0]] || []}
                    columns={columns}
                    disableColumnMenu
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: { sortModel: [{ field: 'change', sort: 'desc' }] },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                />
            </Box>
        </Container>
    );
};

export default EconomicCalendar;