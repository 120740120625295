import { createTheme } from '@mui/material/styles';

// Your original stockPricePalette
const stockPricePalette = {
  positive: {
    main: '#0eb35b',
  },
  negative: {
    main: '#e62333',
  },
  lightGray: {
    main: '#d3d3d3',
  },
};

// Common components without the CssBaseline
const commonComponents = {
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundImage: 'linear-gradient(145deg, rgba(19, 47, 76, 0.4), rgba(19, 47, 76, 0.1))',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: 8,
        fontWeight: 600,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-1px)',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: 'inherit',
        textDecoration: 'none',
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: '#007FFF',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
        backgroundColor: 'transparent',
        backdropFilter: 'blur(10px)',
      },
    },
    variants: [
      {
        props: { variant: 'stats' },
        style: ({ theme }) => ({  // Use a function that receives theme
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(19, 47, 76, 0.4)'
            : 'rgba(255, 255, 255, 0.8)',
          borderRadius: '8px',
          border: theme.palette.mode === 'dark'
            ? '1px solid rgba(255, 255, 255, 0.1)'
            : '1px solid rgba(0, 0, 0, 0.1)',
        })
      }
    ]
  },
  MuiModal: {
    styleOverrides: {
      root: {
        '& .MuiBox-root': {
          backgroundColor: ({ theme }) =>
            theme.palette.mode === 'dark'
              ? 'rgba(13, 34, 55, 0.4)'
              : 'rgba(255, 255, 255, 0.7)',
          backdropFilter: 'blur(8px)',
        }
      },
      backdrop: {
        backgroundColor: 'transparent'
      }
    }
  },
};

const commonTypography = {
  fontFamily: 'Inter, Roboto, Arial, sans-serif',
  h1: {
    fontWeight: 700,
    letterSpacing: '-0.025em',
  },
  h2: {
    fontWeight: 700,
    letterSpacing: '-0.025em',
  },
  h3: {
    fontWeight: 600,
    letterSpacing: '-0.025em',
  },
  h4: {
    fontWeight: 600,
    letterSpacing: '-0.025em',
  },
  h5: {
    fontWeight: 500,
    letterSpacing: '-0.025em',
  },
  h6: {
    fontWeight: 500,
    letterSpacing: '-0.025em',
  },
  button: {
    fontWeight: 600,
    textTransform: 'none',
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
};

// Light theme
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#007FFF',
      light: '#66B2FF',
      dark: '#0059B2',
    },
    secondary: {
      main: 'hsl(220, 35%, 97%)',    // Your cool white
      light: 'hsl(220, 30%, 99%)',   // Lighter, almost pure white
      dark: 'hsl(220, 35%, 85%)',    // Soft gray with blue undertone
    },
    third: {
      main: '#757575',
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#f5f7fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#1A2027',
      secondary: '#3E5060',
    },
    ...stockPricePalette,
  },
  typography: commonTypography,
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'hsl(220, 35%, 97%)', // gray[50] from your primitives
          minHeight: '100vh',
          backgroundImage: 'radial-gradient(50% 50% at 50% 0%, hsl(220, 30%, 94%) 0%, hsl(220, 35%, 97%) 100%)', // using gray[100] to gray[50]
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#007FFF', // Your brand blue
          boxShadow: 'none',
        },
      },
    },
    ...commonComponents,
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
        },
      },
    },
  },
  hero: {
    color: '#1A2027',
    textAlign: 'center',
  },
  ctaButton: {
    marginTop: 4,
  },
});

// Dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#007FFF',
      light: '#66B2FF',
      dark: '#0059B2',
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
    },
    third: {
      main: '#757575',
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#0A1929',
      paper: 'rgba(19, 47, 76, 0.4)',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
    ...stockPricePalette,
  },
  typography: commonTypography,
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  components: {
    ...commonComponents,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#0A1929',
          minHeight: '100vh',
          backgroundImage: 'radial-gradient(50% 50% at 50% 0%, #132f4c 0%, #0A1929 100%)',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(19, 47, 76, 0.4)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 4px 24px rgba(0,0,0,0.2)',
        },
      },
    },
  },
  hero: {
    color: '#ffffff',
    textAlign: 'center',
  },
  ctaButton: {
    marginTop: 4,
  },
});

export { lightTheme, darkTheme };