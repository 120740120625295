import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Alert,
    CircularProgress,
    useTheme
} from '@mui/material';
import {
    Info as InfoIcon,
} from '@mui/icons-material';

// Helper function to format numbers
const formatNumber = (num, decimals = 2) => {
    if (num === null || num === undefined) return '-';
    if (num === 0) return '0';

    // Convert to number if it's a string
    const numValue = typeof num === 'string' ? parseFloat(num) : num;

    const absNum = Math.abs(numValue);
    const sign = numValue < 0 ? '-' : '';

    if (absNum >= 1000000000000) {
        return `${sign}${(absNum / 1000000000000).toFixed(decimals)}T`;
    } else if (absNum >= 1000000000) {
        return `${sign}${(absNum / 1000000000).toFixed(decimals)}B`;
    } else if (absNum >= 1000000) {
        return `${sign}${(absNum / 1000000).toFixed(decimals)}M`;
    } else if (absNum >= 1000) {
        return `${sign}${(absNum / 1000).toFixed(decimals)}K`;
    } else {
        return `${sign}${absNum.toFixed(decimals)}`;
    }
};

// Format date helper
const formatDate = (dateString) => {
    if (!dateString) return '-';
    try {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    } catch (error) {
        return '-';
    }
};

// Financial metrics definitions
const metricDefinitions = {
    revenue: "Total sales or income from operations",
    costOfRevenue: "Direct costs attributable to the production of goods or services",
    grossProfit: "Revenue minus cost of revenue",
    grossProfitRatio: "Gross profit as a percentage of revenue",
    operatingExpenses: "Expenses related to business operations",
    operatingIncome: "Profit from operations before interest and taxes",
    operatingIncomeRatio: "Operating income as a percentage of revenue",
    ebitda: "Earnings Before Interest, Taxes, Depreciation, and Amortization",
    ebitdaratio: "EBITDA as a percentage of revenue",
    netIncome: "Profit after all expenses have been deducted",
    netIncomeRatio: "Net income as a percentage of revenue",
    eps: "Earnings Per Share - net income divided by outstanding shares",
    epsdiluted: "Diluted Earnings Per Share - accounts for potential dilution",
    researchAndDevelopmentExpenses: "Expenses for research and development activities",
    generalAndAdministrativeExpenses: "Expenses for general and administrative functions",
    sellingAndMarketingExpenses: "Expenses for selling and marketing activities",
    interestExpense: "Cost of borrowed funds",
    interestIncome: "Income from interest-bearing assets",
    incomeTaxExpense: "Amount paid in taxes on income",
    sellingGeneralAndAdministrativeExpenses: 'Selling, General and Administrative Expenses'
};

// Define metrics to display in the order they should appear
const incomeStatementMetrics = [
    // Overview Section
    { id: 'section1', label: 'Overview', isSection: true },
    { id: 'date', label: 'Fiscal Year End', format: (value) => formatDate(value) },
    { id: 'calendarYear', label: 'Calendar Year' },

    // Revenue & Gross Profit Section
    { id: 'section2', label: 'Revenue & Gross Profit', isSection: true },
    { id: 'revenue', label: 'Revenue', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'costOfRevenue', label: 'Cost of Revenue', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'grossProfit', label: 'Gross Profit', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'grossProfitRatio', label: 'Gross Margin', format: (value) => value ? `${(value * 100).toFixed(2)}%` : '-', hasTooltip: true },

    // Operating Expenses Section
    { id: 'section3', label: 'Operating Expenses', isSection: true },
    { id: 'researchAndDevelopmentExpenses', label: 'R&D Expenses', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'sellingGeneralAndAdministrativeExpenses', label: 'SG&A Expenses', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'operatingExpenses', label: 'Total Operating Expenses', format: (value) => formatNumber(value, 1), hasTooltip: true },

    // Profitability Section
    { id: 'section4', label: 'Operating Performance & Interest', isSection: true },
    { id: 'operatingIncome', label: 'Operating Income', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'operatingIncomeRatio', label: 'Operating Margin', format: (value) => value ? `${(value * 100).toFixed(2)}%` : '-', hasTooltip: true },
    { id: 'interestExpense', label: 'Interest Expense', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'interestIncome', label: 'Interest Income', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'ebitda', label: 'EBITDA', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'ebitdaratio', label: 'EBITDA Margin', format: (value) => value ? `${(value * 100).toFixed(2)}%` : '-', hasTooltip: true },

    // Income & Taxes Section
    { id: 'section5', label: 'Income & Taxes', isSection: true },
    { id: 'incomeBeforeTax', label: 'Income Before Tax', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'incomeTaxExpense', label: 'Income Tax Expense', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'netIncome', label: 'Net Income', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'netIncomeRatio', label: 'Net Margin', format: (value) => value ? `${(value * 100).toFixed(2)}%` : '-', hasTooltip: true },

    // Per Share Data Section
    { id: 'section6', label: 'Per Share Data', isSection: true },
    { id: 'eps', label: 'EPS', format: (value) => value ? `$${value.toFixed(2)}` : '-', hasTooltip: true },
    { id: 'epsdiluted', label: 'Diluted EPS', format: (value) => value ? `$${value.toFixed(2)}` : '-', hasTooltip: true },
];

const FinancialComparison = ({
    symbols,
    managedMode = true,
    financialData = {},
    loading = false,
    error = null,
    lastUpdated = null,
}) => {
    const theme = useTheme();

    if (!symbols || symbols.length === 0) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 3
            }}>
                <Typography variant="body1" color="text.secondary">
                    Select at least one symbol to view financial data.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>
                <Typography variant="subtitle1">Latest Annual Income Statement (TTM or Last FY)</Typography>
            </Box>

            {loading && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <CircularProgress />
                </Box>
            )}

            {error && !loading && (
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            )}

            {!loading && !error && Object.keys(financialData).length > 0 && (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        fontWeight: 'bold',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 3,
                                        minWidth: 180
                                    }}
                                >
                                    Metric
                                </TableCell>
                                {symbols.map(symbol => (
                                    <TableCell
                                        key={symbol}
                                        align="right"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            fontWeight: 'bold',
                                            minWidth: '150px'
                                        }}
                                    >
                                        {symbol}
                                        {financialData[symbol] && financialData[symbol].reportedCurrency && (
                                            <Typography variant="caption" display="block" color="text.secondary">
                                                {financialData[symbol].reportedCurrency}
                                            </Typography>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {incomeStatementMetrics.map((metric, index) => {
                                if (metric.isSection) {
                                    return (
                                        <TableRow key={metric.id}>
                                            <TableCell
                                                colSpan={symbols.length + 1}
                                                sx={{
                                                    backgroundColor: theme.palette.mode === 'dark' ?
                                                        'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
                                                    fontWeight: 'bold',
                                                    py: 1
                                                }}
                                            >
                                                {metric.label}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }

                                return (
                                    <TableRow key={metric.id} hover>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{
                                                backgroundColor: theme.palette.background.paper,
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 2,
                                                fontWeight: 'medium',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5
                                            }}
                                        >
                                            {metric.label}
                                            {metric.hasTooltip && (
                                                <Tooltip title={metricDefinitions[metric.id] || ''} arrow enterTouchDelay={0} leaveTouchDelay={1500}>
                                                    <InfoIcon fontSize="small" color="action" sx={{ opacity: 0.6, fontSize: 16 }} />
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        {symbols.map(symbol => {
                                            const statement = financialData[symbol];
                                            const value = statement ? statement[metric.id] : null;
                                            return (
                                                <TableCell key={`${symbol}-${metric.id}`} align="right">
                                                    {metric.format ? metric.format(value) : (value || '-')}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            )}

            {!loading && !error && Object.keys(financialData).length === 0 && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <Typography variant="body1" color="text.secondary">
                        No financial data available for the selected symbols.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default FinancialComparison;