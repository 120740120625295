import React from 'react';
import { Box, useTheme } from '@mui/material';
import LPMockup_dark from '../images/LPMockup_dark.png';
import LPMockup_light from '../images/LPMockup_light.png';


const StaticHeroImage = () => {
  const theme = useTheme(); // Get the current theme
  const isDarkMode = theme.palette.mode === 'dark'; // Check if the theme is dark

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'visible',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      }}
    >
      <Box
        component="img"
        src={isDarkMode ? LPMockup_dark : LPMockup_light} // Dynamically set image
        alt="Dashboard Preview"
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          objectPosition: 'center top',
          display: 'block',
        }}
      />
    </Box>
  );
};

export default StaticHeroImage;
