import React, { useState } from 'react';
import {
  Container,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Paper,
  Typography,
  Box,
  Grid
} from '@mui/material';
import { Calculate as CalculateIcon } from '@mui/icons-material';
import { fv } from 'financial';

const StockReturnCalculator = () => {
  const [startingAmount, setStartingAmount] = useState('');
  const [additionalContribution, setAdditionalContribution] = useState('');
  const [contributionFrequency, setContributionFrequency] = useState('Monthly');
  const [returnRate, setReturnRate] = useState('');
  const [yearsOfGrowth, setYearsOfGrowth] = useState('');
  const [totalReturn, setTotalReturn] = useState(null);
  const [growthData, setGrowthData] = useState([]);

  // Keeping your calculation function exactly as is
  const handleCalculate = () => {
    let periodsPerYear = 12;
    if (contributionFrequency === 'Yearly') {
      periodsPerYear = 1;
    } else if (contributionFrequency === 'Weekly') {
      periodsPerYear = 52;
    }
    const interestRatePerPeriod = returnRate / 100 / periodsPerYear;
    const totalPeriods = yearsOfGrowth * periodsPerYear;
    let currentAmount = parseFloat(startingAmount);
    let currentTotalInterestEarned = 0;
    const newData = [];
    for (let i = 0; i < yearsOfGrowth; i++) {
      const annualContribution = parseFloat(additionalContribution) * periodsPerYear;
      const totalContributions = annualContribution * (i + 1);
      let yearlyInterestEarned = 0;
      for (let j = 0; j < periodsPerYear; j++) {
        const interestEarned = currentAmount * interestRatePerPeriod;
        currentAmount += interestEarned + parseFloat(additionalContribution);
        currentTotalInterestEarned += interestEarned;
        yearlyInterestEarned += interestEarned;
      }
      newData.push({
        year: new Date().getFullYear() + i,
        startingAmount: i === 0 ? startingAmount : newData[i - 1].endBalance,
        annualContribution: annualContribution.toFixed(2),
        totalContributions: totalContributions.toFixed(2),
        interestEarned: yearlyInterestEarned.toFixed(2),
        totalInterestEarned: currentTotalInterestEarned.toFixed(2),
        endBalance: currentAmount.toFixed(2),
      });
    }
    setGrowthData(newData);
    const futureValue = fv(interestRatePerPeriod, totalPeriods, -parseFloat(additionalContribution), -startingAmount, 1);
    setTotalReturn(futureValue.toFixed(2));
  };

  return (
    <div className="table-container">
      <Container maxWidth="xl">
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <CalculateIcon sx={{ fontSize: 28, color: 'primary.main' }} />
                <Typography variant="h4" component="h1" sx={{ ml: 2, fontWeight: 'bold' }}>
                  Stock Return Calculator
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card elevation={2} sx={{ height: '100%', bgcolor: 'background.paper' }}>
                <CardContent sx={{ p: 4 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Starting Amount ($)"
                        type="number"
                        value={startingAmount}
                        onChange={(e) => setStartingAmount(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Additional Contribution ($)"
                        type="number"
                        value={additionalContribution}
                        onChange={(e) => setAdditionalContribution(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Contribution Frequency</InputLabel>
                        <Select
                          value={contributionFrequency}
                          onChange={(e) => setContributionFrequency(e.target.value)}
                          label="Contribution Frequency"
                        >
                          <MenuItem value="Weekly">Weekly</MenuItem>
                          <MenuItem value="Monthly">Monthly</MenuItem>
                          <MenuItem value="Yearly">Yearly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Return Rate (%)"
                        type="number"
                        value={returnRate}
                        onChange={(e) => setReturnRate(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Years of Growth"
                        type="number"
                        value={yearsOfGrowth}
                        onChange={(e) => setYearsOfGrowth(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCalculate}
                        fullWidth
                        size="large"
                        sx={{ mt: 2, height: 48, textTransform: 'none', fontWeight: 600 }}
                        startIcon={<CalculateIcon />}
                      >
                        Calculate Returns
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              {totalReturn && (
                <Card
                  elevation={2}
                  sx={{
                    height: '100%',
                    bgcolor: 'background.paper',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CardContent sx={{
                    p: 4,
                    textAlign: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}>
                    <Typography variant="h5" gutterBottom color="text.secondary">
                      Total Return
                    </Typography>
                    <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold' }}>
                      ${Number(totalReturn).toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Paper>

        {growthData.length > 0 && (
          <Paper elevation={3} variant='stats' sx={{ p: 4, overflowX: 'auto' }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
              Growth Breakdown
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell align="right">Starting Amount</TableCell>
                    <TableCell align="right">Annual Contribution</TableCell>
                    <TableCell align="right">Total Contributions</TableCell>
                    <TableCell align="right">Interest Earned</TableCell>
                    <TableCell align="right">Total Interest Earned</TableCell>
                    <TableCell align="right">End Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {growthData.map((data, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{data.year}</TableCell>
                      <TableCell align="right">${Number(data.startingAmount).toLocaleString()}</TableCell>
                      <TableCell align="right">${Number(data.annualContribution).toLocaleString()}</TableCell>
                      <TableCell align="right">${Number(data.totalContributions).toLocaleString()}</TableCell>
                      <TableCell align="right">${Number(data.interestEarned).toLocaleString()}</TableCell>
                      <TableCell align="right">${Number(data.totalInterestEarned).toLocaleString()}</TableCell>
                      <TableCell align="right">${Number(data.endBalance).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default StockReturnCalculator;