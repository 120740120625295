import React from 'react';
import ExtendedHoursTable from './ExtendedHoursTable';

const ExtendedHoursGainers = ({ marketSession, data }) => {
    const sessionName = marketSession === 'pre' ? 'Pre-Market' : 'After-Market';
    
    return (
        <ExtendedHoursTable
            type="gainers"
            marketSession={marketSession}
            titleTop={`${sessionName} Gainers:`}
            // title={`Top Performing Stocks in ${sessionName}`}
            info={`The ${sessionName} Gainers list shows stocks with the largest percentage increases during ${marketSession === 'pre' ? 'pre-market' : 'after-market'} trading hours compared to their previous regular market close.`}
            data={data}
        />
    );
};

export default ExtendedHoursGainers;