import React, { memo } from 'react';
import { Grid } from '@mui/material';
import MemberCard from './MemberCard';

const MembersGrid = memo(({ members }) => {
    return (
        <Grid container spacing={2}>
            {members.map((member, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={`${member.fullName}-${index}`}>
                    <MemberCard member={member} />
                </Grid>
            ))}
        </Grid>
    );
});

MembersGrid.displayName = 'MembersGrid';

export default MembersGrid;