// src/utils/congressMembersMapping.js
import congressData from './congressData.json';  // Your original JSON file

// Transform the data into a more usable format
export const congressMembersMap = congressData.reduce((acc, member) => {
    const bioguideId = member.id?.bioguide;
    if (bioguideId) {
        // Get the most recent term
        const currentTerm = member.terms ? member.terms[member.terms.length - 1] : null;
        
        acc[bioguideId] = {
            fullName: member.name?.official_full || `${member.name?.first} ${member.name?.last}`,
            firstName: member.name?.first,
            lastName: member.name?.last,
            state: currentTerm?.state,
            party: currentTerm?.party,
            type: currentTerm?.type,     // 'sen' for Senate or 'rep' for House
            district: currentTerm?.district,
            termStart: currentTerm?.start,
            termEnd: currentTerm?.end
        };
    }
    return acc;
}, {});

/**
 * Find a member's bioguide ID by their full name
 * @param {string} fullName - The full name to search for
 * @returns {string|null} The bioguide ID or null if not found
 */
export const findBioguideId = (fullName) => {
    if (!fullName) return null;
    
    // Normalize the search name
    const searchName = fullName.toLowerCase().trim();
    
    // Try exact match first
    const exactMatch = Object.entries(congressMembersMap).find(
        ([_, member]) => member.fullName.toLowerCase() === searchName
    );
    if (exactMatch) return exactMatch[0];

    // If no exact match, try matching first + last name combination
    const flexibleMatch = Object.entries(congressMembersMap).find(
        ([_, member]) => {
            const memberFullName = `${member.firstName} ${member.lastName}`.toLowerCase();
            return memberFullName === searchName;
        }
    );
    
    return flexibleMatch ? flexibleMatch[0] : null;
};

/**
 * Find a member's info by their bioguide ID
 * @param {string} bioguideId - The bioguide ID to look up
 * @returns {Object|null} The member's info or null if not found
 */
export const findMemberByBioguideId = (bioguideId) => {
    return congressMembersMap[bioguideId] || null;
};

// Optional: Export the raw data if needed elsewhere
export const rawCongressData = congressData;