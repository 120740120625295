import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ProfileImage from './ProfileImage';

const MemberCard = memo(({ member }) => {
    const theme = useTheme();

    return (
        <RouterLink
            to={`/congress-member/${encodeURIComponent(member.fullName)}`}
            style={{ textDecoration: 'none' }}
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    height: '100%',
                    minHeight: '300px',
                    position: 'relative',
                    overflow: 'hidden',
                    p: 3,
                    borderRadius: '12px',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    border: `1px solid ${theme.palette.divider}`,
                    '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: theme.shadows[4],
                        backgroundColor: theme.palette.mode === 'dark'
                            ? '#252525'
                            : theme.palette.grey[50],
                    },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '80px',
                        background: member.party?.toLowerCase() === 'democratic'
                            ? 'linear-gradient(180deg, rgba(25,118,210,0.8) 0%, rgba(25,118,210,0) 100%)'
                            : member.party?.toLowerCase() === 'republican'
                                ? 'linear-gradient(180deg, rgba(244,67,54,0.8) 0%, rgba(244,67,54,0) 100%)'
                                : 'linear-gradient(180deg, rgba(117,117,117,0.8) 0%, rgba(117,117,117,0) 100%)',
                        borderRadius: '12px 12px 0 0'
                    }
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    mb: 8
                }}>
                    <ProfileImage
                        name={member.fullName}
                        imageUrls={[
                            member.bioguideId
                                ? `/images/congress/${member.bioguideId}.jpg`
                                : null
                        ]}
                        size={80}
                        sx={{ mb: 1 }}
                    />
                    <Typography
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: '1rem',
                            fontWeight: 500,
                            textAlign: 'center',
                            mb: 1
                        }}
                    >
                        {member.fullName}
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: '0.875rem',
                            textAlign: 'center'
                        }}
                    >
                        {member.party} / {member.displayDistrict}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 'auto'
                }}>
                    <Box>
                        <Typography
                            sx={{
                                color: theme.palette.text.secondary,
                                fontSize: '0.75rem',
                                mb: 0.5
                            }}
                        >
                            Total Trades
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: '0.875rem',
                                textAlign: 'center'
                            }}
                        >
                            {member.totalTrades}
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                        <Typography
                            sx={{
                                color: theme.palette.text.secondary,
                                fontSize: '0.75rem',
                                mb: 0.5
                            }}
                        >
                            Last Traded
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: '0.875rem'
                            }}
                        >
                            {new Date(member.lastTraded).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            })}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </RouterLink>
    );
});

MemberCard.displayName = 'MemberCard';

export default MemberCard;