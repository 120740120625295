import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Chip,
    useTheme,
    useMediaQuery,
    Alert,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton
} from '@mui/material';
import {
    Info as InfoIcon,
} from '@mui/icons-material';

// Helper function to get color based on recommendation
const getRecommendationColor = (recommendation) => {
    switch (recommendation) {
        case 'Strong Buy':
            return '#0eb35b'; // Strong green
        case 'Buy':
            return '#4caf50'; // Green
        case 'Neutral':
            return '#ff9800'; // Orange
        case 'Sell':
            return '#f44336'; // Red
        case 'Strong Sell':
            return '#d32f2f'; // Strong red
        default:
            return '#757575'; // Gray for unknown
    }
};

// Helper function to get color based on score (1-5)
const getScoreColor = (score) => {
    switch (parseInt(score)) {
        case 5:
            return '#0eb35b'; // Strong green
        case 4:
            return '#4caf50'; // Green
        case 3:
            return '#ff9800'; // Orange
        case 2:
            return '#f44336'; // Red
        case 1:
            return '#d32f2f'; // Strong red
        default:
            return '#757575'; // Gray for unknown
    }
};

// Helper function to get text color based on background (for contrast)
const getTextColor = (backgroundColor) => {
    // Convert hex to RGB, then calculate luminance
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return white for dark backgrounds, black for light backgrounds
    return luminance > 0.5 ? '#000000' : '#ffffff';
};

// Helper function to format Altman Z-Score with interpretation
const formatAltmanZScore = (score) => {
    const numScore = parseFloat(score);

    if (isNaN(numScore)) return { score: 'N/A', interpretation: 'Unknown', color: '#757575' };

    if (numScore > 2.99) {
        return {
            score: numScore.toFixed(2),
            interpretation: 'Safe Zone',
            color: '#0eb35b'  // Green
        };
    } else if (numScore >= 1.81 && numScore <= 2.99) {
        return {
            score: numScore.toFixed(2),
            interpretation: 'Gray Zone',
            color: '#ff9800'  // Orange
        };
    } else {
        return {
            score: numScore.toFixed(2),
            interpretation: 'Distress Zone',
            color: '#d32f2f'  // Red
        };
    }
};

// Helper function to format Piotroski F-Score with interpretation
const formatPiotroskiScore = (score) => {
    const numScore = parseInt(score);

    if (isNaN(numScore)) return { score: 'N/A', interpretation: 'Unknown', color: '#757575' };

    if (numScore >= 8) {
        return {
            score: numScore,
            interpretation: 'Very Good',
            color: '#0eb35b'  // Green
        };
    } else if (numScore >= 6) {
        return {
            score: numScore,
            interpretation: 'Good',
            color: '#4caf50'  // Light Green
        };
    } else if (numScore >= 4) {
        return {
            score: numScore,
            interpretation: 'Average',
            color: '#ff9800'  // Orange
        };
    } else if (numScore >= 2) {
        return {
            score: numScore,
            interpretation: 'Poor',
            color: '#f44336'  // Red
        };
    } else {
        return {
            score: numScore,
            interpretation: 'Very Poor',
            color: '#d32f2f'  // Dark Red
        };
    }
};

// Helper function to format numbers for display
const formatNumber = (number) => {
    if (!number || isNaN(number)) return 'N/A';

    // Handle negative numbers
    const isNegative = parseFloat(number) < 0;
    const absNum = Math.abs(parseFloat(number));
    let formatted;

    if (absNum >= 1e12) {
        formatted = `$${(absNum / 1e12).toFixed(2)}T`;
    } else if (absNum >= 1e9) {
        formatted = `$${(absNum / 1e9).toFixed(2)}B`;
    } else if (absNum >= 1e6) {
        formatted = `$${(absNum / 1e6).toFixed(2)}M`;
    } else if (absNum >= 1e3) {
        formatted = `$${(absNum / 1e3).toFixed(2)}K`;
    } else {
        formatted = `$${absNum.toFixed(2)}`;
    }

    return isNegative ? `-${formatted}` : formatted;
};

const RatingsScoresComparison = ({
    symbols,
    managedMode = false,
    ratingsData = [],
    loading = false,
    error = null,
    lastUpdated = null,
    onRefresh = () => { }
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isTablet = useMediaQuery(theme.breakpoints.down('md'));


    if (loading) {
        return (
            <Box sx={{ width: '100%', p: 3 }}>
                <Grid container spacing={3}>
                    {[...Array(symbols.length)].map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card elevation={0} sx={{ height: '100%', border: `1px solid ${theme.palette.divider}` }}>
                                <CardHeader
                                    title={<Skeleton width="60%" />}
                                    subheader={<Skeleton width="40%" />}
                                />
                                <Divider />
                                <CardContent>
                                    <Skeleton height={30} />
                                    <Skeleton height={30} />
                                    <Skeleton height={30} />
                                    <Skeleton height={30} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ width: '100%', p: 2 }}>
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            </Box>
        );
    }

    if (!symbols || symbols.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
                <Typography variant="body1" color="text.secondary">
                    Select symbols to compare their ratings and scores
                </Typography>
            </Box>
        );
    }

    if (ratingsData.length === 0) {
        return (
            <Box sx={{ width: '100%', p: 2 }}>
                <Alert severity="info">No data available for the selected symbols</Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
                mt: -2
            }}>
                {lastUpdated && (
                    <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                        Last updated: {lastUpdated.toLocaleTimeString()}
                    </Typography>
                )}
            </Box>

            {/* Overall Ratings Section */}
            <Box sx={{ mb: 3, width: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Overall Ratings</Typography>
                <Grid container spacing={2}>
                    {ratingsData.map((item) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.symbol}-rating`}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: '100%',
                                    border: `1px solid ${theme.palette.divider}`,
                                    transition: 'transform 0.2s',
                                    '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: 2
                                    }
                                }}
                            >
                                <CardHeader
                                    title={item.symbol}
                                    subheader={item.rating?.rating || 'No Rating'}
                                    titleTypographyProps={{ variant: 'h6' }}
                                    subheaderTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
                                />
                                <Divider />
                                <CardContent>
                                    {item.rating ? (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    mb: 2
                                                }}
                                            >
                                                <Chip
                                                    label={item.rating.ratingRecommendation || 'N/A'}
                                                    sx={{
                                                        backgroundColor: getRecommendationColor(item.rating.ratingRecommendation),
                                                        color: getTextColor(getRecommendationColor(item.rating.ratingRecommendation))
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ textAlign: 'center', mb: 1 }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Overall Score
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    sx={{
                                                        color: getScoreColor(item.rating.ratingScore),
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {item.rating.ratingScore}/5
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                                    Last Updated
                                                </Typography>
                                                <Typography variant="body2">
                                                    {new Date(item.rating.updatedAt).toLocaleDateString()}
                                                </Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <Box sx={{ textAlign: 'center', py: 3 }}>
                                            <Typography variant="body1" color="text.secondary">
                                                No rating data available
                                            </Typography>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Detailed Ratings Section */}
            <Box sx={{ mb: 3, width: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Detailed Ratings
                    <Tooltip title="Detailed ratings include PE (Price-to-Earnings), PB (Price-to-Book), DCF (Discounted Cash Flow), D/E (Debt-to-Equity), ROE (Return on Equity), and ROA (Return on Assets) analyses" enterTouchDelay={0} leaveTouchDelay={1500}>
                        <IconButton size="small" sx={{ ml: 1 }}>
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <TableContainer component={Paper} elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
                    <Table size={isMobile ? "small" : "medium"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Symbol</TableCell>
                                <TableCell align="center">P/E</TableCell>
                                <TableCell align="center">P/B</TableCell>
                                <TableCell align="center">DCF</TableCell>
                                <TableCell align="center">D/E</TableCell>
                                <TableCell align="center">ROE</TableCell>
                                <TableCell align="center">ROA</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ratingsData.map((item) => (
                                <TableRow key={`${item.symbol}-detailed`}>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2" fontWeight="bold">
                                            {item.symbol}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.rating ? (
                                            <>
                                                <Chip
                                                    label={`${item.rating.ratingDetailsPEScore}/5`}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: getScoreColor(item.rating.ratingDetailsPEScore),
                                                        color: getTextColor(getScoreColor(item.rating.ratingDetailsPEScore))
                                                    }}
                                                />
                                                <Typography variant="body2" align="center" sx={{ mt: 0.5 }}>
                                                    {item.rating?.ratingDetailsPERecommendation || '-'}
                                                </Typography>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.rating ? (
                                            <>
                                                <Chip
                                                    label={`${item.rating.ratingDetailsPBScore}/5`}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: getScoreColor(item.rating.ratingDetailsPBScore),
                                                        color: getTextColor(getScoreColor(item.rating.ratingDetailsPBScore))
                                                    }}
                                                />
                                                <Typography variant="body2" align="center" sx={{ mt: 0.5 }}>
                                                    {item.rating?.ratingDetailsPBRecommendation || '-'}
                                                </Typography>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.rating ? (
                                            <>
                                                <Chip
                                                    label={`${item.rating.ratingDetailsDCFScore}/5`}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: getScoreColor(item.rating.ratingDetailsDCFScore),
                                                        color: getTextColor(getScoreColor(item.rating.ratingDetailsDCFScore))
                                                    }}
                                                />
                                                <Typography variant="body2" align="center" sx={{ mt: 0.5 }}>
                                                    {item.rating?.ratingDetailsDCFRecommendation || '-'}
                                                </Typography>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.rating ? (
                                            <>
                                                <Chip
                                                    label={`${item.rating.ratingDetailsDEScore}/5`}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: getScoreColor(item.rating.ratingDetailsDEScore),
                                                        color: getTextColor(getScoreColor(item.rating.ratingDetailsDEScore))
                                                    }}
                                                />
                                                <Typography variant="body2" align="center" sx={{ mt: 0.5 }}>
                                                    {item.rating?.ratingDetailsDERecommendation || '-'}
                                                </Typography>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.rating ? (
                                            <>
                                                <Chip
                                                    label={`${item.rating.ratingDetailsROEScore}/5`}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: getScoreColor(item.rating.ratingDetailsROEScore),
                                                        color: getTextColor(getScoreColor(item.rating.ratingDetailsROEScore))
                                                    }}
                                                />
                                                <Typography variant="body2" align="center" sx={{ mt: 0.5 }}>
                                                    {item.rating?.ratingDetailsROERecommendation || '-'}
                                                </Typography>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.rating ? (
                                            <>
                                                <Chip
                                                    label={`${item.rating.ratingDetailsROAScore}/5`}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: getScoreColor(item.rating.ratingDetailsROAScore),
                                                        color: getTextColor(getScoreColor(item.rating.ratingDetailsROAScore))
                                                    }}
                                                />
                                                <Typography variant="body2" align="center" sx={{ mt: 0.5 }}>
                                                    {item.rating?.ratingDetailsROARecommendation || '-'}
                                                </Typography>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Financial Scores Section */}
            <Box sx={{ mb: 3, width: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Financial Health & Balance Sheet
                    <Tooltip title="Altman Z-Score measures bankruptcy risk. Piotroski F-Score evaluates financial strength. This section also includes balance sheet metrics." enterTouchDelay={0} leaveTouchDelay={1500}>
                        <IconButton size="small" sx={{ ml: 1 }}>
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <Grid container spacing={2}>
                    {ratingsData.map((item) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.symbol}-scores`}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: '100%',
                                    border: `1px solid ${theme.palette.divider}`,
                                    transition: 'transform 0.2s',
                                    '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: 2
                                    }
                                }}
                            >
                                <CardHeader
                                    title={item.symbol}
                                    subheader="Financial Health"
                                    titleTypographyProps={{ variant: 'h6' }}
                                />
                                <Divider />
                                <CardContent>
                                    {item.scores ? (
                                        <>
                                            <Box sx={{ mb: 3 }}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    Altman Z-Score
                                                    <Tooltip title="Altman Z-Score: >2.99 is safe, 1.81-2.99 is gray zone, <1.81 indicates distress" enterTouchDelay={0} leaveTouchDelay={1500}>
                                                        <IconButton size="small" sx={{ ml: 0.5 }}>
                                                            <InfoIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                                {(() => {
                                                    const zScore = formatAltmanZScore(item.scores.altmanZScore);
                                                    return (
                                                        <>
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    color: zScore.color,
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                {zScore.score}
                                                            </Typography>
                                                            <Chip
                                                                label={zScore.interpretation}
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor: zScore.color,
                                                                    color: getTextColor(zScore.color),
                                                                    mt: 1
                                                                }}
                                                            />
                                                        </>
                                                    );
                                                })()}
                                            </Box>

                                            <Box sx={{ mb: 2 }}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    Piotroski F-Score
                                                    <Tooltip title="Piotroski F-Score: 8-9 is very good, 6-7 is good, 4-5 is average, 2-3 is poor, 0-1 is very poor" enterTouchDelay={0} leaveTouchDelay={1500}>
                                                        <IconButton size="small" sx={{ ml: 0.5 }}>
                                                            <InfoIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                                {(() => {
                                                    const fScore = formatPiotroskiScore(item.scores.piotroskiScore);
                                                    return (
                                                        <>
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    color: fScore.color,
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                {fScore.score}/9
                                                            </Typography>
                                                            <Chip
                                                                label={fScore.interpretation}
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor: fScore.color,
                                                                    color: getTextColor(fScore.color),
                                                                    mt: 1
                                                                }}
                                                            />
                                                        </>
                                                    );
                                                })()}
                                            </Box>

                                            <Divider sx={{ my: 2 }} />

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                                <Typography variant="body2" color="text.secondary">Market Cap:</Typography>
                                                <Typography variant="body2" fontWeight="medium">
                                                    {formatNumber(item.scores.marketCap)}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                                <Typography variant="body2" color="text.secondary">Revenue:</Typography>
                                                <Typography variant="body2" fontWeight="medium">
                                                    {formatNumber(item.scores.revenue)}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                                <Typography variant="body2" color="text.secondary">EBIT:</Typography>
                                                <Typography variant="body2" fontWeight="medium">
                                                    {formatNumber(item.scores.ebit)}
                                                </Typography>
                                            </Box>

                                            <Divider sx={{ my: 2 }} />

                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                                Balance Sheet Metrics
                                            </Typography>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                                <Typography variant="body2" color="text.secondary">Total Assets:</Typography>
                                                <Typography variant="body2" fontWeight="medium">
                                                    {formatNumber(item.scores.totalAssets)}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                                <Typography variant="body2" color="text.secondary">Total Liabilities:</Typography>
                                                <Typography variant="body2" fontWeight="medium">
                                                    {formatNumber(item.scores.totalLiabilities)}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                                <Typography variant="body2" color="text.secondary">Retained Earnings:</Typography>
                                                <Typography variant="body2" fontWeight="medium">
                                                    {formatNumber(item.scores.retainedEarnings)}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography variant="body2" color="text.secondary">Working Capital:</Typography>
                                                <Typography variant="body2" fontWeight="medium">
                                                    {formatNumber(item.scores.workingCapital)}
                                                </Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <Box sx={{ textAlign: 'center', py: 3 }}>
                                            <Typography variant="body1" color="text.secondary">
                                                No scores data available
                                            </Typography>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default RatingsScoresComparison;