import React from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useTheme
} from '@mui/material';
import {
    Info as InfoIcon,
    Check as CheckIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

const ProfileComparison = ({
    symbols,
    managedMode = true,
    profiles = [],
    loading = false,
    error = null,
}) => {
    const theme = useTheme();

    const metricDefinitions = {
        beta: "Beta coefficient measures a stock's volatility relative to the market.",
        sector: "Economic sector the company operates in.",
        industry: "Specific industry classification within sector.",
        exchange: "Stock exchange where the company is listed.",
        fullTimeEmployees: "Total number of full-time employees.",
        ceo: "Chief Executive Officer of the company.",
        cik: "Central Index Key (CIK) assigned by the SEC.",
        dcf: "Discounted Cash Flow valuation estimate.",
        dcfDiff: "Difference between current price and DCF value.",
        isEtf: "Whether the security is an Exchange Traded Fund.",
        isFund: "Whether the security is a Mutual Fund.",
        isAdr: "Whether the security is an American Depositary Receipt.",
        isActivelyTrading: "Whether the security is actively trading."
    };

    // Define display rows for the table
    const displayRows = [
        { id: 'companyName', label: 'Company Name' },
        { id: 'sector', label: 'Sector', hasTooltip: true },
        { id: 'industry', label: 'Industry', hasTooltip: true },
        { id: 'beta', label: 'Beta', hasTooltip: true, format: value => value ? value.toFixed(2) : 'N/A' },
        { id: 'exchange', label: 'Exchange', hasTooltip: true },
        { id: 'fullTimeEmployees', label: 'Employees', hasTooltip: true, format: value => value ? Number(value).toLocaleString() : 'N/A' },
        { id: 'ceo', label: 'CEO', hasTooltip: true },
        { id: 'country', label: 'Country' },
        { id: 'city', label: 'City' },
        {
            id: 'website', label: 'Website', format: value => value ?
                <a href={value} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main }}>{value}</a> : 'N/A'
        },
        { id: 'dcf', label: 'DCF Value', hasTooltip: true, format: value => value ? `$${value.toFixed(2)}` : 'N/A' },
        {
            id: 'dcfDiff', label: 'DCF Diff', hasTooltip: true, format: value => {
                if (value === undefined || value === null) return 'N/A';
                const isPositive = value > 0;
                return (
                    <span style={{ color: isPositive ? theme.palette.success.main : theme.palette.error.main }}>
                        {isPositive ? '+' : ''}{value.toFixed(2)}%
                    </span>
                );
            }
        },
        {
            id: 'isEtf', label: 'Is ETF', hasTooltip: true, format: value =>
                value ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckIcon fontSize="small" sx={{ color: theme.palette.success.main }} />
                    </Box> :
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CloseIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
                    </Box>
        },
        {
            id: 'isFund', label: 'Is Fund', hasTooltip: true, format: value =>
                value ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckIcon fontSize="small" sx={{ color: theme.palette.success.main }} />
                    </Box> :
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CloseIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
                    </Box>
        },
        {
            id: 'isAdr', label: 'Is ADR', hasTooltip: true, format: value =>
                value ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckIcon fontSize="small" sx={{ color: theme.palette.success.main }} />
                    </Box> :
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CloseIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
                    </Box>
        },
        {
            id: 'isActivelyTrading', label: 'Active Trading', hasTooltip: true, format: value =>
                value ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckIcon fontSize="small" sx={{ color: theme.palette.success.main }} />
                    </Box> :
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CloseIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
                    </Box>
        },
    ];

    if (!symbols || symbols.length === 0) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 3
            }}>
                <Typography variant="body1" color="text.secondary">
                    Select at least one symbol to view company profiles.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>

            {loading && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <CircularProgress />
                </Box>
            )}

            {error && !loading && (
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            )}

            {!loading && !error && profiles.length > 0 && (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: theme.palette.background.paper, fontWeight: 'bold' }}>
                                    Metric
                                </TableCell>
                                {symbols.map(symbol => {
                                    const profile = profiles.find(p => p.symbol === symbol);
                                    return (
                                        <TableCell
                                            key={symbol}
                                            align="center"
                                            sx={{
                                                backgroundColor: theme.palette.background.paper,
                                                fontWeight: 'bold',
                                                minWidth: '150px'
                                            }}
                                        >
                                            {profile ? profile.symbol : symbol}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayRows.map(row => (
                                <TableRow key={row.id} hover>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            position: 'sticky',
                                            left: 0,
                                            fontWeight: 'medium',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5
                                        }}
                                    >
                                        {row.label}
                                        {row.hasTooltip && (
                                            <Tooltip title={metricDefinitions[row.id] || ''} enterTouchDelay={0} leaveTouchDelay={1500}>
                                                <InfoIcon fontSize="small" color="action" sx={{ opacity: 0.6 }} />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    {symbols.map(symbol => {
                                        const profile = profiles.find(p => p.symbol === symbol);
                                        const value = profile ? profile[row.id] : undefined;
                                        return (
                                            <TableCell key={`${symbol}-${row.id}`} align="center">
                                                {row.format ? row.format(value) : (value || 'N/A')}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}

            {!loading && !error && profiles.length === 0 && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <Typography variant="body1" color="text.secondary">
                        No profile data available for the selected symbols.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ProfileComparison;