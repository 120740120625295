import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import {
    Box,
    Paper,
    Typography,
    useTheme,
    useMediaQuery,
    Drawer,
    Fab,
    Tabs,
    Tab,
    Stack,
    Button
} from '@mui/material';
import {
    Compare as CompareIcon
} from '@mui/icons-material';
import StockComparison from './StockComparison';
import RatingsScoresComparison from './RatingsScoresComparison';
import ControlPanel from './ControlPanel';
import ProfileComparison from './ProfileComparison';
import PriceComparison from './PriceComparison';
import FinancialComparison from './FinancialComparison';
import CashFlowComparison from './CashFlowComparison';
import AssetPerformanceComparison from './AssetPerformanceComparison';
import ReturnComparisonChart from './ReturnComparisonChart';
import DividendComparison from './DividendComparison';
import axios from '../config/axiosConfig';

const AssetComparisonDashboard = () => {
    const [searchParams] = useSearchParams();
    const symbolsParam = searchParams.get('symbols');
    const timeframeParam = searchParams.get('timeframe');
    const tabParam = searchParams.get('tab');

    const { user } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const maxSymbols = user?.planName === 'Premium' ? 8 :
        user?.planName === 'Basic' ? 4 : 2;
    const [activeTab, setActiveTab] = useState(() => {
        return ['profiles', 'pricing', 'financial', 'ratings', 'valuation', 'performance'].includes(tabParam)
            ? tabParam
            : 'profiles';
    });

    const [symbols, setSymbols] = useState(() => {
        if (symbolsParam) {
            const urlSymbols = symbolsParam.split(',');
            return urlSymbols.slice(0, maxSymbols);
        }
        return [];
    });

    const timeframes = ['1M', '6M', 'YTD', '1Y', '5Y'];
    const [selectedTimeframe, setSelectedTimeframe] = useState(() => {
        return timeframes.includes(timeframeParam) ? timeframeParam : 'YTD';
    });

    const [drawerOpen, setDrawerOpen] = useState(false);

    // New state for centralized data
    const [profileData, setProfileData] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const [ratingsData, setRatingsData] = useState([]);
    const [financialData, setFinancialData] = useState({});
    const [comparisonData, setComparisonData] = useState({});
    const [cashFlowData, setCashFlowData] = useState({});
    const [financialSubTab, setFinancialSubTab] = useState('income');
    const [dividendData, setDividendData] = useState([]);
    const [loading, setLoading] = useState({
        profiles: false,
        pricing: false,
        ratings: false,
        financial: false,
        cashFlow: false,
        dividends: false
    });
    const [error, setError] = useState({
        profiles: null,
        pricing: null,
        ratings: null,
        financial: null,
        cashFlow: null,
        dividends: null
    });
    const [lastUpdated, setLastUpdated] = useState({
        profiles: null,
        pricing: null,
        ratings: null,
        financial: null,
        cashFlow: null,
        dividends: null
    });

    useEffect(() => {
        const params = new URLSearchParams();
        if (symbols.length > 0) {
            params.set('symbols', symbols.join(','));
        }
        params.set('timeframe', selectedTimeframe);
        params.set('tab', activeTab);
        navigate(`?${params.toString()}`, { replace: true });
    }, [symbols, selectedTimeframe, activeTab, navigate]);

    // Fetch profile data
    const fetchProfileData = async () => {
        if (!symbols || symbols.length === 0) {
            setProfileData([]);
            return;
        }

        try {
            setLoading(prev => ({ ...prev, profiles: true }));
            setError(prev => ({ ...prev, profiles: null }));

            const response = await axios.get('/api/company-profiles', {
                params: {
                    symbols: symbols.join(',')
                }
            });

            if (response.data && response.data.data) {
                setProfileData(response.data.data);
                setLastUpdated(prev => ({ ...prev, profiles: new Date() }));
            } else {
                setError(prev => ({ ...prev, profiles: 'Invalid response format from server' }));
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
            setError(prev => ({
                ...prev,
                profiles: error.response?.data?.error || 'Failed to fetch company profiles'
            }));
        } finally {
            setLoading(prev => ({ ...prev, profiles: false }));
        }
    };

    // Fetch price data
    const fetchPriceData = async () => {
        if (!symbols || symbols.length === 0) {
            setPriceData([]);
            return;
        }

        try {
            setLoading(prev => ({ ...prev, pricing: true }));
            setError(prev => ({ ...prev, pricing: null }));

            const response = await axios.get('/api/asset-prices', {
                params: {
                    symbols: symbols.join(',')
                }
            });

            if (response.data && Array.isArray(response.data)) {
                setPriceData(response.data);
                setLastUpdated(prev => ({ ...prev, pricing: new Date() }));
            } else {
                setError(prev => ({ ...prev, pricing: 'Invalid data format received' }));
            }
        } catch (error) {
            console.error('Error fetching price data:', error);
            setError(prev => ({
                ...prev,
                pricing: error.response?.data?.error || 'Failed to fetch price data'
            }));
        } finally {
            setLoading(prev => ({ ...prev, pricing: false }));
        }
    };

    // Fetch ratings data
    const fetchRatingsData = async () => {
        if (!symbols || symbols.length === 0) {
            setRatingsData([]);
            return;
        }

        try {
            setLoading(prev => ({ ...prev, ratings: true }));
            setError(prev => ({ ...prev, ratings: null }));

            const response = await axios.get('/api/company-ratings-and-scores-comparison', {
                params: {
                    symbols: symbols.join(',')
                }
            });

            if (response.data && response.data.success && response.data.data) {
                setRatingsData(response.data.data);
                setLastUpdated(prev => ({ ...prev, ratings: new Date() }));
            } else {
                setError(prev => ({ ...prev, ratings: response.data.message || 'Invalid data format received' }));
            }
        } catch (error) {
            console.error('Error fetching ratings data:', error);
            setError(prev => ({
                ...prev,
                ratings: error.response?.data?.error || 'Failed to fetch ratings data'
            }));
        } finally {
            setLoading(prev => ({ ...prev, ratings: false }));
        }
    };

    const fetchFinancialData = async () => {
        if (!symbols || symbols.length === 0) {
            setFinancialData({});
            return;
        }

        try {
            setLoading(prev => ({ ...prev, financial: true }));
            setError(prev => ({ ...prev, financial: null }));

            const response = await axios.get('/api/company-income-statements', {
                params: {
                    symbols: symbols.join(','),
                    period: 'annual'
                }
            });

            if (response.data && response.data.data) {
                setFinancialData(response.data.data);
                setLastUpdated(prev => ({ ...prev, financial: new Date() }));
            } else {
                setError(prev => ({ ...prev, financial: 'Invalid response format from server' }));
            }
        } catch (error) {
            console.error('Error fetching financial data:', error);
            setError(prev => ({
                ...prev,
                financial: error.response?.data?.error || 'Failed to fetch financial data'
            }));
        } finally {
            setLoading(prev => ({ ...prev, financial: false }));
        }
    };

    const fetchCashFlowData = async () => {
        if (!symbols || symbols.length === 0) {
            setCashFlowData({});
            return;
        }

        try {
            setLoading(prev => ({ ...prev, cashFlow: true }));
            setError(prev => ({ ...prev, cashFlow: null }));

            const response = await axios.get('/api/company-cash-statements', {
                params: {
                    symbols: symbols.join(','),
                    period: 'annual'
                }
            });

            if (response.data && response.data.data) {
                setCashFlowData(response.data.data);
                setLastUpdated(prev => ({ ...prev, cashFlow: new Date() }));
            } else {
                setError(prev => ({ ...prev, cashFlow: 'Invalid response format from server' }));
            }
        } catch (error) {
            console.error('Error fetching cash flow data:', error);
            setError(prev => ({
                ...prev,
                cashFlow: error.response?.data?.error || 'Failed to fetch cash flow data'
            }));
        } finally {
            setLoading(prev => ({ ...prev, cashFlow: false }));
        }
    };

    const fetchComparisonData = async () => {
        if (!symbols || symbols.length === 0) {
            setComparisonData({});
            return;
        }

        try {
            setLoading(prev => ({ ...prev, returns: true }));
            setError(prev => ({ ...prev, returns: null }));

            const response = await axios.get('/api/stocks-comparison', {
                params: {
                    symbols: symbols.join(',')
                }
            });

            if (response.data && Array.isArray(response.data)) {
                // Store the response in the format our components expect
                const cacheKey = symbols.sort().join(',');
                setComparisonData({
                    [cacheKey]: response.data
                });
                setLastUpdated(prev => ({ ...prev, returns: new Date() }));
            } else {
                setError(prev => ({ ...prev, returns: 'Invalid data format received' }));
            }
        } catch (error) {
            console.error('Error fetching comparison data:', error);
            setError(prev => ({
                ...prev,
                returns: error.response?.data?.error || 'Failed to fetch comparison data'
            }));
        } finally {
            setLoading(prev => ({ ...prev, returns: false }));
        }
    };

    // Fetch dividend data
    const fetchDividendData = async () => {
        if (!symbols || symbols.length === 0) {
            setDividendData([]);
            return;
        }

        try {
            setLoading(prev => ({ ...prev, dividends: true }));
            setError(prev => ({ ...prev, dividends: null }));

            const response = await axios.get('/api/company-dividends', {
                params: {
                    symbols: symbols.join(',')
                }
            });

            if (response.data && response.data.data) {
                setDividendData(response.data.data);
                setLastUpdated(prev => ({ ...prev, dividends: new Date() }));
            } else {
                setError(prev => ({ ...prev, dividends: 'Invalid response format from server' }));
            }
        } catch (error) {
            console.error('Error fetching dividend data:', error);
            setError(prev => ({
                ...prev,
                dividends: error.response?.data?.error || 'Failed to fetch dividend data'
            }));
        } finally {
            setLoading(prev => ({ ...prev, dividends: false }));
        }
    };

    // Fetch all data when symbols change
    useEffect(() => {
        if (symbols.length > 0) {
            fetchProfileData();
            fetchPriceData();
            fetchRatingsData();
            fetchFinancialData();
            fetchCashFlowData();
            fetchComparisonData();
            fetchDividendData();

            // Set up auto-refresh for price data every 60 seconds
            const intervalId = setInterval(() => {
                fetchPriceData();
            }, 60000);

            return () => {
                clearInterval(intervalId);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols]);

    const handleAddSymbol = (symbol) => {
        if (!symbol || typeof symbol !== 'string' || symbols.length >= maxSymbols) return;

        const formattedSymbol = symbol.toUpperCase().trim();
        if (!symbols.includes(formattedSymbol)) {
            setSymbols([...symbols, formattedSymbol]);

            if (isMobile) {
                setDrawerOpen(false);
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);

        // If we haven't loaded data for this tab yet, load it now
        if (newValue === 'profiles' && profileData.length === 0 && !loading.profiles) {
            fetchProfileData();
        } else if (newValue === 'pricing' && priceData.length === 0 && !loading.pricing) {
            fetchPriceData();
        } else if (newValue === 'ratings' && ratingsData.length === 0 && !loading.ratings) {
            fetchRatingsData();
        } else if (newValue === 'financial' && Object.keys(financialData).length === 0 && !loading.financial) {
            fetchFinancialData();
        } else if (newValue === 'performance' && Object.keys(comparisonData).length === 0 && !loading.performance) {
            fetchComparisonData();
        } else if (newValue === 'dividends' && dividendData.length === 0 && !loading.dividends) {
            fetchDividendData();
        }
    };

    const handleRemoveSymbol = (symbolToRemove) => {
        setSymbols(symbols.filter(symbol => symbol !== symbolToRemove));
    };

    const handleClearAll = () => {
        setSymbols([]);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2, gap: 3 }}>
            <Typography variant="h5">Asset Comparison</Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 50px)', gap: 2 }}>
                {/* Horizontal Control Panel for Desktop */}
                {!isMobile && (
                    <Paper
                        elevation={0}
                        sx={{
                            width: '100%',
                            bgcolor: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                            position: 'relative',
                            zIndex: 1200  // Higher z-index for the entire control panel
                        }}
                    >
                        <ControlPanel
                            onAddSymbol={handleAddSymbol}
                            onRemoveSymbol={handleRemoveSymbol}
                            symbols={symbols}
                            maxSymbols={maxSymbols}
                            selectedTimeframe={selectedTimeframe}
                            setSelectedTimeframe={setSelectedTimeframe}
                            toggleDrawer={toggleDrawer}
                            isMobile={isMobile}
                            theme={theme}
                            onClearAll={handleClearAll}
                            showTimeframe={false}
                        />
                    </Paper>
                )}

                {/* Mobile Control Panel */}
                {isMobile && (
                    <>
                        <Fab
                            color="primary"
                            aria-label="compare"
                            onClick={toggleDrawer}
                            sx={{
                                position: 'fixed',
                                bottom: 16,
                                right: 16,
                                zIndex: 1000
                            }}
                        >
                            <CompareIcon />
                        </Fab>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={toggleDrawer}
                            PaperProps={{
                                sx: {
                                    width: '85%',
                                    maxWidth: '360px',
                                    minWidth: '280px',
                                    bgcolor: theme.palette.background.paper
                                }
                            }}
                        >
                            <ControlPanel
                                onAddSymbol={handleAddSymbol}
                                onRemoveSymbol={handleRemoveSymbol}
                                symbols={symbols}
                                maxSymbols={maxSymbols}
                                selectedTimeframe={selectedTimeframe}
                                setSelectedTimeframe={setSelectedTimeframe}
                                toggleDrawer={toggleDrawer}
                                isMobile={isMobile}
                                theme={theme}
                                onClearAll={handleClearAll}
                            />
                        </Drawer>
                    </>
                )}

                {/* Main Content Area */}
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    overflow: 'auto',
                    width: '100%'
                }}>
                    {/* Performance Chart Section - Always Visible */}
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            bgcolor: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                            height: 550,
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2
                        }}>
                            <Typography variant="h6">Performance Comparison</Typography>

                            {/* Timeframe controls - Only visible on desktop/tablet */}
                            {!isMobile && (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <Typography variant="body2" color="text.secondary">Time Period:</Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        {['1M', '6M', 'YTD', '1Y', '5Y'].map((timeframe) => (
                                            <Button
                                                key={timeframe}
                                                variant={selectedTimeframe === timeframe ? 'contained' : 'outlined'}
                                                onClick={() => setSelectedTimeframe(timeframe)}
                                                size="small"
                                            >
                                                {timeframe}
                                            </Button>
                                        ))}
                                    </Stack>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{ height: 420 }}>
                            <StockComparison
                                symbols={symbols}
                                selectedTimeframe={selectedTimeframe}
                                managedMode={true}
                            />
                        </Box>
                    </Paper>

                    {/* Tab Navigation */}
                    <Paper
                        elevation={0}
                        sx={{
                            bgcolor: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                        }}
                    >
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            variant={isMobile ? "scrollable" : "standard"}
                            scrollButtons={isMobile ? "auto" : false}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="comparison tabs"
                        >
                            <Tab value="profiles" label="Profiles" disabled={symbols.length === 0} />
                            <Tab value="pricing" label="Pricing" disabled={symbols.length === 0} />
                            <Tab value="financial" label="Financial" disabled={symbols.length === 0} />
                            <Tab value="ratings" label="Ratings & Scores" disabled={symbols.length === 0} />
                            <Tab value="performance" label="Performance" disabled={symbols.length === 0} />
                            <Tab value="dividends" label="Dividends" disabled={symbols.length === 0} />
                        </Tabs>
                    </Paper>

                    {/* Company Profiles Section */}
                    {activeTab === 'profiles' && symbols.length > 0 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                bgcolor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                mb: 2
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>Company Profiles</Typography>
                            <ProfileComparison
                                symbols={symbols}
                                managedMode={true}
                                profiles={profileData}
                                loading={loading.profiles}
                                error={error.profiles}
                                lastUpdated={lastUpdated.profiles}
                                onRefresh={fetchProfileData}
                            />
                        </Paper>
                    )}

                    {/* Price Comparison Section */}
                    {activeTab === 'pricing' && symbols.length > 0 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                bgcolor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                mb: 2,
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Box sx={{
                                flexGrow: 1,
                                overflow: 'hidden'
                            }}>
                                <PriceComparison
                                    symbols={symbols}
                                    managedMode={true}
                                    priceData={priceData}
                                    loading={loading.pricing}
                                    error={error.pricing}
                                    lastUpdated={lastUpdated.pricing}
                                    onRefresh={fetchPriceData}
                                    onError={(error) => setError(prev => ({ ...prev, pricing: error }))}
                                />
                            </Box>
                        </Paper>
                    )}

                    {activeTab === 'financial' && symbols.length > 0 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                bgcolor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                mb: 2,
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>Financial Comparison</Typography>

                            <Box sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={financialSubTab}
                                    onChange={(e, newValue) => setFinancialSubTab(newValue)}
                                    aria-label="financial statement tabs"
                                    variant={isMobile ? "scrollable" : "standard"}
                                    scrollButtons={isMobile ? "auto" : false}
                                >
                                    <Tab value="income" label="Income Statement" />
                                    <Tab value="cashflow" label="Cash Flow" />
                                </Tabs>
                            </Box>

                            <Box sx={{
                                flexGrow: 1,
                                overflow: 'hidden'
                            }}>
                                {financialSubTab === 'income' && (
                                    <FinancialComparison
                                        symbols={symbols}
                                        managedMode={true}
                                        financialData={financialData}
                                        loading={loading.financial}
                                        error={error.financial}
                                        lastUpdated={lastUpdated.financial}
                                        onRefresh={fetchFinancialData}
                                    />
                                )}

                                {financialSubTab === 'cashflow' && (
                                    <CashFlowComparison
                                        symbols={symbols}
                                        managedMode={true}
                                        cashFlowData={cashFlowData}
                                        loading={loading.cashFlow}
                                        error={error.cashFlow}
                                        lastUpdated={lastUpdated.cashFlow}
                                        onRefresh={fetchCashFlowData}
                                    />
                                )}
                            </Box>
                        </Paper>
                    )}

                    {/* Ratings and Scores Section */}
                    {activeTab === 'ratings' && symbols.length > 0 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                bgcolor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                mb: 2,
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>Ratings & Scores Comparison</Typography>
                            <Box sx={{
                                flexGrow: 1,
                                overflow: 'hidden'
                            }}>
                                <RatingsScoresComparison
                                    symbols={symbols}
                                    managedMode={true}
                                    ratingsData={ratingsData}
                                    loading={loading.ratings}
                                    error={error.ratings}
                                    lastUpdated={lastUpdated.ratings}
                                    onRefresh={fetchRatingsData}
                                />
                            </Box>
                        </Paper>
                    )}

                    {/* Performance Comparison Section */}
                    {activeTab === 'performance' && symbols.length > 0 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                bgcolor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                mb: 2,
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Box sx={{ mb: 4 }}>
                                <ReturnComparisonChart
                                    symbols={symbols}
                                    fullDataCache={comparisonData}
                                    onRefresh={fetchComparisonData}
                                    onError={(error) => setError(prev => ({ ...prev, returns: error }))}
                                />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <AssetPerformanceComparison
                                    symbols={symbols}
                                    fullDataCache={comparisonData}
                                    managedMode={true}
                                    onRefresh={fetchComparisonData}
                                    onError={(error) => setError(prev => ({ ...prev, returns: error }))}
                                />
                            </Box>
                        </Paper>
                    )}

                    {/* Dividend Analysis Section */}
                    {activeTab === 'dividends' && symbols.length > 0 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                bgcolor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                mb: 2,
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>Dividend Analysis</Typography>
                            <Box sx={{
                                flexGrow: 1,
                                overflow: 'hidden'
                            }}>
                                <DividendComparison
                                    symbols={symbols}
                                    dividendData={dividendData}
                                    loading={loading.dividends}
                                    error={error.dividends}
                                    lastUpdated={lastUpdated.dividends}
                                    onRefresh={fetchDividendData}
                                />
                            </Box>
                        </Paper>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AssetComparisonDashboard;