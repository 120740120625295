import React, { useState, useEffect, useRef } from 'react';
import axios from '../config/axiosConfig';
import { 
    TextField, 
    InputAdornment, 
    Paper, 
    List, 
    ListItem, 
    Typography,
    Box,
    CircularProgress
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const ComparisonSearchBar = ({ onSymbolSelect, maxSymbols, currentSymbols = [] }) => {
    const theme = useTheme();
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);
    const searchRef = useRef(null);

    useEffect(() => {
        const fetchResults = async () => {
            if (query.length < 1) {
                setResults([]);
                return;
            }
        
            setIsLoading(true);
            try {
                const response = await axios.get(`/api/search-tradable`, {
                    params: { query }
                });
                // Filter out already selected symbols
                const filteredResults = response.data.filter(
                    result => !currentSymbols.includes(result.symbol)
                );
                setResults(filteredResults);
            } catch (error) {
                console.error('Error fetching search results:', error);
                setResults([]);
            } finally {
                setIsLoading(false);
            }
        };

        const timeoutId = setTimeout(fetchResults, 500);
        return () => clearTimeout(timeoutId);
    }, [query, currentSymbols]);

    const handleResultClick = (symbol) => {
        onSymbolSelect(symbol);
        setQuery('');
        setResults([]);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setResults([]);
            setQuery('');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getTypeColor = (type) => {
        switch (type) {
            case 'stock':
                return theme.palette.primary.main;
            case 'crypto':
                return theme.palette.success.main;
            case 'index':
                return theme.palette.warning.main;
            default:
                return theme.palette.text.primary;
        }
    };

    const isSymbolSelectable = () => {
        return currentSymbols.length < maxSymbols;
    };

    // Common dropdown paper styles
    const dropdownPaperStyle = {
        position: 'absolute', 
        top: '40px', 
        width: '100%', 
        zIndex: 9999, // Much higher z-index
        backdropFilter: 'blur(8px)',
        backgroundColor: theme.palette.mode === 'dark' 
            ? 'rgba(30, 30, 30, 0.9)' 
            : 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
    };

    return (
        <div 
            ref={searchRef} 
            style={{ 
                position: 'relative', 
                width: '100%',
                isolation: 'isolate' // Creates a new stacking context
            }}
        >
            <TextField
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                variant="outlined"
                size="small"
                placeholder={isSymbolSelectable() ? "Search assets..." : `Maximum ${maxSymbols} symbols reached`}
                fullWidth
                autoComplete="off"
                disabled={!isSymbolSelectable()}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
    
            {query.length > 0 && isLoading ? (
                <Paper 
                    elevation={3}
                    sx={{
                        ...dropdownPaperStyle
                    }}
                >
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <CircularProgress size={20} />
                    </Box>
                </Paper>
            ) : query.length > 0 && results.length === 0 ? (
                <Paper 
                    elevation={3}
                    sx={{
                        ...dropdownPaperStyle
                    }}
                >
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Typography color="text.secondary">
                            No results found for "{query}"
                        </Typography>
                    </Box>
                </Paper>
            ) : results.length > 0 && (
                <Paper 
                    elevation={3}
                    sx={{
                        ...dropdownPaperStyle,
                        maxHeight: '400px',
                        overflowY: 'auto'
                    }}
                >
                    <List>
                        {results.map((result) => (
                            <ListItem 
                                button 
                                key={`${result.symbol}-${result.type}`} 
                                onClick={() => handleResultClick(result.symbol)}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover
                                    }
                                }}
                            >
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="subtitle1" component="span" fontWeight="bold">
                                            {result.symbol}
                                        </Typography>
                                        <Typography 
                                            variant="caption" 
                                            component="span"
                                            sx={{ 
                                                color: getTypeColor(result.type),
                                                fontWeight: 'bold',
                                                textTransform: 'uppercase'
                                            }}
                                        >
                                            {result.type}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {result.name}
                                    </Typography>
                                    {result.exchange && (
                                        <Typography variant="caption" color="text.secondary">
                                            {result.exchange}
                                        </Typography>
                                    )}
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </div>
    );
}

export default ComparisonSearchBar;