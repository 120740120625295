import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';
import { Typography, Button, Box } from '@mui/material';
import TitleAndInfo from './TitleAndInfo';
import { exportToCsv } from '../utils/exportToCsv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ExtendedHoursTable = ({ type, marketSession, title, titleTop, info, data }) => {
    const { user } = useAuth();
    const theme = useTheme();

    // Format price with 2 decimal places or more if needed
    const formatPrice = (price) => {
        if (price === undefined || price === null) return 'N/A';

        const priceValue = parseFloat(price);
        if (isNaN(priceValue)) return 'N/A';

        // For prices that need more precision (less than 0.01)
        if (Math.abs(priceValue) < 0.01 && priceValue !== 0) {
            return priceValue.toFixed(4);
        }

        return priceValue.toFixed(2);
    };

    // Columns configuration
    const columns = [
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 280,
            renderCell: (params) => (
                <div>
                    <RouterLink
                        to={`/asset/${params.row.symbol}`}
                        style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                    >
                        {params.row.symbol}
                    </RouterLink>
                    <div style={{ fontSize: '0.9em', color: '#888' }}>{params.row.name}</div>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'price',
            headerName: marketSession === 'pre' ? 'Pre-Market' : 'After-Market',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatPrice(params.value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'regularPrice',
            headerName: 'Regular Hours',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => formatPrice(params.value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'change',
            headerName: 'Change',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {formatPrice(params.value)}
                </div>
            ),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'percentChange',
            headerName: '%Change',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {formatPrice(params.value)}%
                </div>
            ),
            align: 'right',
            headerAlign: 'right',
        },
    ];

    const handleExport = () => {
        // Call the exportToCsv utility
        const filename = `${marketSession}_market_${type}.csv`;
        exportToCsv(filename, columns, data);
    };

    return (
        <Box className="table-container" mb={4}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ xs: 'column', sm: 'row' }}
                mb={2}
                gap={2}
            >
                <TitleAndInfo titleTop={titleTop} title={title} info={info} />

                {user.plan?.name === 'Premium' && (
                    <Box sx={{ display: { md: 'block' } }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExport}
                        >
                            Export
                        </Button>
                    </Box>
                )}
            </Box>

            {data.length === 0 ? (
                <Typography variant="body1" align="center" p={2}>
                    No data available for {marketSession === 'pre' ? 'pre-market' : 'after-market'} {type}.
                </Typography>
            ) : (
                <DataGrid
                    rows={data}
                    columns={columns}
                    getRowId={(row) => row.symbol}
                    disableColumnMenu
                    pagination
                    autoHeight
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            fontSize: '0.8rem',
                        },
                    }}
                />
            )}
        </Box>
    );
};

export default ExtendedHoursTable;