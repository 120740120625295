import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  useTheme,
  alpha,
  Button,
  Card,
  Chip,
} from '@mui/material';
import {
  Assessment,
  FilterList,
  AccountBalanceWallet,
  Visibility,
  Article,
  Star,
  Lock,
  CurrencyBitcoin,
  TrendingUp,
  Gavel,
  EventAvailable,
  BarChart

} from '@mui/icons-material';

import Portfolio_dark from '../images/Portfolio_dark.png';
import AvgScreener_dark from '../images/AvgScreener_dark.png';
import Watchlist_dark from '../images/Watchlist_dark.png';
import BTC_dark from '../images/BTC_dark.png';
import RevBreakdown_dark from '../images/RevBreakdown_dark.png';
import CongressMembers_dark from '../images/CongressMembers_dark.png';
import EarningTranscript_dark from '../images/EarningTranscript_dark.png';
import EarningCalendar_dark from '../images/EarningCalendar_dark.png';
import FinBalance_dark from '../images/FinBalance_dark.png';
import Ratings_dark from '../images/Ratings_dark.png';

import Portfolio_light from '../images/Portfolio_light.png';
import AvgScreener_light from '../images/AvgScreener_light.png';
import Watchlist_light from '../images/Watchlist_light.png';
import BTC_light from '../images/BTC_light.png';
import RevBreakdown_light from '../images/RevBreakdown_light.png';
import CongressMembers_light from '../images/CongressMembers_light.png';
import EarningTranscript_light from '../images/EarningTranscript_light.png';
import EarningCalendar_light from '../images/EarningCalendar_light.png';
import FinBalance_light from '../images/FinBalance_light.png';
import Ratings_light from '../images/Ratings_light.png';

// Feature data structure
const features = [
  {
    icon: <Assessment sx={{ fontSize: 24 }} />,
    title: 'Financial Data & Analytics',
    description: 'Dive into detailed financial statements, including income statements, balance sheets, and cash flow reports. Visualize key financial trends with intuitive charts and data-driven insights',
    isPremium: false,
    previewImageDark: FinBalance_dark,
    previewImageLight: FinBalance_light,
  },
  {
    icon: <AccountBalanceWallet sx={{ fontSize: 24 }} />,
    title: 'Portfolio Management',
    description: 'Track and analyze your investment portfolio performance with advanced tools and metrics. Monitor your investments in real-time.',
    isPrimary: true,
    isPremium: false,
    // previewImageDark: '/api/placeholder/800/600',
    previewImageDark: Portfolio_dark,
    previewImageLight: Portfolio_light,
  },
  {
    icon: <FilterList sx={{ fontSize: 24 }} />,
    title: 'Advanced Screeners',
    description: 'Screen stocks and markets using professional-grade filters. Find the best opportunities with customizable criteria and real-time filtering.',
    isPrimary: true,
    isPremium: false,
    previewImageDark: AvgScreener_dark,
    previewImageLight: AvgScreener_light,
  },
  {
    icon: <BarChart sx={{ fontSize: 24 }} />,
    title: 'Revenue Breakdown',
    description: 'Analyze how companies generate revenue with detailed breakdowns by segments, regions, and product lines. Gain insights into business diversification and key income drivers',
    isPrimary: false,
    isPremium: false,
    previewImageDark: RevBreakdown_dark,
    previewImageLight: RevBreakdown_light,
  },
  {
    icon: <TrendingUp sx={{ fontSize: 24 }} />,
    title: 'Ratings & Metrics',
    description: 'Evaluate companies with in-depth ratings, financial health scores, and key performance metrics. Compare valuations, risk factors, and overall market positioning',
    isPrimary: true,
    isPremium: false,
    previewImageDark: Ratings_dark,
    previewImageLight: Ratings_light,
  },
  {
    icon: <Visibility sx={{ fontSize: 24 }} />,
    title: 'Watchlist Tracking',
    description: 'Keep an eye on your favorite stocks, crypto, and indexes with a personalized watchlist. Easily monitor price movements and key data at a glance.',
    isPremium: false,
    previewImageDark: Watchlist_dark,
    previewImageLight: Watchlist_light,
  },
  {
    icon: <CurrencyBitcoin sx={{ fontSize: 24 }} />,
    title: 'Cryptocurrencies',
    description: 'Track crypto prices in real-time with advanced cryptocurrency trading tools and market analysis.',
    isPrimary: true,
    isPremium: false,
    previewImageDark: BTC_dark,
    previewImageLight: BTC_light,
  },
  {
    icon: <Gavel sx={{ fontSize: 24 }} />,
    title: 'Congress Tracker',
    description: 'Track and analyze the trading activities of all members of Congress. Explore detailed trading histories, patterns, and financial moves.',
    isPremium: false,
    previewImageDark: CongressMembers_dark,
    previewImageLight: CongressMembers_light,
  },
  {
    icon: <Article sx={{ fontSize: 24 }} />,
    title: 'Market Reports',
    description: 'Access detailed market reports, earnings transcripts, and analyst insights. Stay informed with professional research and analysis.',
    isPremium: false,
    previewImageDark: EarningTranscript_dark,
    previewImageLight: EarningTranscript_light,
  },
  {
    icon: <EventAvailable sx={{ fontSize: 24 }} />,
    title: 'Market Calendars',
    description: 'Stay ahead with our comprehensive market calendars, including earnings reports, key economic events, and upcoming dividend schedules.',
    isPremium: false,
    previewImageDark: EarningCalendar_dark,
    previewImageLight: EarningCalendar_light,
  },
  // {
  //   icon: <Newspaper sx={{ fontSize: 24 }} />,
  //   title: 'Market News',
  //   description: 'Stay informed with latest market news, real-time updates, and breaking financial headlines.',
  //   isPremium: false,
  //   previewImage: '/api/placeholder/800/600',
  // }
];

// Mobile Layout Component
const MobileFeatures = ({ selectedIndex, onSelect, features }) => {
  const theme = useTheme();

  const getPreviewImage = (feature) => {
    return theme.palette.mode === 'dark' ? feature.previewImageDark : feature.previewImageLight;
  };

  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column', gap: 2 }}>
      {/* Feature Selection Chips */}
      <Box sx={{ display: 'flex', gap: 1, overflow: 'auto', pb: 1, px: 1 }}>
        {features.map((feature, index) => (
          <Chip
            key={index}
            label={feature.title}
            onClick={() => onSelect(index)}
            sx={{
              borderRadius: 2,
              backgroundColor: selectedIndex === index
                ? theme.palette.primary.main
                : alpha(theme.palette.primary.main, 0.1),
              color: selectedIndex === index
                ? '#fff'
                : theme.palette.text.primary,
              '&:hover': {
                backgroundColor: selectedIndex === index
                  ? theme.palette.primary.dark
                  : alpha(theme.palette.primary.main, 0.2),
              },
              whiteSpace: 'nowrap',
            }}
          />
        ))}
      </Box>

      {/* Selected Feature Preview */}
      <Card
        variant="outlined"
        sx={{
          overflow: 'hidden',
          background: theme.palette.mode === 'dark'
            ? 'linear-gradient(145deg, rgba(19, 47, 76, 0.4), rgba(19, 47, 76, 0.1))'
            : 'linear-gradient(145deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7))',
          backdropFilter: 'blur(10px)',
          border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            pt: '56.25%', // 16:9 aspect ratio
            '& img': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: '8px 8px 0 0'
            }
          }}
        >
          <img
            src={getPreviewImage(features[selectedIndex])}
            alt={features[selectedIndex].title}
            loading="lazy"
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            {features[selectedIndex].icon}
            <Typography variant="h6" component="h3">
              {features[selectedIndex].title}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {features[selectedIndex].description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

// Desktop Feature Button Component
const FeatureButton = ({ feature, selected, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      fullWidth
      sx={{
        p: 2,
        height: 'auto',
        textAlign: 'left',
        borderRadius: 2,
        background: selected
          ? alpha(theme.palette.primary.main, 0.1)
          : 'transparent',
        '&:hover': {
          background: selected
            ? alpha(theme.palette.primary.main, 0.15)
            : alpha(theme.palette.primary.main, 0.05),
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
          {feature.icon}
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: selected ? theme.palette.primary.main : theme.palette.text.primary,
            }}
          >
            {feature.title}
          </Typography>
          {/* Feature Tags */}
          <Box sx={{ display: 'flex', gap: 0.5, ml: 'auto' }}>
            {feature.isPrimary && (
              <Box
                sx={{
                  px: 1,
                  py: 0.25,
                  borderRadius: 1,
                  fontSize: '0.65rem',
                  color: theme.palette.primary.main,
                  background: alpha(theme.palette.primary.main, 0.1),
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <Star sx={{ fontSize: 12 }} />
                Popular
              </Box>
            )}
            {feature.isPremium && (
              <Box
                sx={{
                  px: 1,
                  py: 0.25,
                  borderRadius: 1,
                  fontSize: '0.65rem',
                  color: theme.palette.warning.main,
                  background: alpha(theme.palette.warning.main, 0.1),
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <Lock sx={{ fontSize: 12 }} />
                Premium
              </Box>
            )}
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
          }}
        >
          {feature.description}
        </Typography>
      </Box>
    </Button>
  );
};

// Main Features Component
const Features = () => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getPreviewImage = (feature) => {
    return theme.palette.mode === 'dark' ? feature.previewImageDark : feature.previewImageLight;
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: { xs: 6, md: 8 } }}>
        {/* Main Title */}
        <Box sx={{ width: { sm: '100%', md: '60%' }, mb: { xs: 4, md: 6 } }}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              background: theme.palette.mode === 'dark'
                ? 'linear-gradient(to right, #fff 0%, rgb(218, 193, 193) 100%)'
                : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Professional Tools for Every Trader
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
          >
            Access powerful trading tools and features designed for both beginners and professional traders.
            Our platform provides everything you need to make informed investment decisions.
          </Typography>
        </Box>

        {/* Mobile Layout */}
        <MobileFeatures
          selectedIndex={selectedIndex}
          onSelect={setSelectedIndex}
          features={features}
        />

        {/* Desktop Layout */}
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            gap: 4,
            flexDirection: 'row-reverse',
          }}
        >
          {/* Preview Area */}
          <Box sx={{ flex: '1 1 60%' }}>
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                minHeight: 600,
                background: theme.palette.mode === 'dark'
                  ? 'linear-gradient(145deg, rgba(19, 47, 76, 0.4), rgba(19, 47, 76, 0.1))'
                  : 'linear-gradient(145deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7))',
                backdropFilter: 'blur(10px)',
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
              }}
            >
              <img
                src={getPreviewImage(features[selectedIndex])}
                alt={features[selectedIndex].title}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  borderRadius: theme.shape.borderRadius,
                }}
              />
            </Card>
          </Box>

          {/* Feature Selection */}
          <Box sx={{
            flex: '1 1 40%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxHeight: '600px',
            overflowY: 'auto',
            pr: 2,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: alpha(theme.palette.primary.main, 0.2),
              borderRadius: '4px',
              '&:hover': {
                background: alpha(theme.palette.primary.main, 0.3),
              },
            },
          }}>
            {features.map((feature, index) => (
              <FeatureButton
                key={index}
                feature={feature}
                selected={selectedIndex === index}
                onClick={() => setSelectedIndex(index)}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Features;