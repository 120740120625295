import React, { useState, useRef } from 'react';
import axios from '../config/axiosConfig';
import {
  TextField,
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Popper,
  ClickAwayListener,
  Grow,
  Stack,
  CircularProgress
} from '@mui/material';
import UpgradePopup from './UpgradePopup';

const AddPortfolioAsset = ({ portfolioId, handleCloseModalAndRefetchData }) => {
  const [newAsset, setNewAsset] = useState({
    symbol: '',
    quantity: '',
    price: '',
  });
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errors, setErrors] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const debounceTimeout = useRef(null);

  const searchTradableAssets = async (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get('/api/search-tradable', {
        params: { query }
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching assets:', error);
    } finally {
      setLoading(false);
    }
  };

  const createPortfolioAsset = async (portfolioId, newAsset) => {
    try {
      const response = await axios.post('/api/createasset', { portfolioId, newAsset });
      handleCloseModalAndRefetchData();
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setShowUpgradePopup(true);
      }
      const errorMsg = error.response?.data?.message || 'Error creating portfolio asset';
      setErrorMessage(errorMsg);
      setShowSnackbar(true);
      console.error('Error creating portfolio asset:', error);
    }
  };

  const handleSymbolChange = (e) => {
    const { value } = e.target;
    const uppercaseValue = value.toUpperCase();
    setNewAsset(prev => ({ ...prev, symbol: uppercaseValue }));
    setErrors(prev => ({ ...prev, symbol: '' }));
    setAnchorEl(e.currentTarget);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      searchTradableAssets(uppercaseValue);
    }, 300);
  };

  const handleAssetSelect = (asset) => {
    setNewAsset(prev => ({
      ...prev,
      symbol: asset.symbol,
      price: asset.price || '',
    }));
    setSearchResults([]);
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'quantity' || name === 'price') {
      parsedValue = value === '' ? '' : parseFloat(value);
    }

    setNewAsset(prev => ({
      ...prev,
      [name]: parsedValue,
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));

    if (name !== 'symbol') {
      setSearchResults([]);
      setAnchorEl(null);
    }
  };

  const handleClickAway = () => {
    setSearchResults([]);
    setAnchorEl(null);
  };


  const validateForm = () => {
    const newErrors = {};
    if (!newAsset.symbol.trim()) newErrors.symbol = 'Symbol is required';
    if (!newAsset.quantity || newAsset.quantity <= 0) newErrors.quantity = 'Quantity must be greater than 0';
    if (!newAsset.price || newAsset.price <= 0) newErrors.price = 'Price must be greater than 0';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await createPortfolioAsset(portfolioId, newAsset);
        setNewAsset({
          symbol: '',
          quantity: '',
          price: '',
        });
      } catch (error) {
        console.error('Error adding new portfolio asset:', error);
      }
    }
  };

  const open = Boolean(anchorEl) && (searchResults.length > 0 || loading);

  return (
    <Box sx={{
      width: 400,
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    }}>
      <Typography variant="h6" component="h2" mb={2} textAlign="center">
        Add Transaction
      </Typography>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <TextField
                  id="symbol"
                  name="symbol"
                  label="Symbol"
                  variant="outlined"
                  value={newAsset.symbol}
                  onChange={handleSymbolChange}
                  fullWidth
                  required
                  error={!!errors.symbol}
                  helperText={errors.symbol}
                  inputRef={inputRef}
                  autoComplete="off"
                />
                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  transition
                  disablePortal={false} // Use Portal for proper z-index
                  modifiers={[
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: 'window',
                      },
                    },
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom-start', 'top-start'],
                      },
                    },
                  ]}
                  sx={{
                    width: anchorEl ? anchorEl.offsetWidth : 'auto',
                    zIndex: 1500, // Ensure it’s above other elements
                  }}
                >

                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper
                        elevation={3}
                        sx={{
                          maxHeight: '250px',
                          overflowY: 'auto',
                          mt: 1,
                          '& .MuiListItem-root': {
                            py: 1
                          }
                        }}
                      >
                        {loading ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                            <CircularProgress size={24} />
                          </Box>
                        ) : (
                          <List dense>
                            {searchResults.map((result, index) => (
                              <ListItem
                                button
                                key={`${result.symbol}-${index}`}
                                onClick={() => handleAssetSelect(result)}
                                divider={index < searchResults.length - 1}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: 'action.hover',
                                  }
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <Typography variant="body1" component="span" fontWeight="medium">
                                        {result.symbol}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        component="span"
                                        color="text.secondary"
                                        sx={{ fontSize: '0.75em' }}
                                      >
                                        ({result.exchange})
                                      </Typography>
                                    </Stack>
                                  }
                                  secondary={
                                    <Typography variant="body2" color="text.secondary">
                                      {result.name} ({result.type})
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="quantity"
                  name="quantity"
                  label="Quantity"
                  variant="outlined"
                  type="number"
                  value={newAsset.quantity}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.quantity}
                  helperText={errors.quantity}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="price"
                  name="price"
                  label="Price per asset"
                  variant="outlined"
                  type="number"
                  value={newAsset.price}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.price}
                  helperText={errors.price}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                  }}
                >
                  * Please use the currency of the traded asset on the exchange
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button onClick={handleCloseModalAndRefetchData} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Add
              </Button>
            </Box>
          </form>
        </Box>
      </ClickAwayListener>

      <UpgradePopup
        open={showUpgradePopup}
        onClose={() => setShowUpgradePopup(false)}
      />

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddPortfolioAsset;