import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    CircularProgress,
    Alert,
    useTheme,
    useMediaQuery
} from '@mui/material';
import {
    Info as InfoIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';

const AssetPerformanceComparison = ({
    symbols,
    fullDataCache,
    managedMode = true,
    onRefresh = () => { },
    onError = () => { }
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [performanceData, setPerformanceData] = useState([]);

    // Define timeframes to show in the table
    const timeframeMetrics = [
        { id: 'return1M', label: 'Return 1M', tooltip: 'Percentage return over the last month' },
        { id: 'return6M', label: 'Return 6M', tooltip: 'Percentage return over the last 6 months' }, // Added 6M
        { id: 'returnYTD', label: 'Return YTD', tooltip: 'Percentage return year-to-date' },
        { id: 'return1Y', label: 'Return 1Y', tooltip: 'Percentage return over the last year' },
        { id: 'return3Y', label: 'Return 3Y', tooltip: 'Percentage return over the last 3 years' }, // Added 3Y
        { id: 'return5Y', label: 'Return 5Y', tooltip: 'Percentage return over the last 5 years' }
    ];

    useEffect(() => {
        if (symbols.length === 0 || !fullDataCache) {
            setPerformanceData([]);
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const cacheKey = symbols.sort().join(',');
            const cachedData = fullDataCache[cacheKey];

            if (!cachedData || !Array.isArray(cachedData) || cachedData.length === 0) {
                setLoading(false);
                return;
            }

            const calculatedData = calculateReturns(cachedData);
            setPerformanceData(calculatedData);
            setLoading(false);
        } catch (err) {
            console.error('Error processing performance data:', err);
            setError('Unable to process performance data');
            onError('Failed to process performance data');
            setLoading(false);
        }
    }, [symbols, fullDataCache, onError]);

    // Calculate returns for each timeframe
    // In both ReturnComparisonChart and AssetPerformanceComparison
    const calculateReturns = (cachedData) => {
        const now = new Date();

        // Define timeframe start dates exactly like StockComparison does
        const getStartDate = (timeframe) => {
            switch (timeframe) {
                case '1M':
                    return new Date(new Date().setMonth(now.getMonth() - 1));
                case '6M':
                    return new Date(new Date().setMonth(now.getMonth() - 6));
                case 'YTD':
                    return new Date(now.getFullYear(), 0, 1); // January 1st
                case '1Y':
                    return new Date(new Date().setFullYear(now.getFullYear() - 1));
                case '3Y':
                    return new Date(new Date().setFullYear(now.getFullYear() - 3));
                case '5Y':
                    return new Date(new Date().setFullYear(now.getFullYear() - 5));
                default:
                    return new Date(now.getFullYear(), 0, 1);
            }
        };

        return cachedData.map(symbolData => {
            if (!symbolData || !symbolData.symbol || !Array.isArray(symbolData.fullData) || symbolData.fullData.length === 0) {
                return {
                    symbol: symbolData?.symbol || 'Unknown',
                    return1M: null,
                    return6M: null,
                    returnYTD: null,
                    return1Y: null,
                    return3Y: null,
                    return5Y: null
                };
            }

            const sortedData = [...symbolData.fullData].sort((a, b) => new Date(a.date) - new Date(b.date));
            const latestPrice = sortedData[sortedData.length - 1]?.close;

            // For each timeframe, filter data and use first point as reference
            const calculate = (timeframe) => {
                const startDate = getStartDate(timeframe);
                const filteredData = sortedData
                    .filter(d => d && d.date && d.close && new Date(d.date) >= startDate)
                    .sort((a, b) => new Date(a.date) - new Date(b.date));

                if (filteredData.length === 0) return null;

                const basePrice = filteredData[0].close;
                if (basePrice <= 0) return null;

                return ((latestPrice - basePrice) / basePrice) * 100;
            };

            // Calculate returns for each timeframe using the same method
            return {
                symbol: symbolData.symbol,
                return1M: calculate('1M'),
                return6M: calculate('6M'),
                returnYTD: calculate('YTD'),
                return1Y: calculate('1Y'),
                return3Y: calculate('3Y'),
                return5Y: calculate('5Y')
            };
        });
    };

    // Format percentage values with color coding
    const formatPercentage = (value) => {
        if (value === null || value === undefined || value === '-') return '-';

        const numValue = parseFloat(value);
        const color = numValue > 0
            ? theme.palette.success.main
            : numValue < 0
                ? theme.palette.error.main
                : theme.palette.text.primary;

        const icon = numValue > 0
            ? <ArrowUpwardIcon fontSize="small" sx={{ fontSize: 14, marginRight: 0.5 }} />
            : numValue < 0
                ? <ArrowDownwardIcon fontSize="small" sx={{ fontSize: 14, marginRight: 0.5 }} />
                : null;

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color }}>
                {icon}
                {Math.abs(numValue).toFixed(2)}%
            </Box>
        );
    };

    return (
        <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4
            }}>
                <Typography variant="h6">Asset Performance Comparison</Typography>

                {/*<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {lastUpdated && (
                        <Typography variant="caption" color="text.secondary">
                            Last updated: {lastUpdated.toLocaleTimeString()}
                        </Typography>
                    )}
                    <IconButton
                        size="small"
                        onClick={handleRefresh}
                        disabled={loading}
                        title="Refresh data"
                    >
                        <RefreshIcon fontSize="small" />
                    </IconButton>
                </Box>  
                */}
            </Box>

            {symbols.length === 0 ? (
                <Typography variant="body2" color="text.secondary" sx={{ py: 4, textAlign: 'center' }}>
                    Add symbols to view performance comparison
                </Typography>
            ) : loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress size={40} />
                </Box>
            ) : error ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            ) : performanceData.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Typography variant="body2" color="text.secondary">
                        Waiting for data to be available...
                    </Typography>
                </Box>
            ) : (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table stickyHeader size={isMobile ? "small" : "medium"}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        fontWeight: 'bold',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 3
                                    }}
                                >
                                    Symbol
                                </TableCell>
                                {timeframeMetrics.map((metric) => (
                                    <TableCell
                                        key={metric.id}
                                        align="right"
                                        sx={{ whiteSpace: 'nowrap' }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {metric.label}
                                            {metric.tooltip && (
                                                <Tooltip title={metric.tooltip} arrow enterTouchDelay={0} leaveTouchDelay={1500}>
                                                    <InfoIcon fontSize="small" color="disabled" sx={{ ml: 0.5, fontSize: 16 }} />
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {performanceData.map((row) => (
                                <TableRow key={row.symbol} hover>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 2,
                                            fontWeight: 'medium'
                                        }}
                                    >
                                        {row.symbol}
                                    </TableCell>
                                    {timeframeMetrics.map((metric) => {
                                        const value = row[metric.id];
                                        return (
                                            <TableCell key={`${row.symbol}-${metric.id}`} align="right">
                                                {formatPercentage(value)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
        </Box>
    );
};

export default AssetPerformanceComparison;