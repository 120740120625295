import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container, Box, Grid, Typography, Switch, FormControlLabel,
  Card, CardContent, Divider, useTheme, useMediaQuery
} from '@mui/material';
import BarChart from './BarChart';
import { DataGrid } from '@mui/x-data-grid';
import withPlanAccess from './WithPlanAccess';

const CompanyHistoricalDividendsV2 = ({ data }) => {
  const { symbol } = useParams();
  const [showRawData, setShowRawData] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // Transform and validate the data
  const historicalDividendsData = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];

    const allHistoricalData = data
      .filter(item => item.historical && Array.isArray(item.historical))
      .flatMap(item => item.historical)
      .filter(dividend =>
        dividend &&
        dividend.dividend &&
        dividend.date
      );

    return allHistoricalData.sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [data]);

  // Calculate summary metrics from ACTUAL data
  const summaryMetrics = useMemo(() => {
    if (!historicalDividendsData.length) return null;

    // Get the most recent dividend data
    const latestDividend = [...historicalDividendsData].sort((a, b) =>
      new Date(b.date) - new Date(a.date)
    )[0];

    // Calculate annual dividend (based on frequency)
    let annualMultiplier = 1;
    if (latestDividend.frequency === 'Quarterly') {
      annualMultiplier = 4;
    } else if (latestDividend.frequency === 'Semi-Annual') {
      annualMultiplier = 2;
    } else if (latestDividend.frequency === 'Monthly') {
      annualMultiplier = 12;
    }

    const annualDividend = latestDividend.adjDividend * annualMultiplier;

    // Get the most recent dividend amount
    const sortedDividends = [...historicalDividendsData].sort((a, b) =>
      new Date(b.date) - new Date(a.date)
    );

    // Find dividend from previous year for the same quarter/period
    const findQuarterlyDividend = (yearsAgo) => {
      if (sortedDividends.length === 0) return null;

      const latestDate = new Date(sortedDividends[0].date);
      const targetYear = latestDate.getFullYear() - yearsAgo;

      // Try to find a dividend in the same quarter
      const targetQuarter = Math.floor(latestDate.getMonth() / 3);
      const quarterStart = targetQuarter * 3;
      const quarterEnd = quarterStart + 2;

      const dividendsFromYear = sortedDividends.filter(div => {
        const divDate = new Date(div.date);
        const divYear = divDate.getFullYear();
        const divMonth = divDate.getMonth();
        return divYear === targetYear && divMonth >= quarterStart && divMonth <= quarterEnd;
      });

      return dividendsFromYear.length > 0 ? dividendsFromYear[0] : null;
    };

    // Find dividend from previous year
    const oneYearAgoDividend = findQuarterlyDividend(1);

    // Calculate 1-year growth rate
    let oneYearGrowth = null;
    if (oneYearAgoDividend && oneYearAgoDividend.adjDividend > 0) {
      oneYearGrowth = ((latestDividend.adjDividend - oneYearAgoDividend.adjDividend) /
        oneYearAgoDividend.adjDividend) * 100;
    }

    return {
      yield: latestDividend.yield !== undefined ? latestDividend.yield : null,
      annualDividend: annualDividend,
      exDividendDate: latestDividend.date,
      payoutFrequency: latestDividend.frequency || null,
      oneYearGrowth: oneYearGrowth
    };
  }, [historicalDividendsData]);

  if (!historicalDividendsData.length) {
    return null;
  }

  const prepareChartData = (label, key, colors) => {
    return {
      labels: historicalDividendsData.map(dividend => new Date(dividend.date).getFullYear()),
      datasets: [{
        label: label,
        backgroundColor: colors.backgroundColor,
        borderColor: colors.borderColor,
        borderWidth: 1,
        hoverBackgroundColor: colors.hoverBackgroundColor,
        hoverBorderColor: colors.hoverBorderColor,
        data: historicalDividendsData.map(dividend => dividend[key])
      }]
    };
  };

  // Define columns for DataGrid
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      valueFormatter: params =>
        params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
    {
      field: 'adjDividend',
      headerName: 'Adj Dividend',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? Number(params.value).toFixed(4) : 'N/A'
    },
    {
      field: 'dividend',
      headerName: 'Dividend',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? Number(params.value).toFixed(4) : 'N/A'
    },
    {
      field: 'yield',
      headerName: 'Yield (%)',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? Number(params.value).toFixed(2) + '%' : 'N/A'
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
    },
    {
      field: 'recordDate',
      headerName: 'Record Date',
      flex: 1,
      valueFormatter: params =>
        params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      flex: 1,
      valueFormatter: params =>
        params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
    {
      field: 'declarationDate',
      headerName: 'Declaration Date',
      flex: 1,
      valueFormatter: params =>
        params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
  ];

  // Prepare rows for DataGrid
  const rows = historicalDividendsData.map((dividend, index) => ({
    id: index,
    date: dividend.date,
    adjDividend: dividend.adjDividend,
    dividend: dividend.dividend,
    yield: dividend.yield,
    frequency: dividend.frequency,
    recordDate: dividend.recordDate,
    paymentDate: dividend.paymentDate,
    declarationDate: dividend.declarationDate
  }));

  const chartDataDividends = prepareChartData('Dividend', 'adjDividend', {
    backgroundColor: 'rgba(75,192,192,0.2)',
    borderColor: 'rgba(75,192,192,1)',
    hoverBackgroundColor: 'rgba(75,192,192,0.4)',
    hoverBorderColor: 'rgba(75,192,192,1)'
  });

  // For metric cards styling - made more compact
  const MetricCard = ({ title, value, format }) => {
    if (value === null || value === undefined) return null;

    let formattedValue = value;

    if (format === 'percent') {
      formattedValue = typeof value === 'number' ? `${value.toFixed(2)}%` : value;
    } else if (format === 'currency') {
      formattedValue = typeof value === 'number' ? `$${value.toFixed(2)}` : value;
    } else if (format === 'date') {
      formattedValue = value ? new Date(value).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }) : 'N/A';
    }

    return (
      <Card sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 1.5,
        boxShadow: 2,
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: 3,
        },
        width: isMobile ? '120px' : '180px',
        minWidth: '120px',
        maxWidth: '300px'
      }}>
        <CardContent sx={{
          flexGrow: 1,
          padding: isMobile ? '6px 10px' : '12px',
          '&:last-child': { paddingBottom: isMobile ? '6px' : '12px' }
        }}>
          <Typography
            variant={isMobile ? "caption" : "subtitle2"}
            color="text.secondary"
            gutterBottom
            sx={{ mb: 0.5 }}
          >
            {title}
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            component="div"
            sx={{ fontWeight: 'bold' }}
          >
            {formattedValue}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container maxWidth={isMobile ? "sm" : "xl"}>
      {summaryMetrics && (
        <Box my={isMobile ? 2 : 3}>
          <Typography variant={isMobile ? "h5" : "h4"} gutterBottom align="center" sx={{ mb: isMobile ? 2 : 3 }}>
            Dividend Metrics for {symbol}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'center'
            }}
          >
            {summaryMetrics.yield !== null && (
              <MetricCard
                title="Dividend Yield"
                value={summaryMetrics.yield}
                format="percent"
              />
            )}
            <MetricCard
              title="Annual Dividend"
              value={summaryMetrics.annualDividend}
              format="currency"
            />
            <MetricCard
              title="Ex-Dividend Date"
              value={summaryMetrics.exDividendDate}
              format="date"
            />
            {summaryMetrics.payoutFrequency && (
              <MetricCard
                title="Payout Frequency"
                value={summaryMetrics.payoutFrequency}
              />
            )}

            {/* Only showing 1Y Dividend Growth */}
            {summaryMetrics.oneYearGrowth !== null && (
              <MetricCard
                title="1Y Dividend Growth"
                value={summaryMetrics.oneYearGrowth}
                format="percent"
              />
            )}
          </Box>
        </Box>
      )}

      <Divider sx={{ my: isMobile ? 2 : 3 }} />

      <Box my={isMobile ? 2 : 3}>
        <Grid container justifyContent="center" spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={10}>
            {chartDataDividends && <BarChart data={chartDataDividends} />}
          </Grid>
        </Grid>
      </Box>

      <Box my={isMobile ? 2 : 3} display="flex" justifyContent="center">
        <FormControlLabel
          control={
            <Switch
              checked={showRawData}
              onChange={() => setShowRawData(!showRawData)}
            />
          }
          label="Show Raw Data"
        />
      </Box>

      {showRawData && (
        <Box my={isMobile ? 2 : 3} sx={{ width: '100%' }}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            gutterBottom
            align="center"
          >
            Historical Dividends for {symbol}
          </Typography>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            disableColumnMenu
            pagination
            paginationPosition="middle"
            initialState={{
              pagination: { paginationModel: { pageSize: 15 } },
              sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
            }}
            pageSizeOptions={[5, 10, 15, 25]}
            sx={{
              '& .MuiDataGrid-cell': {
                fontSize: isMobile ? '0.85rem' : '1rem',
              },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: isMobile ? '0.7rem' : '0.8rem',
              },
            }}
            autoHeight
          />
        </Box>
      )}
    </Container>
  );
};

// Maintain the plan access control
const allowedPlans = ['Basic', 'Premium'];
export default withPlanAccess(CompanyHistoricalDividendsV2, allowedPlans);