import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Alert,
    CircularProgress,
    useTheme
} from '@mui/material';
import {
    Info as InfoIcon,
} from '@mui/icons-material';

// Helper function to format numbers
const formatNumber = (num, decimals = 2) => {
    if (num === null || num === undefined) return '-';
    if (num === 0) return '0';

    // Convert to number if it's a string
    const numValue = typeof num === 'string' ? parseFloat(num) : num;

    const absNum = Math.abs(numValue);
    const sign = numValue < 0 ? '-' : '';

    if (absNum >= 1000000000000) {
        return `${sign}${(absNum / 1000000000000).toFixed(decimals)}T`;
    } else if (absNum >= 1000000000) {
        return `${sign}${(absNum / 1000000000).toFixed(decimals)}B`;
    } else if (absNum >= 1000000) {
        return `${sign}${(absNum / 1000000).toFixed(decimals)}M`;
    } else if (absNum >= 1000) {
        return `${sign}${(absNum / 1000).toFixed(decimals)}K`;
    } else {
        return `${sign}${absNum.toFixed(decimals)}`;
    }
};

// Format date helper
const formatDate = (dateString) => {
    if (!dateString) return '-';
    try {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    } catch (error) {
        return '-';
    }
};

// Cash flow metrics definitions
const metricDefinitions = {
    netIncome: "Net profit or loss for the period",
    depreciationAndAmortization: "Non-cash expense that reduces the value of assets over time",
    stockBasedCompensation: "Non-cash compensation expense for equity awards",
    changeInWorkingCapital: "Changes in operational assets and liabilities",
    accountsReceivables: "Changes in accounts receivable during the period (negative indicates increase)",
    accountsPayables: "Changes in accounts payable during the period (positive indicates increase)",
    inventory: "Changes in inventory during the period (negative indicates increase)",
    otherWorkingCapital: "Changes in other operational assets and liabilities",
    operatingCashFlow: "Net cash generated by operating activities",
    capitalExpenditure: "Funds used to acquire, upgrade, and maintain physical assets",
    investmentsInPropertyPlantAndEquipment: "Cash spent on property, plant and equipment",
    purchasesOfInvestments: "Cash used to acquire investments",
    salesMaturitiesOfInvestments: "Cash received from sale or maturity of investments",
    acquisitionsNet: "Cash spent on business acquisitions",
    netCashUsedForInvestingActivites: "Net cash used in or provided by investing activities",
    debtRepayment: "Cash used to repay debt",
    commonStockRepurchased: "Cash spent on share buybacks",
    dividendsPaid: "Cash paid as dividends to shareholders",
    netCashUsedProvidedByFinancingActivities: "Net cash used in or provided by financing activities",
    freeCashFlow: "Operating cash flow minus capital expenditures",
    netChangeInCash: "Net increase or decrease in cash during the period",
    cashAtBeginningOfPeriod: "Cash balance at the beginning of the period",
    cashAtEndOfPeriod: "Cash balance at the end of the period"
};

// Define metrics to display in the order they should appear
const cashFlowMetrics = [
    { id: 'date', label: 'Fiscal Year End', format: (value) => formatDate(value) },
    { id: 'calendarYear', label: 'Calendar Year' },

    // Operating Activities
    { id: 'section1', label: 'Operating Activities', isSection: true },
    { id: 'netIncome', label: 'Net Income', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'depreciationAndAmortization', label: 'Depreciation & Amortization', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'stockBasedCompensation', label: 'Stock-Based Compensation', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'changeInWorkingCapital', label: 'Change in Working Capital', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'accountsReceivables', label: 'Accounts Receivables', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'accountsPayables', label: 'Accounts Payables', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'inventory', label: 'Inventory', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'otherWorkingCapital', label: 'Other Working Capital', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'operatingCashFlow', label: 'Operating Cash Flow', format: (value) => formatNumber(value, 1), hasTooltip: true },

    // Investing Activities
    { id: 'section2', label: 'Investing Activities', isSection: true },
    { id: 'capitalExpenditure', label: 'Capital Expenditure', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'investmentsInPropertyPlantAndEquipment', label: 'Investments in PPE', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'purchasesOfInvestments', label: 'Purchases of Investments', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'salesMaturitiesOfInvestments', label: 'Sales/Maturities of Investments', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'acquisitionsNet', label: 'Acquisitions (Net)', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'netCashUsedForInvestingActivites', label: 'Net Cash from Investing', format: (value) => formatNumber(value, 1), hasTooltip: true },

    // Financing Activities
    { id: 'section3', label: 'Financing Activities', isSection: true },
    { id: 'debtRepayment', label: 'Debt Repayment', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'commonStockRepurchased', label: 'Stock Repurchases', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'dividendsPaid', label: 'Dividends Paid', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'netCashUsedProvidedByFinancingActivities', label: 'Net Cash from Financing', format: (value) => formatNumber(value, 1), hasTooltip: true },

    // Cash Summary
    { id: 'section4', label: 'Cash Summary', isSection: true },
    { id: 'freeCashFlow', label: 'Free Cash Flow', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'netChangeInCash', label: 'Net Change in Cash', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'cashAtBeginningOfPeriod', label: 'Cash at Beginning of Period', format: (value) => formatNumber(value, 1), hasTooltip: true },
    { id: 'cashAtEndOfPeriod', label: 'Cash at End of Period', format: (value) => formatNumber(value, 1), hasTooltip: true }
];

const CashFlowComparison = ({
    symbols,
    managedMode = true,
    cashFlowData = {},
    loading = false,
    error = null,
}) => {
    const theme = useTheme();

    if (!symbols || symbols.length === 0) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 3
            }}>
                <Typography variant="body1" color="text.secondary">
                    Select at least one symbol to view cash flow data.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>
                <Typography variant="subtitle1">Cash Flow Statement Comparison</Typography>
            </Box>

            {loading && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <CircularProgress />
                </Box>
            )}

            {error && !loading && (
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            )}

            {!loading && !error && Object.keys(cashFlowData).length > 0 && (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        fontWeight: 'bold',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 3,
                                        minWidth: 180
                                    }}
                                >
                                    Metric
                                </TableCell>
                                {symbols.map(symbol => (
                                    <TableCell
                                        key={symbol}
                                        align="right"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            fontWeight: 'bold',
                                            minWidth: '150px'
                                        }}
                                    >
                                        {symbol}
                                        {cashFlowData[symbol] && cashFlowData[symbol].reportedCurrency && (
                                            <Typography variant="caption" display="block" color="text.secondary">
                                                {cashFlowData[symbol].reportedCurrency}
                                            </Typography>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cashFlowMetrics.map((metric, index) => {
                                if (metric.isSection) {
                                    return (
                                        <TableRow key={metric.id}>
                                            <TableCell
                                                colSpan={symbols.length + 1}
                                                sx={{
                                                    backgroundColor: theme.palette.mode === 'dark' ?
                                                        'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
                                                    fontWeight: 'bold',
                                                    py: 1
                                                }}
                                            >
                                                {metric.label}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }

                                return (
                                    <TableRow key={metric.id} hover>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{
                                                backgroundColor: theme.palette.background.paper,
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 2,
                                                fontWeight: metric.isBold ? 'bold' : 'medium',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5
                                            }}
                                        >
                                            {metric.label}
                                            {metric.hasTooltip && (
                                                <Tooltip title={metricDefinitions[metric.id] || ''} arrow enterTouchDelay={0} leaveTouchDelay={1500}>
                                                    <InfoIcon fontSize="small" color="action" sx={{ opacity: 0.6, fontSize: 16 }} />
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        {symbols.map(symbol => {
                                            const statement = cashFlowData[symbol];
                                            const value = statement ? statement[metric.id] : null;
                                            return (
                                                <TableCell
                                                    key={`${symbol}-${metric.id}`}
                                                    align="right"
                                                    sx={{
                                                        fontWeight: metric.isBold ? 'bold' : 'normal',
                                                    }}
                                                >
                                                    {metric.format ? metric.format(value) : (value || '-')}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            )}

            {!loading && !error && Object.keys(cashFlowData).length === 0 && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3
                }}>
                    <Typography variant="body1" color="text.secondary">
                        No cash flow data available for the selected symbols.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default CashFlowComparison;