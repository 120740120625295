import React from 'react';
import {
    Box,
    IconButton,
    Button,
    Typography,
    Stack,
    Chip,
    Divider,
    useTheme,
    useMediaQuery
} from '@mui/material';
import {
    Close as CloseIcon,
    Clear as ClearIcon
} from '@mui/icons-material';
import ComparisonSearchBar from './ComparisonSearchBar';

const ControlPanel = ({
    onAddSymbol,
    onRemoveSymbol,
    symbols,
    maxSymbols,
    selectedTimeframe,
    setSelectedTimeframe,
    toggleDrawer,
    isMobile,
    theme,
    onClearAll,
    showTimeframe = false
}) => {
    const timeframes = ['1M', '6M', 'YTD', '1Y', '5Y'];
    const localTheme = useTheme();
    const isTablet = useMediaQuery(localTheme.breakpoints.down('md'));

    // This is for mobile - stays the same as before with vertical layout
    if (isMobile) {
        return (
            <Box sx={{ p: 2 }}>
                <Stack spacing={3}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" color="text.primary">Compare Assets</Typography>
                        <IconButton onClick={toggleDrawer} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                        mb: 1,
                        position: 'relative',
                        zIndex: 9999 // Adding high z-index here for mobile
                    }}>
                        <Box sx={{
                            flexGrow: 1,
                            position: 'relative',
                            isolation: 'isolate' // Creating stacking context in mobile view
                        }}>
                            <ComparisonSearchBar
                                onSymbolSelect={onAddSymbol}
                                maxSymbols={maxSymbols}
                                currentSymbols={symbols}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Selected Symbols
                            </Typography>
                            {symbols.length > 0 && (
                                <Button
                                    startIcon={<ClearIcon />}
                                    onClick={onClearAll}
                                    size="small"
                                    color="error"
                                >
                                    Clear All
                                </Button>
                            )}
                        </Box>
                        <Stack
                            direction="row"
                            spacing={1}
                            flexWrap="wrap"
                            gap={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            {symbols.map((symbol) => (
                                <Chip
                                    key={symbol}
                                    label={symbol}
                                    onDelete={() => onRemoveSymbol(symbol)}
                                    color="primary"
                                    size="small"
                                />
                            ))}
                        </Stack>
                    </Box>

                    <Divider />

                    <Box>
                        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                            Time Period
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={1}
                            flexWrap="wrap"
                            gap={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            {timeframes.map((timeframe) => (
                                <Button
                                    key={timeframe}
                                    variant={selectedTimeframe === timeframe ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTimeframe(timeframe)}
                                    size="small"
                                >
                                    {timeframe}
                                </Button>
                            ))}
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        );
    }

    // This is for desktop/tablet - new horizontal layout
    return (
        <Box sx={{
            p: 2,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: isTablet ? 'column' : 'row',
                gap: 2,
                alignItems: isTablet ? 'stretch' : 'center',
                justifyContent: 'space-between'
            }}>
                {/* Left side - Asset selection section */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: isTablet ? 'column' : 'row',
                    gap: 2,
                    alignItems: isTablet ? 'stretch' : 'center',
                    width: isTablet ? '100%' : 'auto',
                    flexGrow: 1
                }}>
                    <Box sx={{ minWidth: 240, maxWidth: isTablet ? '100%' : 360 }}>
                        <ComparisonSearchBar
                            onSymbolSelect={onAddSymbol}
                            maxSymbols={maxSymbols}
                            currentSymbols={symbols}
                        />
                    </Box>

                    <Typography variant="body2" color="text.secondary" sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                        {symbols.length} of {maxSymbols} symbols used
                    </Typography>
                </Box>

                {/* Right side - Selected symbols section */}
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexGrow: 1,
                    justifyContent: isTablet ? 'flex-start' : 'flex-end'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Selected:
                        </Typography>

                        <Stack
                            direction="row"
                            spacing={1}
                            flexWrap="wrap"
                            gap={1}
                            alignItems="center"
                        >
                            {symbols.map((symbol) => (
                                <Chip
                                    key={symbol}
                                    label={symbol}
                                    onDelete={() => onRemoveSymbol(symbol)}
                                    color="primary"
                                    size="small"
                                />
                            ))}
                        </Stack>
                    </Box>

                    {symbols.length > 0 && (
                        <Button
                            startIcon={<ClearIcon />}
                            onClick={onClearAll}
                            size="small"
                            color="error"
                            variant="outlined"
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            Clear All
                        </Button>
                    )}
                </Box>
            </Box>

            {/* Timeframe controls - Only shown if needed */}
            {showTimeframe && (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mt: 1
                }}>
                    <Typography variant="subtitle2" color="text.secondary">
                        Time Period:
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                    >
                        {timeframes.map((timeframe) => (
                            <Button
                                key={timeframe}
                                variant={selectedTimeframe === timeframe ? 'contained' : 'outlined'}
                                onClick={() => setSelectedTimeframe(timeframe)}
                                size="small"
                            >
                                {timeframe}
                            </Button>
                        ))}
                    </Stack>
                </Box>
            )}
        </Box>
    );
};

export default ControlPanel;